import { ContentLayout, ForbbidenFallback } from "@/components/layout";
import { UsersList } from "../components/users-list";
import { Root } from "@/components/data-table/data-table-root";
import { useForm } from "react-hook-form";
import { Authorization, ROLES } from "@/lib/authorization";

export const Users = () => {
  const form = useForm();

  return (
    <ContentLayout title="Lista de usuários">
      <Authorization
        allowedRoles={[ROLES.Administrator]}
        forbbidenFallback={<ForbbidenFallback />}
      >
        <Root filterForm={form}>
          <UsersList />
        </Root>
      </Authorization>
    </ContentLayout>
  );
};
