import { forwardRef, useImperativeHandle, useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useDisclosure } from "@/hooks";
import { Publisher } from "@/@core/services/types";
import { CreateForm } from "./create";
import { UpdateForm } from "./update";

const schema = z.object({
  name: z.string().min(1, { message: "Nome é obrigatório" }),
  code: z.string().min(1, { message: "Código é obrigatório" }),
  url: z.string(),
}).refine(({ url }) => {
  const regex = /^(https?:\/\/)?(?:www\.)?[\w.-]+\.\w{2,}(?:\.\w{2})?(?:\/\S*)?$/;
  const isValid = regex.test(url)

  return isValid
}, {
  message: "URL inválida",
  path: ["url"]
});

export type PublisherSchema = z.infer<typeof schema>;

type Props = {
  type?: "update" | "create";
};

export type PublishersFormRef = {
  open: (publisher?: Publisher) => void;
};

export const PublishersForm = forwardRef<PublishersFormRef, Props>(
  ({ type = "create" }, ref) => {
    const { open, close, isOpen } = useDisclosure();

    const [publisherToUpdate, setPublisherToUpdate] = useState(
      null as Publisher | null
    );

    const {
      register,
      handleSubmit,
      setValue,
      setError,
      formState: { errors },
    } = useForm<PublisherSchema>({
      resolver: zodResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      open: (publisher?: Publisher) => {
        open();

        if (publisher) {
          setPublisherToUpdate(publisher);
        }
      },
    }));

    return (
      <Sheet open={isOpen}>
        <SheetContent onClose={close}>
          <SheetHeader>
            <SheetTitle>
              {type === "create" ? "Adicionar editora" : "Editar editora"}
            </SheetTitle>
          </SheetHeader>
          {type === "create" && !publisherToUpdate ? (
            <CreateForm
              errors={errors}
              register={register}
              onClose={close}
              setError={setError}
              onSubmit={handleSubmit}
            />
          ) : (
            <UpdateForm
              errors={errors}
              register={register}
              onClose={close}
              setError={setError}
              setValue={setValue}
              onSubmit={handleSubmit}
              publisher={publisherToUpdate!}
            />
          )}
        </SheetContent>
      </Sheet>
    );
  }
);
