import { OrderStatus } from "@/@core/services/types";
import { Badge } from "@/components/ui/badge";
import { OrderVariantStatus } from "../../types";


import { statusType } from "../../utils/filter-options";
import { faLink } from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  status: OrderStatus;
  variant: OrderVariantStatus
  shipment: string
}

export const OrderStatusBadgeExternalLink = ({ status, ...props }: Props) => {
  const link = status === "finishing"
    ? `https://expedicao.novaconcursos.com.br/#/expedition/separating/${props.shipment}`
    : `https://expedicao.novaconcursos.com.br/#/expedition/shipping/${props.shipment}`

  const label = status === "shipping" ? "Bipar" : "Separar"

  return (
    <div className="flex space-x-1">
      <Badge
        variant={props.variant}
        className="capitalize text-[11px] font-semibold w-fit"
      >
        {statusType[status]}
      </Badge>

      <a
        target="_blank"
        href={link}
        className="w-fit inline-flex items-center rounded-md border ps-2 pe-1.5 py-0.5 text-[10.5px] font-semibold transition-colors border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80 gap-1"
        id="shipping-beep-link"
      >
        {label} <FontAwesomeIcon icon={faLink} />
      </a>
    </div>
  )
}
