import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import { useDisclosure } from "@/hooks";

import * as A from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useDataTable } from "@/components/data-table";

import { useGenerateCsvOrders } from "../../api";
import { OrdersFilterSchema } from "../../types";
import { convertStringToArray } from "../../utils/filter-converter";
import { Spinner } from "@/components/ui/spinner";

export type GenerateCsvConfirmRef = {
  open: () => void;
};

export const GenerateCsvConfirm = forwardRef<GenerateCsvConfirmRef, unknown>(
  (_, ref) => {
    const { page, perPage, order, filters } =
      useDataTable<OrdersFilterSchema>();

    const { close, isOpen, open } = useDisclosure();

    const generateCsv = useGenerateCsvOrders();

    useImperativeHandle(ref, () => ({ open }), [open]);

    const handleConfirm = useCallback(() => {
      const params = {
        page,
        per_page: perPage,
        sort_direction: order?.direction,
        sort_by: order?.field,
        from: filters.dateFrom ? filters.dateFrom : undefined,
        to: filters.dateTo ? filters.dateTo : undefined,
        shipment: filters?.shipment ? filters.shipment : undefined,
        status: filters?.orderStatus
          ? convertStringToArray(filters.orderStatus)
          : undefined,
        type: filters?.orderType
          ? convertStringToArray(filters.orderType)
          : undefined,
      };

      generateCsv.mutate(params);
    }, [generateCsv]);

    useEffect(() => {
      if (generateCsv.isSuccess) {
        close();
      }
    }, [generateCsv.isSuccess]);

    return (
      <A.AlertDialog open={isOpen}>
        <A.AlertDialogContent>
          <A.AlertDialogHeader>
            <A.AlertDialogTitle>CSV dos Pedidos</A.AlertDialogTitle>
            <A.AlertDialogDescription className="text-md text-gray-700">
              Deseja gerar o CSV dos pedidos?
            </A.AlertDialogDescription>
          </A.AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button variant="ghost" onClick={close}>
              Fechar
            </Button>
            <Button disabled={generateCsv.isPending} onClick={handleConfirm}>
              Confirmar {generateCsv.isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </A.AlertDialogContent>
      </A.AlertDialog>
    );
  }
);
