import { PropsWithChildren, createContext, useContext, useEffect } from "react";

import { User } from "@/@core/services/types";
import { useLoadUser } from "@/features/auth";
import { useAccountStore } from "@/stores";
import { useUserStore } from "@/stores/user";

type AuthValue = {
  user: User | null;
  isLoading: boolean;
};

const AuthContext = createContext<AuthValue | null>(null);

export const useAuth = () => {
  const state = useContext(AuthContext);

  if (!state) {
    throw new Error("useAuth must be used within AuthProvider");
  }

  return state;
};

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { accessToken } = useAccountStore();
  const { setUser } = useUserStore();

  const me = useLoadUser({
    config: {
      enabled: !!accessToken,
    },
  });

  useEffect(() => {
    if (me.isSuccess) {
      setUser(me.data);
    }
  }, [me.data, me.isSuccess, setUser]);

  const values = {
    user: me.data ?? null,
    isLoading: me.isLoading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
