import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadOrdersList } from "@/@core/services/factories/usecases";
import { LoadOrdersList } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

const loadAll = (params: LoadOrdersList.Params) =>
  makeRemoteLoadOrdersList().loadAll(params);

type QueryFnType = typeof loadAll;

type UseLoadOrdersListOptions = {
  params: LoadOrdersList.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadOrdersList = ({
  params,
  config,
}: UseLoadOrdersListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["orders"],
    queryFn: () => loadAll(params),
    ...config,
  });
};
