import { create } from "zustand";
import { LoadOrdersList } from "@/@core/services/types";

type Values = {
  order: LoadOrdersList.Model | null;
  setOrder: (order: LoadOrdersList.Model) => void;
  removeOrder: () => void;
};

export const useOrderDetailsStore = create<Values>()((set) => ({
  order: null,
  setOrder: (order) => set({ order }),
  removeOrder: () => set({ order: null }),
}));
