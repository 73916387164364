import { useCallback, useContext, useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

import { useOrderStatus } from "../../hooks/use-order-status";
import { OrdersActionContext, OrdersSelectedContext } from "../../contexts";
import { OrderTakeAction } from "@/@core/services/types";

type Props = {
  onClose: () => void;
};

export const ExpeditorAction = ({ onClose }: Props) => {
  const { rows } = useContext(OrdersSelectedContext);
  const { onChangeCurrentAction, onCloseActionModal } =
    useContext(OrdersActionContext);
  const { takeAction } = useOrderStatus();

  const handleConfirm = useCallback(() => {
    const payload = {
      orders: rows.map((row) => ({
        id: row.id,
        action: "end_dispatch",
      })),
    } as OrderTakeAction.Params;

    takeAction.mutate(payload);
  }, [rows, takeAction]);

  useEffect(() => {
    if (takeAction.isSuccess || takeAction.isError) {
      onCloseActionModal(true);
    }
  }, [takeAction.isSuccess, takeAction.isError]);

  useEffect(() => {
    onChangeCurrentAction("end_dispatch");

    return () => {
      onChangeCurrentAction("none");
    };
  }, []);

  const title =
    rows.length > 1
      ? "Deseja alterar status dos pedidos para finalizado?"
      : "Deseja alterar status do pedido para finalizado?";

  return (
    <>
      <p className="text-md mb-5">{title}</p>

      <div className="flex justify-end items-center space-x-4">
        <Button variant={"ghost"} onClick={onClose}>
          Cancelar
        </Button>
        <Button disabled={takeAction.isPending} onClick={handleConfirm}>
          Confirmar {takeAction.isPending && <Spinner className="ml-2" />}
        </Button>
      </div>
    </>
  );
};
