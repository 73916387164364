import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useDisclosure } from "@/hooks";
import { Spinner } from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { useDeletePrinter } from "../api";

export type PrintersDeleteRef = {
  open: (printerId: number) => void;
};

export const PrintersDelete = forwardRef<PrintersDeleteRef, unknown>(
  (_, ref) => {
    const { open, close, isOpen } = useDisclosure();
    const [printerIdSelected, setPrinterIdSelected] = useState<number | null>(
      null
    );

    const deletePrinter = useDeletePrinter();

    const handleOpen = (printerId: number) => {
      open();
      setPrinterIdSelected(printerId);
    };

    const handleConfirm = useCallback(async () => {
      if (printerIdSelected) {
        await deletePrinter.mutateAsync(printerIdSelected);
      }
    }, [deletePrinter, printerIdSelected]);

    useImperativeHandle(ref, () => ({
      open: (publisherId) => handleOpen(publisherId),
    }));

    return (
      <AlertDialog open={isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Deletar impressora</AlertDialogTitle>
            <AlertDialogDescription className="text-md text-gray-700">
              Tem certeza que deseja realizar essa ação?
            </AlertDialogDescription>
          </AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button variant={"ghost"} onClick={close}>
              Cancelar
            </Button>
            <Button onClick={handleConfirm} disabled={deletePrinter.isPending}>
              Confirmar{" "}
              {deletePrinter.isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
);
