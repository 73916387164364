import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadPrintersToPrint } from "@/@core/services/factories/usecases";
import { LoadPrintersToPrint } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

const load = (params: LoadPrintersToPrint.Params) =>
  makeRemoteLoadPrintersToPrint().load(params);

type QueryFnType = typeof load;

type UseLoadPrintersToPrintOptions = {
  params: LoadPrintersToPrint.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadPrintersToPrint = ({
  params,
  config,
}: UseLoadPrintersToPrintOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["printers-to-print"],
    queryFn: () => load(params),
    ...config,
  });
};
