import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { FieldInput } from "@/components/form";
import { PublisherSchema } from ".";
import { Button } from "@/components/ui/button";
import { Publisher } from "@/@core/services/types";
import { useCallback, useEffect } from "react";
import { Spinner } from "@/components/ui/spinner";
import { BadRequestError } from "@/@core/services/errors";
import { useUpdatePublisher } from "../../api";

type Props = {
  errors: FieldErrors<PublisherSchema>;
  register: UseFormRegister<PublisherSchema>;
  setValue: UseFormSetValue<PublisherSchema>;
  setError: UseFormSetError<PublisherSchema>;
  onClose: () => void;
  onSubmit: UseFormHandleSubmit<PublisherSchema>;
  publisher: Publisher;
};

export const UpdateForm = ({
  errors,
  register,
  onSubmit,
  onClose,
  setValue,
  setError,
  publisher,
}: Props) => {
  const updatePublisher = useUpdatePublisher();

  const handleSubmit: SubmitHandler<PublisherSchema> = useCallback(
    (data) => {
      updatePublisher.mutate({
        name: data.name,
        code: data.code,
        webhook_url: data.url || null,
        id: publisher!.id,
      });
    },
    [publisher, updatePublisher]
  );

  useEffect(() => {
    if (updatePublisher.isSuccess) {
      onClose();
    }
  }, [onClose, updatePublisher.isSuccess]);

  useEffect(() => {
    if (publisher) {
      setValue("name", publisher.name);
      setValue("code", publisher.code);
      setValue("url", publisher.webhook_url || "");
    }
  }, [setValue, publisher]);

  useEffect(() => {
    if (updatePublisher.error instanceof BadRequestError) {
      setError("code", {
        message: "Esse código já está em uso",
      });
    }
  }, [setError, updatePublisher.error]);

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <div className="grid gap-4 mt-5">
        <FieldInput
          label="Nome"
          error={errors.name}
          placeholder="Digite o nome da editora"
          {...register("name")}
        />

        <FieldInput
          label="Código"
          error={errors.code}
          placeholder="Digite o código da editora"
          {...register("code")}
        />

        <FieldInput
          label="Webhook de alteração de status"
          error={errors.url}
          placeholder="Digite a url do webhook"
          {...register("url")}
        />
      </div>

      <div className="flex justify-end gap-x-3 mt-4">
        <Button type="button" variant={"secondary"} onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={updatePublisher.isPending}>
          Atualizar{" "}
          {updatePublisher.isPending ? <Spinner className="ml-2" /> : null}
        </Button>
      </div>
    </form>
  );
};
