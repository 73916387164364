import { MetaResponse, PaginationParams } from "./pagination";
import { Publisher } from "./publishers";
import { Product } from "./products";
import { User } from "./users";

export type OrderAction =
  | "received"
  | "start_print_cover"
  | "start_print_core"
  | "start_finishing"
  | "start_dispatch"
  | "finish"
  | "end_print_cover"
  | "end_print_core"
  | "end_finishing"
  | "end_dispatch"
  | "change_status"
  | "cancel";

export enum OrderStatusEnum {
  received = "received",
  waiting_print = "waiting_print",
  cover_print_requested = "cover_print_requested",
  cover_printing = "cover_printing",
  cover_print_finished = "cover_print_finished",
  core_print_requested = "core_print_requested",
  core_printing = "core_printing",
  core_print_finished = "core_print_finished",
  finishing = "finishing",
  shipping = "shipping",
  finished = "finished",
  failed = "failed",
  canceled = "canceled",
}

export type OrderStatus = keyof typeof OrderStatusEnum;

export type OrderHistory = {
  id?: number;
  action: OrderAction | null;
  status: OrderStatus;
  created_at: string;
  message?: string | null;
  user_id?: number;
  user: User;
};

export type Order = {
  id: number;
  shipment: string;
  urgent: boolean;
  type: "site" | "priority" | "corporate" | "reprint";
  status: OrderStatus[];
  core_total: number;
  cover_total: number;
  pages_total: number;
  sku_total: number;
  due_date: string;
  opened_at: string;
  is_reprint: boolean;
  products: Product[];
  history: OrderHistory[];
  publisher: Publisher;
};

/** Load Orders */
export interface LoadOrdersList {
  loadAll: (params: LoadOrdersList.Params) => Promise<LoadOrdersList.Response>;
}

export namespace LoadOrdersList {
  export type Model = Omit<Order, "products">;

  export type Response = {
    data: Model[];
    meta: MetaResponse;
  };

  export type Params = {
    from?: string;
    to?: string;
    shipment?: string;
    status?: string[];
    type?: string[];
  } & PaginationParams;
}

/** Load Order */
export interface LoadOrder {
  load: () => Promise<LoadOrder.Model>;
}

export namespace LoadOrder {
  export type Response = {
    data: Order;
  };

  export type Model = Order;

  export type Params = {
    id: number;
  };
}

/** Order Take Action */
export interface OrderTakeAction {
  takeAction: (params: OrderTakeAction.Params) => Promise<void>;
}

export namespace OrderTakeAction {
  export type Params = {
    orders: {
      id: number;
      action: OrderAction;
      status?: OrderStatus;
      printer?: number;
    }[];
  };
}

/** Reprint Order */

export interface ReprintOrder {
  reprint: (params: ReprintOrder.Params) => Promise<void>;
}

export namespace ReprintOrder {
  export type Params = {
    orderId: number;
    products: {
      id: number;
      core_total: number;
      cover_total: number;
    }[];
  };
}

/** Resend Switch */

export interface ResendSwitch {
  resend: (params: ResendSwitch.Params) => Promise<void>;
}

export namespace ResendSwitch {
  export type Params = {
    orders: {
      id: number;
    }[];
  };
}

/** Download All Files */

export interface OrderDownloadAll {
  downloadAll: (params: OrderDownloadAll.Params) => Promise<void>;
}

export namespace OrderDownloadAll {
  export type Params = {
    orderId: number;
    shipment: string;
  };
}

/** Generate CSV */

export interface GenerateCsvOrders {
  generate: (params: GenerateCsvOrders.Params) => Promise<void>;
}

export namespace GenerateCsvOrders {
  export type Params = {
    from?: string;
    to?: string;
    shipment?: string;
    status?: string[];
    type?: string[];
  } & PaginationParams;
}
