import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadOrder } from "@/@core/services/factories/usecases";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

const load = (orderId: number) => makeRemoteLoadOrder(orderId).load();

type QueryFnType = typeof load;

type UseLoadOrderOptions = {
  orderId: number;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadOrder = ({ orderId, config }: UseLoadOrderOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["order"],
    queryFn: () => load(orderId),
    ...config,
  });
};
