import { LoadOrdersList, OrderStatus } from "@/@core/services/types";
import { useDataTable } from "@/components/data-table";
import { queryClient } from "@/lib/react-query";
import { useSocket } from "@/lib/socket";
import { useCallback, useContext } from "react";
import { OrdersFilterSchema } from "../types";
import { OrdersSelectedContext } from "../contexts";

type Payload = {
  status: OrderStatus[];
  id: number;
};

export const useOrderStatusSocket = () => {
  const { rows, onChangeRows } = useContext(OrdersSelectedContext);
  const { filters } = useDataTable();

  const updateSelectedRows = useCallback(
    (payload: Payload) => {
      if (rows.length === 0) return;

      const newRows = rows.map((row) => {
        if (row.id === payload.id) {
          return {
            ...row,
            status: payload.status,
          };
        }

        return row;
      });

      onChangeRows(newRows);
    },
    [onChangeRows, rows]
  );

  const updateOrderStatus = useCallback(
    (payload: Payload) => {
      const filtersTyped = filters as OrdersFilterSchema;

      queryClient.setQueryData(
        ["orders"],
        (previousData: LoadOrdersList.Response) => {
          if (!previousData) return previousData;

          const newData = previousData.data.map(
            (order: LoadOrdersList.Model) => ({
              ...order,
              status: order.id === payload.id ? payload.status : order.status,
            })
          );

          if (filtersTyped.orderStatus) {
            const filtersArray = filtersTyped.orderStatus.split(",");

            return {
              ...previousData,
              data: newData.filter((order) => {
                return order.status.some((status) =>
                  filtersArray.includes(status)
                );
              }),
            };
          }

          return {
            ...previousData,
            data: newData,
          };
        }
      );
    },
    [filters]
  );

  useSocket({
    type: "order-status-updated",
    callBack: (payload) => {
      updateOrderStatus(payload);
      updateSelectedRows(payload);
    },
  });
};
