export const switchRequestStatus = {
  success: "success",
  error: "error",
  pending: "pending",
};

export const switchRequestStatusText = {
  success: "Sucesso",
  error: "Error",
  pending: "Pendente",
};
