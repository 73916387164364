import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import * as D from "@/components/ui/dialog";
import { OrderAction, OrderStatus } from "@/@core/services/types";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { useDisclosure } from "@/hooks";
import { useOrderStatus } from "@/features/order/hooks/use-order-status";
import { statusType } from "@/features/order/utils/filter-options";

type OpenParams = {
  ids: number[];
  status: OrderStatus | undefined;
};

export type ConfirmChangeStatusHardRef = {
  open: (params: OpenParams) => void;
};

type Props = {
  onClose: () => void;
  onSuccess: () => void;
};

export const ConfirmChangeStatusHard = forwardRef<
  ConfirmChangeStatusHardRef,
  Props
>(({ onClose, onSuccess }, ref) => {
  const { isOpen, close, open } = useDisclosure();

  const [params, setParams] = useState<OpenParams>({
    ids: [],
    status: undefined,
  });

  const { takeAction } = useOrderStatus();

  useImperativeHandle(
    ref,
    () => ({
      open: (params) => {
        setParams(params);
        open();
      },
    }),
    [open]
  );

  const handleConfirm = useCallback(() => {
    const payload = {
      orders: params.ids.map((id) => ({
        id,
        action: "change_status" as OrderAction,
        status: params.status,
      })),
    };

    takeAction.mutate(payload);
  }, [params, takeAction]);

  function handleClose() {
    close();
    onClose();
  }

  const statusLabel = statusType[params.status as keyof typeof statusType];

  useEffect(() => {
    if (takeAction.isSuccess) {
      close();
      onSuccess();
    }
  }, [close, takeAction.isSuccess]);

  if (!isOpen) return null;

  return (
    <D.Dialog open={isOpen}>
      <D.DialogContent onClose={handleClose}>
        <D.DialogHeader>
          <D.DialogTitle>Confirmar alteração de status</D.DialogTitle>
        </D.DialogHeader>

        <p className="text-base mb-5">
          Deseja alterar os pedidos para o status de{" "}
          <strong>{statusLabel}</strong>?
        </p>

        <div className="flex justify-end items-center space-x-4">
          <Button variant={"ghost"} onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm} disabled={takeAction.isPending}>
            Confirmar {takeAction.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </D.DialogContent>
    </D.Dialog>
  );
});
