import { LoadUserMe } from "@/@core/services/types";
import { UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteLoadMe implements LoadUserMe {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadUserMe.Model>,
  ) {}

  async me(): Promise<LoadUserMe.Model> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.unauthorized:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  }
}
