import { Authentication } from "@/@core/services/types";
import { UnexpectedError, CredentialsInvalid } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

type Response = {
  access_token: string;
  expires_in: number;
  token_type: string;
};

export class RemoteAuthentication implements Authentication {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<Response>,
  ) {}

  async auth(params: Authentication.Params): Promise<Authentication.Model> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return {
          accessToken: response.body!.access_token,
        };
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.unprocessableContent:
        throw new CredentialsInvalid();
      default:
        throw new UnexpectedError();
    }
  }
}
