import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadSettings } from "@/@core/services/factories/usecases";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { LoadSettings } from "@/@core/services/types";

const load = (): Promise<LoadSettings.Model> =>
  makeRemoteLoadSettings().load();

type QueryFnType = typeof load;

type UseLoadSettingsOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLoadSettings = ({
  config,
}: UseLoadSettingsOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["settings"],
    queryFn: () => load(),
    ...config,
  });
};
