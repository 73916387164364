import { makeRemoteDeleteUser } from "@/@core/services/factories/usecases";
import { useToast } from "@/components/ui/use-toast";
import { MutationConfig, queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const deleteUser = (userId: number) => makeRemoteDeleteUser(userId).delete();

type UseDeleteUserOptions = {
  config?: MutationConfig<typeof deleteUser>;
};

export const useDeleteUser = ({ config }: UseDeleteUserOptions = {}) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, context: any) => {
      toast({
        variant: "destructive",
        title: "Erro ao deletar usuário!",
        description: "Ocorreu um erro ao deletar o usuário.",
      });

      if (context?.previousDiscussions) {
        queryClient.setQueryData(["users"], context.previousUsers);
      }
    },
    onSuccess: () => {
      toast({
        title: "Usuário deletado!",
        description: "Usuário deletado com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
    mutationFn: deleteUser,
    ...config,
  });
};
