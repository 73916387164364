import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "@/components/ui/button";
import { useLoadPrintersToPrint } from "@/features/printer/api";
import { useContext, useEffect, useMemo, useState } from "react";
import { FieldComboBox } from "@/components/form/field-combobox";
import { ConfirmPrintToPrint } from "./confirm-printer-to-print";
import { Spinner } from "@/components/ui/spinner";
import { OrdersActionContext } from "@/features/order/contexts";

const schema = z.object({
  printer: z.string({ required_error: "Selecione uma impressora" }),
});

type PrinterSchema = z.infer<typeof schema>;

type Props = {
  onClose: () => void;
  type: "book" | "cover";
};

export const PrintersList = (props: Props) => {
  const { onChangeCurrentAction } = useContext(OrdersActionContext);
  const [confirmPrintIsVisible, setConfirmPrintIsVisible] = useState(false);
  const [printerId, setPrinterId] = useState<number | null>(null);

  useEffect(() => {
    onChangeCurrentAction(
      props.type === "book" ? "start_print_core" : "start_print_cover"
    );

    return () => {
      //@ts-ignore
      onChangeCurrentAction("none");
    };
  }, [props.type]);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<PrinterSchema>({
    resolver: zodResolver(schema),
  });

  const {
    data: printers,
    isPending,
    isFetching,
  } = useLoadPrintersToPrint({
    params: {
      type: props.type === "book" ? "core_printer" : "cover_printer",
    },
  });

  const onSubmit = (data: PrinterSchema) => {
    setConfirmPrintIsVisible(true);
    setPrinterId(Number(data.printer));
  };

  const placeholder =
    props.type === "book"
      ? "Selecione uma impressora de miolo"
      : "Selecione uma impressora de capa";

  const options = useMemo(() => {
    if (isPending || !printers) return [];

    return printers.data.map((printer) => ({
      label: printer.name,
      name: printer.name,
      value: printer.id.toString(),
    }));
  }, [isPending, printers]);

  const hasPrinters = printers && printers.data.length > 0;

  if (confirmPrintIsVisible && printerId) {
    return (
      <ConfirmPrintToPrint
        type={props.type}
        printerId={printerId}
        onClose={() => setConfirmPrintIsVisible(false)}
      />
    );
  }

  if (isPending || isFetching) {
    return (
      <div className="flex items-center justify-center py-4">
        <Spinner size="md-lg" />
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-y-5">
      {hasPrinters ? (
        <FieldComboBox
          classNameCommand="w-[29rem]"
          placeholderNotFound="Nenhuma impressora encontrada"
          control={control}
          name="printer"
          label="Impressoras"
          error={errors.printer}
          options={options as any}
          registration={register("printer")}
          placeholder={placeholder}
        />
      ) : (
        <p className="text-center text-md mt-5 mb-3">
          Nenhuma impressora disponível para impressão
        </p>
      )}

      <div className="flex justify-end items-center space-x-4">
        <Button
          variant={hasPrinters ? "ghost" : "default"}
          onClick={props.onClose}
        >
          Cancelar
        </Button>

        {hasPrinters && <Button>Confirmar</Button>}
      </div>
    </form>
  );
};
