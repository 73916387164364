import { SettingsForm } from "../components"
import { ContentLayout } from "@/components/layout"

export const Settings = () => {
  return (
    <ContentLayout title="Configurações">
      <SettingsForm />
    </ContentLayout>
  )
}
