import * as React from "react";
import { Check } from "lucide-react";

import { Button } from "@/components/ui/button";
import * as C from "@/components/ui/command";
import * as P from "@/components/ui/popover";

import { Controller, FieldError, UseFormRegisterReturn } from "react-hook-form";

import { FieldWrapper } from ".";
import { PopoverProps } from "@radix-ui/react-popover";
import { cn } from "@/lib/cn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/sharp-solid-svg-icons";

type Options = {
  label: string;
  value: string;
  name: string;
};

type Props = {
  label?: string;
  error?: FieldError | undefined;
  placeholder?: string;
  options: Options[];
  control: any;
  name: string;
  className?: string;
  classNameCommand?: string;
  iconIsVisible?: boolean;
  placeholderNotFound: string;
  hasSearch?: boolean;
  registration: Partial<UseFormRegisterReturn>;
} & PopoverProps;

export const FieldComboBox = ({
  label,
  error,
  control,
  options,
  hasSearch = true,
  classNameCommand = "",
  placeholderNotFound = "Nenhum resultado encontrado",
  placeholder = "Selecione uma opção",
  ...props
}: Props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <FieldWrapper error={error} label={label}>
      <Controller
        control={control}
        name={props.name}
        render={({ field }) => (
          <P.Popover open={open} onOpenChange={setOpen} modal>
            <P.PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                aria-expanded={open}
                className="w-full justify-between h-9 border"
              >
                <span className="font-normal">
                  {field.value
                    ? options.find((option) => option.value === field.value)
                        ?.label
                    : placeholder}
                </span>
                <FontAwesomeIcon icon={faAngleDown} className="ml-2 shrink-0 opacity-50"/>
              </Button>
            </P.PopoverTrigger>
            <P.PopoverContent className="p-0 text-md w-full">
              <C.Command className={classNameCommand}>
                {hasSearch ? (
                  <C.CommandInput placeholder={placeholder} className="text-md" />
                ) : null}
                <C.CommandEmpty>
                  <span className="text-md">{placeholderNotFound}</span>
                </C.CommandEmpty>
                <C.CommandGroup className="max-h-[175px] overflow-y-auto">
                  {options.map((option) => (
                    <C.CommandItem
                      className="text-md"
                      key={option.value}
                      value={option.name}
                      onSelect={() => {
                        field.onChange(option.value);
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          field.value === option.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </C.CommandItem>
                  ))}
                </C.CommandGroup>
              </C.Command>
            </P.PopoverContent>
          </P.Popover>
        )}
      />
    </FieldWrapper>
  );
};
