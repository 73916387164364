import { FieldInput, FieldWrapper } from "@/components/form";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/sharp-solid-svg-icons";

import { UserSchema } from ".";
import { userTypeOptions } from "@/utils/user-type-options";
import { Button } from "@/components/ui/button";
import { useUpdateUser } from "../../api";
import { User, UserType } from "@/@core/services/types";
import { useCallback, useEffect } from "react";
import { Spinner } from "@/components/ui/spinner";
import { BadRequestError } from "@/@core/services/errors";
import { Authorization, ROLES } from "@/lib/authorization";

import "./styles.css";

type Props = {
  errors: FieldErrors<UserSchema>;
  register: UseFormRegister<UserSchema>;
  control: Control<UserSchema>;
  options: typeof userTypeOptions;
  setValue: UseFormSetValue<UserSchema>;
  setError: UseFormSetError<UserSchema>;
  onClose: () => void;
  onSubmit: UseFormHandleSubmit<UserSchema>;
  user: User;
};

export const UpdateForm = ({
  errors,
  register,
  control,
  options,
  onSubmit,
  onClose,
  setValue,
  setError,
  user,
}: Props) => {
  const updateUser = useUpdateUser();

  const handleSubmit: SubmitHandler<UserSchema> = useCallback(
    (data) => {
      updateUser.mutate({
        email: data.email,
        name: data.name,
        type: data.type as UserType,
        id: user!.id,
      });
    },

    [user]
  );

  useEffect(() => {
    if (updateUser.isSuccess) {
      onClose();
    }
  }, [onClose, updateUser.isSuccess]);

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("type", user.type);
    }
  }, [setValue, user]);

  useEffect(() => {
    if (updateUser.error instanceof BadRequestError) {
      setError("email", {
        message: "Esse email já está em uso",
      });
    }
  }, [setError, updateUser.error]);

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <div className="grid gap-4 mt-5">
        <FieldInput
          label="Nome"
          error={errors.name}
          placeholder="Digite o nome do usuário"
          {...register("name")}
        />

        <FieldInput
          label="Email"
          error={errors.email}
          placeholder="Digite seu email"
          type="email"
          {...register("email")}
        />

        <Authorization allowedRoles={[ROLES.Administrator]}>
          <FieldWrapper error={errors.type} label="Tipo do usuário">
            <Controller
              name="type"
              control={control}
              render={({ field }) => (
                <div className="relative">
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className="opacity-50 absolute z-10 right-[12px] bottom-[10px] pointer-events-none"
                    size="sm"
                  />
                  <select
                    {...field}
                    className="flex h-9 w-full items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-md ring-offset-background placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                  >
                    {options.map((option) => (
                      <option
                        key={option.value}
                        value={option.value}
                        className="text-md"
                      >
                        <span className="ps-6">{option.label}</span>
                      </option>
                    ))}
                  </select>
                </div>
              )}
            />
          </FieldWrapper>
        </Authorization>
      </div>

      <div className="flex justify-end gap-x-3 mt-4">
        <Button type="button" variant={"secondary"} onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={updateUser.isPending}>
          Atualizar {updateUser.isPending ? <Spinner className="ml-2" /> : null}
        </Button>
      </div>
    </form>
  );
};
