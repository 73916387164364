import {
  ForbbidenError,
  NotFoundError,
  UnexpectedError,
} from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { ReprintOrder } from "../types";

export class RemoteReprintOrder implements ReprintOrder {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async reprint(params: ReprintOrder.Params): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.created:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      case HttpStatusCode.notFound:
        throw new NotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}
