import { useCallback } from "react";

import { FinisherAction } from "../components/order-action/finisher-action";
import { ExpeditorAction } from "../components/order-action/expeditor-action";

import { BookCoverPrinterAction } from "../components/order-action/book-cover-printer-action";

export type RenderStatusTypes = "finishing" | "shipping" | "printer";

type RenderActionParams = {
  status: RenderStatusTypes;
  onClose: () => void;
};

export const useOrderAction = () => {
  const renderActionBasedOnStatus = useCallback(
    (params: RenderActionParams) => {
      switch (params.status) {
        case "finishing":
          return <FinisherAction {...params} />;
        case "shipping":
          return <ExpeditorAction {...params} />;
        case "printer":
          return <BookCoverPrinterAction {...params} />;
        default:
          return null;
      }
    },
    []
  );

  return { renderActionBasedOnStatus };
};
