import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
} from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { useDisclosure } from "@/hooks";

import * as D from "@/components/ui/dialog";

import { OrdersSelectedContext } from "../../contexts";
import { useOrderResendSwitch } from "../../api";

export interface ResendFilesRef {
  open: () => void;
}

export const ResendFiles = forwardRef<ResendFilesRef, unknown>((_, ref) => {
  const { rows } = useContext(OrdersSelectedContext);
  const resendSwitch = useOrderResendSwitch();
  const { isOpen, open, close } = useDisclosure();

  const handleConfirm = useCallback(() => {
    const payload = {
      orders: rows.map((row) => ({
        id: row.id,
      })),
    };

    resendSwitch.mutate(payload);
  }, [rows, resendSwitch]);

  useEffect(() => {
    if (resendSwitch.isSuccess) {
      close();
    }
  }, [resendSwitch.isSuccess]);

  const title =
    rows.length > 1
      ? "Deseja reenviar para os switch os pedidos selecionados?"
      : "Deseja reenviar para os switch os pedido selecionado?";

  useImperativeHandle(ref, () => ({ open }), [open]);

  return (
    <D.Dialog open={isOpen}>
      <D.DialogContent onClose={close}>
        <D.DialogHeader>
          <D.DialogTitle>Reenviar arquivos</D.DialogTitle>
        </D.DialogHeader>

        <p className="text-md mb-5">{title}</p>

        <div className="flex justify-end items-center space-x-4">
          <Button variant={"ghost"} onClick={close}>
            Cancelar
          </Button>
          <Button disabled={resendSwitch.isPending} onClick={handleConfirm}>
            Confirmar {resendSwitch.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </D.DialogContent>
    </D.Dialog>
  );
});
