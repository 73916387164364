import { makeRemoteForgotPassword } from "@/@core/services/factories/usecases";
import { ForgotPassword } from "@/@core/services/types";
import { useMutation } from "@tanstack/react-query";

const forgotPassword = async (data: ForgotPassword.Params): Promise<void> =>
  makeRemoteForgotPassword().send(data);

export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
  });
};
