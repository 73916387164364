import { useAccountStore } from "@/stores";
import { useUserStore } from "@/stores/user";

export const useAuth = () => {
  const { accessToken } = useAccountStore();
  const { user } = useUserStore();
  const isAuthenticated = !!accessToken;

  return { isAuthenticated, user };
};
