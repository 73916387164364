import { storageKeys } from "@/config/storage-keys";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type Account = {
  accessToken: string | null;
  setToken: (token: string) => void;
  removeToken: () => void;
};

export const useAccountStore = create<Account>()(
  persist(
    (set) => ({
      accessToken: null,
      setToken: (token) => set({ accessToken: token }),
      removeToken: () => set({ accessToken: null }),
    }),
    {
      name: storageKeys.accessTokenKey,
    },
  ),
);
