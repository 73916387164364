import { makeResetPassword } from "@/@core/services/factories/usecases";
import { useMutation } from "@tanstack/react-query";

type ResetPasswordDTO = {
  email: string;
  password: string;
  passwordConfirmation: string;
  token: string;
};

const resetPassword = async (data: ResetPasswordDTO): Promise<void> => {
  await makeResetPassword().send({
    email: data.email,
    token: data.token,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
  });
};

export const useResetPassword = () => {
  return useMutation({
    mutationFn: resetPassword,
  });
};
