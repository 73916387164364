import { PropsWithChildren } from "react";
import { faBarcode, faBookOpen } from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Product } from "@/@core/services/types/products";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

type Props = {
  product: Product;
} & PropsWithChildren;

export const OrderProduct = ({ product, children }: Props) => {
  return (
    <div className="flex items-center">
      <Avatar className={`
        h-14 w-14 overflow-visible cursor-pointer
        hover:w-44 hover:h-44 hover:z-[1000]
        duration-300 transform
      `}>
        {product.thumb_url ? (
          <AvatarImage
            src={decodeURIComponent(product.thumb_url)}
            alt="Avatar"
            className="rounded-none"
          />
        ) : (
          <AvatarFallback>O</AvatarFallback>
        )}
      </Avatar>

      <div className="ml-3">
        <p className="text-md font-medium">{product.name}</p>
        <div className="flex items-center text-muted-foreground">
          <span className="text-sm flex items-center">
            <FontAwesomeIcon className="me-1 mb-0.5" icon={faBarcode} size="sm" />
            {product.bar_code}
          </span>
          <span className="text-sm flex items-center ms-2">
            <FontAwesomeIcon className="me-1 mb-0.5" icon={faBookOpen} size="sm" />
            {product.pages} páginas
          </span>
        </div>
        <p className="text-sm text-muted-foreground">{product.sku}</p>
      </div>
      {children}
    </div>
  );
};
