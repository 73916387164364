import {
  HttpClient,
  HttpRequest,
  HttpResponse,
} from "@/@core/infra/protocols/http";

export class AxiosHttpClientAuthorization implements HttpClient {
  constructor(private readonly httpClient: HttpClient) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    const storage = localStorage.getItem("@webprint-access-token");
    const account = storage ? JSON.parse(storage) : null;

    if (account && account.state.accessToken) {
      Object.assign(data, {
        headers: Object.assign(data.headers || {}, {
          Authorization: `Bearer ${account.state.accessToken}`,
        }),
      });
    }

    const HttpResponse = await this.httpClient.request(data);
    return HttpResponse;
  }
}
