import { ColumnDef } from "@tanstack/react-table";
import * as D from "@/components/ui/dropdown-menu";

import { DataTableOrder } from "@/components/data-table";
import { LoadSwitchRequestsList } from "@/@core/services/types/switch-request";
import { Badge } from "@/components/ui/badge";
import {
  switchRequestStatus,
  switchRequestStatusText,
} from "../utils/switch-request-status";
import { formatDate } from "@/utils/format-date";
import { Button } from "@/components/ui/button";
import { useRef } from "react";
import {
  ResendSwitchRequest,
  ResendSwitchRequestRef,
} from "../components/resend-switch-request";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpArrowDown,
  faBarcode,
  faCalendarCircleUser,
  faEllipsis,
  faHashtag,
  faRotateLeft,
} from "@fortawesome/sharp-solid-svg-icons";

export const useColumns = () => {
  const resendSwitchRequestRef = useRef<ResendSwitchRequestRef>(null);

  const columns: ColumnDef<LoadSwitchRequestsList.Model>[] = [
    {
      accessorKey: "submit_point_type",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faHashtag} />}
          accessor="submit_point_type"
          title="Tipo do Envio"
        />
      ),
      cell: ({ row }) => <>{row.original.submit_point_type}</>,
    },
    {
      accessorKey: "order_shipment",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faBarcode} />}
          accessor="order_shipment"
          title="Remessa"
        />
      ),
      cell: ({ row }) => <>{row.original.order_shipment}</>,
    },
    {
      accessorKey: "created_at",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faCalendarCircleUser} />}
          accessor="created_at"
          title="Data de Criação"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{formatDate(row.original.created_at)}</div>
      ),
    },
    {
      accessorKey: "status",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faArrowUpArrowDown} />}
          accessor="status"
          hasSort={false}
          title="Status"
        />
      ),
      cell: ({ row }) => {
        const status = row.original.status.toLowerCase();
        const label =
          switchRequestStatusText[
            status as keyof typeof switchRequestStatusText
          ];

        return (
          <Badge
            className="capitalize text-[11px] font-semibold"
            variant={status as keyof typeof switchRequestStatus}
          >
            {label}
          </Badge>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <>
            <D.DropdownMenu>
              <D.DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <FontAwesomeIcon icon={faEllipsis} size="lg" />
                </Button>
              </D.DropdownMenuTrigger>
              <D.DropdownMenuContent align="end">
                <D.DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    resendSwitchRequestRef.current?.open(row.original.id)
                  }
                >
                  <FontAwesomeIcon icon={faRotateLeft} size="1x" />{" "}
                  <span className="ms-2">Refazer Requisição</span>
                </D.DropdownMenuItem>
              </D.DropdownMenuContent>
            </D.DropdownMenu>

            <ResendSwitchRequest ref={resendSwitchRequestRef} />
          </>
        );
      },
    },
  ];

  return { columns };
};
