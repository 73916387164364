import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as A from "@/components/ui/alert-dialog";
import { useDisclosure } from "@/hooks";
import { Spinner } from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { useResendSwitchRequest } from "../api/resend-switch-request";
import { useToast } from "@/components/ui/use-toast";

export type ResendSwitchRequestRef = {
  open: (switchRequestId: number) => void;
};

export const ResendSwitchRequest = forwardRef<ResendSwitchRequestRef, unknown>(
  (_, ref) => {
    const { toast } = useToast();
    const { open, close, isOpen } = useDisclosure();
    const [switchRequestIdSelected, setSwitchRequestIdSelected] = useState<
      number | null
    >(null);

    const resendSwitchRequest = useResendSwitchRequest();

    const handleOpen = (switchRequestId: number) => {
      open();
      setSwitchRequestIdSelected(switchRequestId);
    };

    const handleConfirm = useCallback(async () => {
      if (switchRequestIdSelected) {
        await resendSwitchRequest.mutateAsync({
          switchRequest: switchRequestIdSelected,
        });
      }
    }, [resendSwitchRequest, switchRequestIdSelected]);

    useImperativeHandle(ref, () => ({
      open: (switchRequestId) => handleOpen(switchRequestId),
    }));

    useEffect(() => {
      if (resendSwitchRequest.isSuccess) {
        toast({
          title: "Request enviada com sucesso.",
          description: "A request selecionada foi enviada com sucesso.",
        });

        close();
      }
    }, [resendSwitchRequest.isSuccess]);

    useEffect(() => {
      if (resendSwitchRequest.isError) {
        toast({
          title: "Error ao refazer a requisição!",
          description: "Não foi possivel refazer a requisição.",
          variant: "destructive",
        });

        close();
      }
    }, [resendSwitchRequest.isError]);

    return (
      <A.AlertDialog open={isOpen}>
        <A.AlertDialogContent>
          <A.AlertDialogHeader>
            <A.AlertDialogTitle>Refazer requisição</A.AlertDialogTitle>
            <A.AlertDialogDescription className="text-md text-gray-700">
              Tem certeza que deseja refazer essa requisição?
            </A.AlertDialogDescription>
          </A.AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button variant={"ghost"} onClick={close}>
              Cancelar
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={resendSwitchRequest.isPending}
            >
              Confirmar{" "}
              {resendSwitchRequest.isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </A.AlertDialogContent>
      </A.AlertDialog>
    );
  }
);
