import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAccountStore } from "@/stores";
import { useUserStore } from "@/stores/user";
import { UserForm, UserFormRef } from "./user-form";

export const UserNav = () => {
  const navigate = useNavigate();
  const { user, removeUser } = useUserStore();
  const { removeToken } = useAccountStore();

  const userFormRef = useRef<UserFormRef>(null);

  const handleLogout = () => {
    removeToken();
    removeUser();
    navigate("/login");
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarFallback className="text-white text-md font-bold bg-blue-main">
              {user?.name.charAt(0)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal mb-2">
          <div className="flex flex-col space-y-1">
            <p className="text-base font-medium">{user?.name}</p>
            <p className="text-md leading-none text-muted-foreground">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuGroup>
          {user && (
            <DropdownMenuItem
              onClick={() =>
                userFormRef.current?.open({
                  email: user.email,
                  id: user.id,
                  name: user.name,
                  type: user.type,
                })
              }
            >
              Editar
            </DropdownMenuItem>
          )}
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate("/settings")}>Configurações</DropdownMenuItem>
        <DropdownMenuItem onClick={handleLogout}>Sair</DropdownMenuItem>
      </DropdownMenuContent>

      {user && <UserForm type="update" ref={userFormRef} />}
    </DropdownMenu>
  );
};
