import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

type Props = {
  quantity: number;
  isLoading: boolean;
  onReprint: () => void;
};

export const Footer = (props: Props) => {
  const quantityLabel =
    props.quantity === 1 ? "Pedido selecionado" : "Pedidos selecionados";

  return (
    <div
      className={`
      flex items-center justify-end w-full space-x-6 py-2 px-5 bottom-0
      bg-white border-t-2 left-0 absolute
    `}
    >
      <small>
        {props.quantity} {quantityLabel}
      </small>
      <Button
        variant="default"
        size="sm"
        onClick={props.onReprint}
        disabled={props.isLoading}
      >
        <span className="pt-1">Refazer</span>{" "}
        {props.isLoading && <Spinner className="ml-2" />}
      </Button>
    </div>
  );
};
