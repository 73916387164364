import { LoadUserList } from "@/@core/services/types";
import { UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteLoadUserList implements LoadUserList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadUserList.Response>
  ) {}

  async loadAll(params: LoadUserList.Params): Promise<LoadUserList.Response> {
    const response = await this.httpClient.request({
      url: this.url,
      params,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      default:
        throw new UnexpectedError();
    }
  }
}
