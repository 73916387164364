import { useState, useImperativeHandle, forwardRef, useCallback } from "react";
import * as D from "@/components/ui/dialog";
import { useDisclosure } from "@/hooks";

import { OrderTabs } from "./order-tabs";
import { OrderReprint } from "./order-reprint";
import { useOrderDetailsStore } from "@/features/order/stores/order-details";
import { useOrderReprintStore } from "../../stores";
import classNames from "classnames";

type OrderVisible = "details" | "reprint";

export type OrderDetailsRef = {
  open: () => void;
};

export const OrderDetails = forwardRef((_, ref) => {
  const [orderVisible, setOrderVisible] = useState("details" as OrderVisible);

  const { order, removeOrder } = useOrderDetailsStore();
  const { setOrder, clearProducts, products } = useOrderReprintStore();
  const { open, isOpen, close } = useDisclosure();

  const handleOpenReprint = useCallback(() => {
    setOrderVisible("reprint");
    if (order) {
      setOrder(order);
    }
  }, [order, setOrder]);

  const handleClose = useCallback(() => {
    if (orderVisible === "details") {
      close();
      removeOrder();

      return;
    }

    if (orderVisible === "reprint") {
      setOrderVisible("details");
      clearProducts();
    }
  }, [close, removeOrder, clearProducts, orderVisible]);

  useImperativeHandle(ref, () => ({ open }), [open]);

  if (!isOpen || !order) {
    return null;
  }

  return (
    <D.Dialog open={isOpen}>
      <D.DialogContent
        className={classNames(
          "max-w-screen-lg md:h-[460px] 2xl:h-[650px] flex flex-col justify-start",
          {
            "pb-14": products.length > 0,
          }
        )}
        onClose={handleClose}
      >
        {orderVisible === "details" ? (
          <OrderTabs orderId={order.id} openReprint={handleOpenReprint} />
        ) : (
          <OrderReprint onClose={close} />
        )}
      </D.DialogContent>
    </D.Dialog>
  );
});
