import { LoadOrdersList } from "@/@core/services/types";
import { PropsWithChildren, createContext, useCallback, useState } from "react";

type OrdersSelectedType = {
  rows: LoadOrdersList.Model[];
  onChangeRows: (rows: LoadOrdersList.Model[]) => void;
};

export const OrdersSelectedContext = createContext({} as OrdersSelectedType);

export const OrdersSelectedProvider = ({ children }: PropsWithChildren) => {
  const [rows, setRows] = useState<LoadOrdersList.Model[]>([]);

  const onChangeRows = useCallback(
    (rows: LoadOrdersList.Model[]) => setRows(rows),
    []
  );

  return (
    <OrdersSelectedContext.Provider value={{ rows, onChangeRows }}>
      {children}
    </OrdersSelectedContext.Provider>
  );
};
