import { DeletePublisher } from "@/@core/services/types";
import {
  UnexpectedError,
  CredentialsInvalid,
  ForbbidenError,
  NotFoundError,
} from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteDeletePublisher implements DeletePublisher {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async delete(): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
    });

    switch (response.statusCode) {
      case HttpStatusCode.noContent:
        return response.body!;
      case HttpStatusCode.unauthorized:
        throw new CredentialsInvalid();
      case HttpStatusCode.notFound:
        throw new NotFoundError();
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
