import { useCallback, useContext, useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

import {
  OrdersActionContext,
  OrdersSelectedContext,
} from "@/features/order/contexts";
import { useOrderStatus } from "@/features/order/hooks/use-order-status";
import { OrderTakeAction } from "@/@core/services/types";

type Props = {
  type: "book" | "cover";
  printerId: number;
  onClose: () => void;
};

export const ConfirmPrintToPrint = (props: Props) => {
  const { rows } = useContext(OrdersSelectedContext);
  const { onCloseActionModal } = useContext(OrdersActionContext);
  const { takeAction } = useOrderStatus();

  const handleConfirm = useCallback(() => {
    const payload = {
      orders: rows.map((row) => ({
        id: row.id,
        action:
          props.type === "book" ? "start_print_core" : "start_print_cover",
        printer: props.printerId,
      })),
    } as OrderTakeAction.Params;

    takeAction.mutate(payload);
  }, [rows, takeAction, props.printerId]);

  useEffect(() => {
    if (takeAction.isSuccess || takeAction.isError) {
      onCloseActionModal(true);
    }
  }, [takeAction.isSuccess, takeAction.isError]);

  return (
    <>
      <p className="text-md mb-5">
        Deseja confirmar a impressão dos pedidos selecionados?
      </p>

      <div className="flex justify-end items-center space-x-4">
        <Button variant={"ghost"} onClick={props.onClose}>
          Cancelar
        </Button>
        <Button disabled={takeAction.isPending} onClick={handleConfirm}>
          Confirmar {takeAction.isPending && <Spinner className="ml-2" />}
        </Button>
      </div>
    </>
  );
};
