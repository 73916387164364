import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { FieldInput, FieldSelect } from "@/components/form";
import { PrinterSchema } from ".";
import { Button } from "@/components/ui/button";
import { Printer } from "@/@core/services/types";
import { useCallback, useEffect } from "react";
import { Spinner } from "@/components/ui/spinner";
import { BadRequestError } from "@/@core/services/errors";
import { useUpdatePrinter } from "../../api";
import { printerTypeOptions } from "../../utils/printers-type-options";

type Props = {
  errors: FieldErrors<PrinterSchema>;
  register: UseFormRegister<PrinterSchema>;
  setValue: UseFormSetValue<PrinterSchema>;
  setError: UseFormSetError<PrinterSchema>;
  onClose: () => void;
  options: typeof printerTypeOptions;
  control: Control<PrinterSchema>;
  onSubmit: UseFormHandleSubmit<PrinterSchema>;
  printer: Printer;
};

export const UpdateForm = ({
  errors,
  register,
  onSubmit,
  onClose,
  control,
  options,
  setValue,
  setError,
  printer,
}: Props) => {
  const updatePrinter = useUpdatePrinter();

  const handleSubmit: SubmitHandler<PrinterSchema> = useCallback(
    (data) => {
      updatePrinter.mutate({
        name: data.name,
        code: data.code,
        type: data.type,
        id: printer!.id,
      });
    },
    [printer, updatePrinter]
  );

  useEffect(() => {
    if (updatePrinter.isSuccess) {
      onClose();
    }
  }, [onClose, updatePrinter.isSuccess]);

  useEffect(() => {
    if (printer) {
      setValue("name", printer.name);
      setValue("type", printer.type);
      setValue("code", printer.code);
    }
  }, [setValue, printer]);

  useEffect(() => {
    if (updatePrinter.error instanceof BadRequestError) {
      setError("code", {
        message: "Esse código já está em uso",
      });
    }
  }, [setError, updatePrinter.error]);

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <div className="grid gap-4 mt-5">
        <FieldInput
          label="Nome"
          error={errors.name}
          placeholder="Digite o nome da impressora"
          {...register("name")}
        />

        <FieldInput
          label="Código"
          error={errors.code}
          placeholder="Digite o código da impressora"
          {...register("code")}
        />

        <FieldSelect
          name="type"
          error={errors.type}
          registration={register("type")}
          control={control}
          label="Tipo da Impressora"
          placeholder="Selecione o tipo da impressora"
          options={options}
        />
      </div>

      <div className="flex justify-end gap-x-3 mt-4">
        <Button type="button" variant={"secondary"} onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={updatePrinter.isPending}>
          Atualizar{" "}
          {updatePrinter.isPending ? <Spinner className="ml-2" /> : null}
        </Button>
      </div>
    </form>
  );
};
