import { LoadPrintersList } from "@/@core/services/types";
import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteLoadPrintersList implements LoadPrintersList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadPrintersList.Response>
  ) {}

  async loadAll(
    params: LoadPrintersList.Params
  ): Promise<LoadPrintersList.Response> {
    const response = await this.httpClient.request({
      url: this.url,
      params,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
