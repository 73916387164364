import { makeRemoteGenerateCsvOrders } from "@/@core/services/factories/usecases";
import { GenerateCsvOrders } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { useMutation } from "@tanstack/react-query";
import { formatISO } from "date-fns";

const generate = (params: GenerateCsvOrders.Params) =>
  makeRemoteGenerateCsvOrders().generate(params);

export const useGenerateCsvOrders = () => {
  const { toast } = useToast();

  const formatedDate = formatISO(new Date(), {
    format: "basic",
  });

  return useMutation({
    mutationFn: generate,
    onError: () => {
      toast({
        variant: "destructive",
        title: "Erro ao gerar CSV!",
        description:
          "Não foi possivel gerar o CSV. Tente novamente mais tarde.",
      });
    },
    onSuccess: (data: any) => {
      const blob = new Blob([data], { type: "text/csv" });
      const link = document.createElement("a");

      link.download = `pedidos_${formatedDate.split("-")[0]}.csv`;

      link.href = window.URL.createObjectURL(blob);

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    },
  });
};
