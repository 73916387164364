import { makeRemoteOrderDownloadAll } from "@/@core/services/factories/usecases";
import { OrderDownloadAll } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { useMutation } from "@tanstack/react-query";
import JSZip from "jszip";

const download = (params: OrderDownloadAll.Params) =>
  makeRemoteOrderDownloadAll(params.orderId).downloadAll();

export const useOrderDownloadAll = () => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: download,
    onError: () => {
      toast({
        title: "Erro ao baixar os arquivos!",
        description: "Não foi possivel baixar todos os arquivos.",
        variant: "destructive",
      });
    },
    onSuccess: async (data: any, variables) => {
      const zip = new JSZip()

      zip.loadAsync(data).then((zip) => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const url = URL.createObjectURL(content);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${variables.shipment}-${variables.orderId}.zip`;
          a.click();
        });
      })
    },
  });
};
