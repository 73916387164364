import { Button } from "@/components/ui/button";

type Props = {
  onClose: () => void;
};

export const CannotSelectedPrintingDifferent = (props: Props) => {
  return (
    <>
      <p className="text-md text-gray-700">
        Os pedidos selecionados estão em fases diferente de impressão. Você só
        pode realizar um tipo de ação no mesmo pedido.
      </p>

      <div className="flex justify-end items-center space-x-4">
        <Button onClick={props.onClose}>Fechar</Button>
      </div>
    </>
  );
};
