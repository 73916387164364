import { useMutation } from "@tanstack/react-query";

import { queryClient } from "@/lib/react-query";

import { makeRemoteOrderTakeAction } from "@/@core/services/factories/usecases";
import { OrderAction, OrderTakeAction } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { useContext } from "react";
import { OrdersActionContext } from "../contexts";

const getErrorMessage = (action: OrderAction) => {
  switch (action) {
    case "end_print_core":
      return {
        title: "Erro ao finalizar impressão!",
        description: "Não foi possivel finalizar a impressão do miolo.",
      };
    case "end_print_cover":
      return {
        title: "Erro ao finalizar impressão!",
        description: "Não foi possivel finalizar a impressão da capa.",
      };
    case "start_print_cover":
      return {
        title: "Erro ao iniciar impressão!",
        description: "Não foi possivel iniciar a impressão da capa.",
      };
    case "start_print_core":
      return {
        title: "Erro ao finalizar impressão!",
        description: "Não foi possivel finalizar a impressão do miolo.",
      };
    case "end_finishing":
      return {
        title: "Erro ao finalizar acabamento!",
        description: "Não foi possivel finalizar o acabamento.",
      };
    case "end_dispatch":
      return {
        title: "Erro ao finalizar o pedido!",
        description: "Não foi possivel finalizar o pedido.",
      };
    case "cancel":
      return {
        title: "Erro ao cancelar o pedido!",
        description: "Não foi possivel cancelar o pedido.",
      };
  }
};

const takeAction = (params: OrderTakeAction.Params) =>
  makeRemoteOrderTakeAction().takeAction(params);

export const useOrderTakeAction = () => {
  const { onCloseActionModal } = useContext(OrdersActionContext);
  const { toast } = useToast();

  return useMutation({
    mutationFn: takeAction,
    onError: (_, variables) => {
      const order = variables.orders[0];
      const errorMessage = getErrorMessage(order.action);

      if (!errorMessage) {
        toast({
          variant: "destructive",
          title: "Erro ao realizar ação!",
          description: "Não foi possivel realizar essa ação.",
        });

        return;
      }

      toast({
        variant: "destructive",
        title: errorMessage.title,
        description: errorMessage.description,
      });
    },
    onSuccess: (_, variables) => {
      const order = variables.orders[0];

      if (order.action === "change_status") {
        return;
      }

      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
    },
    onSettled: () => {
      onCloseActionModal(true);

      queryClient.invalidateQueries({
        queryKey: ["printers-to-print"],
      });
    },
  });
};
