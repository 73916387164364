import { Route, Routes } from "react-router-dom";

import { ForgotPassword, Login, ResetPassword } from "@/features/auth";
import { Users } from "@/features/user";
import { NotFound } from "@/features/misc";
import { Publishers } from "@/features/publisher";

import { ProtectedRoute } from "./protected";
import { Printers } from "@/features/printer";
import { Order } from "@/features/order";
import { SwitchRequests } from "@/features/switch-request";
import { Settings } from "@/features/settings/routes";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Order />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/publishers" element={<Publishers />} />
        <Route path="/printers" element={<Printers />} />
        <Route path="/users" element={<Users />} />
        <Route path="/switch-requests" element={<SwitchRequests />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
