import { useContext } from "react";

import { OrdersSelectedContext } from "@/features/order/contexts";

import { BookFinishContent } from "./book-finish-content";
import { PrintersList } from "../printers-list";
import { CannotSelectedPrintingDifferent } from "../cannot-select-printing-different";

type Props = {
  onClose: () => void;
};

export const BookPrinter = (props: Props) => {
  const { rows } = useContext(OrdersSelectedContext);

  const canStartPrinting = rows.every(
    (row) => !row.status.includes("core_printing")
  );

  if (canStartPrinting) {
    return <PrintersList type="book" {...props} />;
  }

  const canFinishPrinting = rows.every((row) =>
    row.status.includes("core_printing")
  );

  if (canFinishPrinting) {
    return <BookFinishContent {...props} />;
  }

  return <CannotSelectedPrintingDifferent {...props} />;
};
