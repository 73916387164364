import axios, { AxiosResponse } from "axios";
import { HttpClient, HttpRequest, HttpResponse } from "../protocols/http";
import { storageKeys } from "@/config/storage-keys";

export class AxiosHttpClient implements HttpClient {
  private async redirect() {
    const storage = localStorage.getItem(storageKeys.accessTokenKey);
    const account = storage ? JSON.parse(storage) : null;
    if (account && account.state.accessToken) {
      window.location.href = "/login";
      localStorage.removeItem(storageKeys.accessTokenKey);
    }
  }

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse;

    try {
      axiosResponse = await axios.request({
        url: data.url,
        method: data.method,
        params: data.params,
        data: data.body,
        headers: data.headers,
        responseType: data.responseType,
      });
    } catch (error: any) {
      if (error.response.status === 401) {
        this.redirect();
      }

      axiosResponse = error.response;
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    };
  }
}
