import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/sharp-solid-svg-icons";
import { CheckIcon } from "@radix-ui/react-icons";
import { Popover, PopoverTrigger } from "@radix-ui/react-popover";
import { Button } from "../ui/button";
import { PopoverContent } from "../ui/popover";
import { cn } from "@/lib/cn";
import { Command, CommandGroup, CommandItem, CommandList } from "../ui/command";
import { Separator } from "../ui/separator";
import { Badge } from "../ui/badge";

interface Props {
  title?: string;
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  className?: string;
  searchedValues: string;
  onClear: () => void;
  onSearch: (items: string) => void;
}

export type PopoverMultiFilterRef = {
  clear: () => void;
};

export const PopoverMultiFilter = React.forwardRef<
  PopoverMultiFilterRef,
  Props
>(({ title, options, onSearch, onClear, searchedValues, className }, ref) => {
  const [selected, setSelected] = React.useState(new Set<string>());

  const handleSearch = (values: Set<string>) => {
    const items = Array.from(values);
    const hasItems = items.length > 0;
    onSearch(hasItems ? items.join(",") : "");
  };

  const handleSet = React.useCallback((value: string) => {
    setSelected((prevState) => {
      if (prevState.has(value)) {
        prevState.delete(value);
        handleSearch(prevState);
        return new Set(prevState);
      }

      handleSearch(new Set([...prevState, value]));
      return new Set([...prevState, value]);
    });
  }, []);

  React.useEffect(() => {
    if (searchedValues) {
      const values = searchedValues?.split(",");
      setSelected(new Set(values));
    }
  }, [searchedValues]);

  React.useImperativeHandle(ref, () => ({
    clear: () => {
      setSelected(new Set());
    },
  }));

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" size="sm" className="h-8 border">
          <FontAwesomeIcon icon={faFilter} className="mr-1.5" />
          {title}

          {selected?.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal lg:hidden"
              >
                {selected.size}
              </Badge>
              <div className="hidden space-x-1 lg:flex">
                {selected.size > 1 ? (
                  <Badge
                    variant="secondary"
                    className="rounded-sm px-1 font-normal"
                  >
                    {selected.size} selecionados
                  </Badge>
                ) : (
                  options
                    .filter((option) => selected.has(option.value))
                    .map((option) => (
                      <Badge
                        variant="secondary"
                        key={option.value}
                        className="rounded-sm px-1 font-normal"
                      >
                        {option.label}
                      </Badge>
                    ))
                )}
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className={`w-[200px] p-0 ${className}`} align="start">
        <Command>
          <CommandList>
            <CommandGroup>
              {options.map((option) => {
                return (
                  <CommandItem
                    key={option.value}
                    className="text-md cursor-pointer"
                    onSelect={() => handleSet(option.value)}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        selected.has(option.value)
                          ? "bg-primary text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className={cn("h-4 w-4")} />
                    </div>
                    {option.icon && (
                      <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                    )}
                    <span>{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selected.size > 0 && (
              <>
                <CommandGroup className="p-0">
                  <CommandItem
                    onSelect={() => {
                      setSelected(new Set());
                      onClear();
                    }}
                    className="justify-center font-medium py-1.5 text-center text-md cursor-pointer"
                  >
                    Limpar filtro
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
});
