import { useMutation } from "@tanstack/react-query";
import { makeRemoteDeletePublisher } from "@/@core/services/factories/usecases";
import { useToast } from "@/components/ui/use-toast";
import { MutationConfig, queryClient } from "@/lib/react-query";

const deletePublisher = (publisherId: number) =>
  makeRemoteDeletePublisher(publisherId).delete();

type UseDeletePublisherOptions = {
  config?: MutationConfig<typeof deletePublisher>;
};

export const useDeletePublisher = ({
  config,
}: UseDeletePublisherOptions = {}) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, context: any) => {
      toast({
        variant: "destructive",
        title: "Erro ao deletar editora!",
        description: "Ocorreu um erro ao deletar a editora.",
      });

      if (context?.previousDiscussions) {
        queryClient.setQueryData(["publishers"], context.previousUsers);
      }
    },
    onSuccess: () => {
      toast({
        title: "Editora deletada!",
        description: "Editora deletada com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["publishers"],
      });
    },
    mutationFn: deletePublisher,
    ...config,
  });
};
