import { makeLoadUsersList } from "@/@core/services/factories/usecases";
import { LoadUserList } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "@tanstack/react-query";

const loadAll = (params: LoadUserList.Params) =>
  makeLoadUsersList().loadAll(params);

type QueryFnType = typeof loadAll;

type useLoadUserListOptions = {
  params: LoadUserList.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadUserList = ({ params, config }: useLoadUserListOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["users"],
    queryFn: () => loadAll(params),
    ...config,
  });
};
