import { Button } from "@/components/ui/button";
import { OrdersActionContext } from "@/features/order/contexts";
import { useContext, useEffect } from "react";

type Props = {
  onClose: () => void;
};

export const PrinterWithoutActionAvaiable = (props: Props) => {
  const { onChangeCurrentAction } = useContext(OrdersActionContext);

  useEffect(() => {
    //@ts-ignore
    onChangeCurrentAction("printer_admin");

    return () => {
      //@ts-ignore
      onChangeCurrentAction("none");
    };
  }, []);

  return (
    <>
      <p className="text-md text-gray-700">
        Um ou mais pedidos selecionados não possui uma ação de impressão
        disponível.
      </p>

      <div className="flex justify-end items-center space-x-4">
        <Button onClick={props.onClose}>Fechar</Button>
      </div>
    </>
  );
};
