import { useMutation } from "@tanstack/react-query";

import { makeRemoteResendSwitchRequest } from "@/@core/services/factories/usecases/remote-resend-switch-request-factory";
import { ResendSwitchRequest } from "@/@core/services/types";

const resend = (params: ResendSwitchRequest.Params) =>
  makeRemoteResendSwitchRequest(params.switchRequest).resend();

export const useResendSwitchRequest = () => {
  return useMutation({
    mutationFn: resend,
  });
};
