import { formatDate } from "@/utils/format-date";
import { Order } from "@/@core/services/types";
import { History } from "./history";
import { Spinner } from "@/components/ui/spinner";
import {
  calculateTotalPages,
  calculateTotalPagesCoreReprint,
  calculateTotalPagesCoverReprint,
  calculateTotalQuantity,
  calculateTotalQuantityCoreReprint,
  calculateTotalQuantityCoverReprint,
} from "@/features/order/utils/calculate-order";
import { orderTypeOptions } from "@/features/order/utils/filter-options";

type Props = {
  order: Order | undefined;
  isLoading: boolean;
};

const mapper = (order: Order): Record<string, string> => ({
  "Tipo do pedido": orderTypeOptions.find((opt) => opt.value.toLowerCase() === order.type.toLowerCase())?.label ?? "",
  "Código do Pedido": order.id.toString(),
  Remessa: order.shipment,
  "Nome do Solicitante": order.publisher ? order.publisher.name : "",
  "Data de Abertura": formatDate(order.opened_at),
  Prazo: formatDate(order.due_date),
  Urgente: order.urgent ? "Sim" : "Não",
  Refaz: order.is_reprint ? "Sim" : "Não",
  "Total de SKU": order.products.length.toString(),
});

const totalMapper = (order: Order): Record<string, string> => {
  if (order.is_reprint) {
    return {
      "Total de Páginas do Miolo": calculateTotalPagesCoreReprint(
        order.products
      ).toString(),
      "Total de Páginas da Capa": calculateTotalPagesCoverReprint(
        order.products
      ).toString(),
      "Total de Volume de Miolo": calculateTotalQuantityCoreReprint(
        order.products
      ).toString(),
      "Total de Volume de Capa": calculateTotalQuantityCoverReprint(
        order.products
      ).toString(),
    };
  }

  return {
    "Total de Páginas": calculateTotalPages(order.products).toString(),
    "Total de Itens": calculateTotalQuantity(order.products).toString(),
  };
};

export const General = ({ order, isLoading }: Props) => {
  if (isLoading) {
    return (
      <div className="mt-10 flex items-center justify-center">
        <Spinner className="mx-auto h-6 w-6" />
      </div>
    );
  }

  if (!order) return null;

  const orderMapped = {
    ...mapper(order),
    ...totalMapper(order),
  };

  return (
    <div className="grid grid-cols-2 gap-x-6">
      <div>
        {Object.entries(orderMapped).map(([key, value], idx) => (
          <div className="flex flex-col mb-3 space-y-0.5" key={idx}>
            <span className="font-semibold text-md">{key}</span>
            <span className="text-md">{value}</span>
          </div>
        ))}
      </div>

      <History history={order.history} order={order} />
    </div>
  );
};
