import { OrderOptions } from "../order-options";
import { DataTableFilter } from "./data-table-filter";

type Props = {
  hasOrders: boolean;
};

export function DataTableHeader(props: Props) {
  return (
    <div className="flex">
      <DataTableFilter />
      <OrderOptions {...props} />
    </div>
  );
}
