import { makeRemoteAuthentication } from "@/@core/services/factories/usecases";
import { Authentication } from "@/@core/services/types";
import { useAccountStore } from "@/stores";
import { useMutation } from "@tanstack/react-query";

const login = (data: Authentication.Params): Promise<Authentication.Model> =>
  makeRemoteAuthentication().auth(data);

export const useAuthentication = () => {
  const { setToken } = useAccountStore();

  return useMutation({
    onSuccess: (account) => setToken(account.accessToken),
    mutationFn: login,
  });
};
