import { useUserStore } from "@/stores";
import { useOrderTakeAction } from "../api";
import { useCallback } from "react";
import { OrderStatus, OrderStatusEnum } from "@/@core/services/types";

interface OrderStatusHook {
  takeAction: ReturnType<typeof useOrderTakeAction>;
  allowedStatusForUser: () => OrderStatus[] | null;
}

export function useOrderStatus(): OrderStatusHook {
  const { user } = useUserStore();
  const takeAction = useOrderTakeAction();

  const allowedStatusForUser = useCallback((): OrderStatus[] | null => {
    if (!user) return null;

    switch (user.type) {
      case "BookPrinter":
        return [
          "core_printing",
          "cover_print_requested",
          "cover_print_finished",
          "cover_printing",
          "waiting_print",
        ];
      case "CoverPrinter":
        return [
          "cover_printing",
          "core_print_requested",
          "core_print_finished",
          "core_printing",
          "waiting_print",
        ];
      case "Finisher":
        return ["finishing"];
      case "Expeditor":
        return ["shipping"];
      default:
        return Object.keys(OrderStatusEnum) as OrderStatus[];
    }
  }, [user]);

  return { takeAction, allowedStatusForUser };
}
