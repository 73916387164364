import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import * as I from "lucide-react";

import logo from "@/assets/imgs/logo-print-park.svg";
import shortLogo from "@/assets/imgs/short-logo-print-park.svg";
import { useUserStore } from "@/stores";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as F from "@fortawesome/sharp-solid-svg-icons"

export const MainLayout = ({ children }: PropsWithChildren) => {
  const [fixed, setFixed] = useState(false)
  const [collapsed, setCollapsed] = React.useState(true)
  const [collapsedAfterTimeout, setCollapsedAfterTimeout] = React.useState(() => collapsed)

  const { user } = useUserStore();
  const { pathname } = useLocation();

  const paths = useMemo(
    () => [
      {
        path: "/",
        icon: F.faBullseyePointer,
        label: "Pedidos",
        isAllowed: true,
      },
      {
        path: "/publishers",
        icon: F.faBriefcase,
        label: "Editoras",
        isAllowed: user?.type === "Administrator",
      },
      {
        path: "/printers",
        icon: F.faPrint,
        label: "Impressoras",
        isAllowed: user?.type === "Administrator",
      },
      {
        path: "/users",
        icon: F.faUsers,
        label: "Usuários",
        isAllowed: user?.type === "Administrator",
      },
      {
        path: "/switch-requests",
        icon: F.faWebhook,
        label: "Requisições",
        isAllowed: user?.type === "Administrator",
      },
    ],
    [user]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (fixed) return

    if (!collapsed) {
      timeout = setTimeout(() => {
        setCollapsedAfterTimeout(collapsed)
      }, 105);
    } else {
      setCollapsedAfterTimeout(collapsed)
    }

    return () => clearTimeout(timeout)
  }, [collapsed, fixed])

  const collapsing = collapsed && !fixed

  return (
    <div className={classNames({
      "grid grid-cols-[2fr, 1fr]": !fixed,
      "flex": fixed,
    })}>
      <aside
        onMouseLeave={() => setCollapsed(true)}
        onMouseEnter={() => setCollapsed(false)}
        aria-label="Sidebar"
        className={classNames("h-screen transition-all duration-200", {
          "w-[16rem] max-w-[16rem]": !collapsing,
          "absolute z-50": !fixed,
          "w-[4rem]": collapsing,
        })}
      >
        <div className={classNames("h-full relative overflow-x-hidden py-4 overflow-y-auto bg-blue-dark max-w-[14rem]")}>
          {!collapsedAfterTimeout && (
            <div className="p-4 absolute cursor-pointer right-[-8px] top-0">
              <I.Pin
                color="white"
                size={15}
                onClick={() => setFixed(prevState => !prevState)}
              />
            </div>
          )}

          {fixed && (
            <div className="p-4 absolute cursor-pointer right-[-8px] top-0">
              <I.PinOff
                color="white"
                size={15}
                onClick={() => setFixed(prevState => !prevState)}
              />
            </div>

          )}

          <a href="/">
            <img
              src={collapsing ? shortLogo : logo}
              alt="print-park-logo"
              className="max-w-none h-9 ms-2.5 mb-6 cursor-pointer"
            />
          </a>

          {user && user.type ? (
            <ul className="space-y-3 font-medium">
              {paths.map(({ icon, label, path, isAllowed }) => (
                <React.Fragment key={label}>
                  {isAllowed ? (
                    <li key={path} className="mx-3">
                      <Link
                        target={path === "/" ? "_parent" : "_self"}
                        to={path}
                        className={classNames(
                          "flex items-center space-x-4 ps-2.5 transition-all duration-300 h-8 text-gray-900 rounded-lg hover:bg-blue-main/90 dark:hover:bg-gray-700 group",
                          {
                            "bg-blue-main": pathname === path,
                          }
                        )}
                      >
                        <FontAwesomeIcon icon={icon} size="1x" color="white"/>

                        <span className={classNames("text-base mt-0.5 text-white", {
                          "hidden": collapsedAfterTimeout,
                        })}>
                          {label}
                        </span>
                      </Link>
                    </li>
                  ) : null}
                </React.Fragment>
              ))}
            </ul>
          ) : null}
        </div>
      </aside>

      <div className={classNames("w-full pe-5 overflow-y-auto h-screen transition-all duration-300", {
        "pl-[82px]": !fixed,
        "ps-[16px]": fixed,
      })}>{children}</div>
    </div>
  );
};
