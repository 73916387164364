import { useMutation } from "@tanstack/react-query";
import { makeRemoteDeletePrinter } from "@/@core/services/factories/usecases";
import { useToast } from "@/components/ui/use-toast";
import { MutationConfig, queryClient } from "@/lib/react-query";

const deletePrinter = (printerId: number) =>
  makeRemoteDeletePrinter(printerId).delete();

type UseDeletePrinterOptions = {
  config?: MutationConfig<typeof deletePrinter>;
};

export const useDeletePrinter = ({ config }: UseDeletePrinterOptions = {}) => {
  const { toast } = useToast();

  return useMutation({
    onError: (_, __, context: any) => {
      toast({
        variant: "destructive",
        title: "Erro ao deletar impressora!",
        description: "Ocorreu um erro ao deletar a impressora.",
      });

      if (context?.previousDiscussions) {
        queryClient.setQueryData(["printers"], context.previousUsers);
      }
    },
    onSuccess: () => {
      toast({
        title: "Impressora deletada!",
        description: "Impressora deletada com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["printers"],
      });
    },
    mutationFn: deletePrinter,
    ...config,
  });
};
