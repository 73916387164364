import { makeRemoteCreatePublisher } from "@/@core/services/factories/usecases";
import { CreatePublisher } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const create = (params: Omit<CreatePublisher.Params, "id">) =>
  makeRemoteCreatePublisher().save(params);

export const useCreatePublisher = () => {
  const { toast } = useToast();
  return useMutation({
    onSuccess: () => {
      toast({
        title: "Editora criada!",
        description: "Editora criada com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["publishers"],
      });
    },
    mutationFn: create,
  });
};
