import { ForbbidenError } from "@/@core/services/errors";
import { makeRemoteOrderResendSwitch } from "@/@core/services/factories/usecases";
import { ResendSwitch } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { useMutation } from "@tanstack/react-query";

const resend = (params: ResendSwitch.Params) =>
  makeRemoteOrderResendSwitch().resend(params);

export const useOrderResendSwitch = () => {
  const { toast } = useToast();

  return useMutation({
    mutationFn: resend,
    onError: (error) => {
      toast({
        title: "Erro ao reenviar o pedido!",
        description:
          error instanceof ForbbidenError
            ? "Você não tem permissão para reenviar o pedido."
            : "Não foi possivel reenviar o pedido.",
        variant: "destructive",
      });
    },
  });
};
