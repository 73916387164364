import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { LoadSwitchRequestsList } from "../types/switch-request";

export class RemoteLoadSwitchRequestsList implements LoadSwitchRequestsList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadSwitchRequestsList.Response>
  ) {}

  async loadAll(
    params: LoadSwitchRequestsList.Params
  ): Promise<LoadSwitchRequestsList.Response> {
    const response = await this.httpClient.request({
      url: this.url,
      params,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
