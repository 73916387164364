import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useDisclosure } from "@/hooks";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { Spinner } from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";
import { useDeletePublisher } from "../api";

export type PublishersDeleteRef = {
  open: (publisherId: number) => void;
};

export const PublishersDelete = forwardRef<PublishersDeleteRef, unknown>(
  (_, ref) => {
    const { open, close, isOpen } = useDisclosure();
    const [publisherIdSelected, setPublisherIdSeletec] = useState<
      number | null
    >(null);

    const deletePublisher = useDeletePublisher();

    const handleOpen = (publisherId: number) => {
      open();
      setPublisherIdSeletec(publisherId);
    };

    const handleConfirm = useCallback(async () => {
      if (publisherIdSelected) {
        await deletePublisher.mutateAsync(publisherIdSelected);
      }
    }, [deletePublisher, publisherIdSelected]);

    useImperativeHandle(ref, () => ({
      open: (publisherId) => handleOpen(publisherId),
    }));

    return (
      <AlertDialog open={isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Deletar editora</AlertDialogTitle>
            <AlertDialogDescription className="text-md text-gray-700">
              Tem certeza que deseja realizar essa ação?
            </AlertDialogDescription>
          </AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button variant={"ghost"} onClick={close}>
              Cancelar
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={deletePublisher.isPending}
            >
              Confirmar{" "}
              {deletePublisher.isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </AlertDialogContent>
      </AlertDialog>
    );
  }
);
