import { useUserStore } from "@/stores";
import { BookCoverPrinterAdmin } from "./book-cover-printer/admin";
import { BookPrinter } from "./book-cover-printer/book";
import { CoverPrinter } from "./book-cover-printer/cover";
import { RenderStatusTypes } from "../../hooks/use-order-action";

type Props = {
  onClose: () => void;
  status: RenderStatusTypes;
};

export const BookCoverPrinterAction = (props: Props) => {
  const { user } = useUserStore();

  if (!user) return null;

  if (user.type === "Administrator") {
    return <BookCoverPrinterAdmin {...props} />;
  }

  if (user.type === "BookPrinter") {
    return <BookPrinter {...props} />;
  }

  if (user.type === "CoverPrinter") {
    return <CoverPrinter {...props} />;
  }

  return null;
};
