import { forwardRef, useImperativeHandle } from "react";

import { useDisclosure } from "@/hooks";

import * as A from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

export type ResendNotAvaiableForStatusRef = {
  open: () => void;
};

export const ResendNotAvaiableForStatus = forwardRef<
  ResendNotAvaiableForStatusRef,
  unknown
>((_, ref) => {
  const { close, isOpen, open } = useDisclosure();

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    [open]
  );

  return (
    <A.AlertDialog open={isOpen}>
      <A.AlertDialogContent>
        <A.AlertDialogHeader>
          <A.AlertDialogTitle>Atenção!</A.AlertDialogTitle>
          <A.AlertDialogDescription className="text-md text-gray-700">
            Você está tentando reenviar arquivos de um pedido que não está com o
            status permitido para essa ação.
          </A.AlertDialogDescription>
        </A.AlertDialogHeader>

        <div className="flex justify-end items-center space-x-4">
          <Button onClick={close}>Fechar</Button>
        </div>
      </A.AlertDialogContent>
    </A.AlertDialog>
  );
});
