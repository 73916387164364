import {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, UseFormReturn } from "react-hook-form";
import {
  faChevronLeft,
  faChevronRight,
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";

type Order = {
  field: string;
  direction: "asc" | "desc";
};

type DataTableProviderProps<TFilters extends FieldValues> = {
  filterForm?: UseFormReturn<TFilters>;
  defaultValues?: {
    filters?: FieldValues;
    perPage?: number;
    page?: number;
    order?: Order;
  };
  children?: React.ReactNode;
};

type DataTableContext<TFilters extends FieldValues> =
  DataTableProviderProps<TFilters> & {
    onSubmit: SubmitHandler<TFilters>;

    filters: DataTableProviderProps<TFilters>["filterForm"] extends undefined
      ? never
      : FieldValues;
    order?: Order;
    page: number;
    perPage: number;
    onSort: (field: string, direction: "asc" | "desc") => void;
    onPerPageChange: (perPage: number) => void;
    onPageChange: (page: number) => void;
  };

const DataTableContext = createContext<DataTableContext<any>>(
  null as unknown as DataTableContext<any>
);

function Root<TFilters extends FieldValues>(
  props: DataTableProviderProps<TFilters>
) {
  const navigate = useNavigate();
  const location = useLocation();

  const [order, setOrder] = useState<Order | undefined>(() => {
    const params = new URLSearchParams(window.location.search);
    const order = params.get("order");
    if (!order) return props.defaultValues?.order;
    const [field, direction] = order.split(",");
    return { field, direction: direction as "asc" | "desc" };
  });

  const [page, setPage] = useState<number>(() => {
    const params = new URLSearchParams(window.location.search);
    const page = params.get("page");
    if (!page) return props.defaultValues?.page || 1;
    return Number(page);
  });

  const [perPage, setPerPage] = useState<number>(() => {
    const params = new URLSearchParams(window.location.search);
    const perPage = params.get("perPage");
    if (!perPage) return props.defaultValues?.perPage || 10;

    return Number(perPage);
  });

  const [filters, setFilters] = useState<FieldValues>(() => {
    const params = new URLSearchParams(window.location.search);
    const filters: FieldValues = {};

    params.forEach((value, key) => {
      if (key !== "page" && key !== "perPage" && key !== "order") {
        filters[key] = value;
        if (props.filterForm && value && value !== "undefined") {
          props.filterForm.setValue(key as any, value as any);
        }
      }
    });

    if (Object.keys(filters).length === 0) {
      if (props.defaultValues?.filters) {
        Object.entries(props.defaultValues?.filters).forEach(([key, value]) => {
          filters[key] = value;
          if (props.filterForm) {
            props.filterForm.setValue(key as any, value as any);
          }
        });
      }
    }

    return filters;
  });

  const onSort = (field: string, direction: "asc" | "desc") => {
    setOrder({ field, direction });
  };

  const onPerPageChange = (perPage: number) => {
    setPage(1);
    setPerPage(perPage);
  };

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onSubmit: SubmitHandler<TFilters> = (data) => {
    if (!props.filterForm) return;

    setPage(1);
    // if an entry is object, get only the value
    Object.entries(data).forEach(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        if (value.value) {
          data[key as keyof typeof data] = value.value;
        }
      }

      if (Array.isArray(value) && value) {
        //@ts-ignore
        data[key as keyof typeof data] = value
          .map((value) => value.value)
          .join(",");
      }
    });
    setFilters(data);
  };

  useEffect(() => {
    if (location.search === "") {
      setFilters({});
      setPage(1);
    }
  }, [location.search]);

  useEffect(() => {
    // add to query params
    const params = new URLSearchParams();
    params.set("page", page.toString());
    params.set("perPage", perPage.toString());

    if (order) {
      params.set("order", `${order.field},${order.direction}`);
    }

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        //if value is object and has value, set it
        if (typeof value === "object" && value !== null) {
          if (value.value) {
            params.set(key, value.value);
          }
          return;
        }

        params.set(key, value);
      });
    }

    navigate({ search: params.toString() });
  }, [order, page, perPage, filters, navigate]);

  return (
    <DataTableContext.Provider
      value={{
        ...props,
        onSubmit,
        onSort,
        onPageChange,
        onPerPageChange,
        page,
        perPage,
        order,
        filters,
      }}
    >
      {props.children}
    </DataTableContext.Provider>
  );
}

function useDataTable<TFilters extends FieldValues>() {
  return useContext(DataTableContext) as DataTableContext<TFilters>;
}

const PerPageSelect = ({
  values = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
  ],
}: {
  values?: { value: number; label: string }[];
}) => {
  const { perPage, onPerPageChange } = useDataTable();

  return (
    <div className="flex items-center space-x-2">
      <p className="text-md font-medium">Mostrar</p>
      <Select
        value={perPage.toString()}
        onValueChange={(value) => onPerPageChange(Number(value))}
      >
        <SelectTrigger className="h-8 w-[70px]">
          <SelectValue placeholder="Page" />
        </SelectTrigger>
        <SelectContent side="top">
          {values.map((v) => (
            <SelectItem key={v.value} value={`${v.value}`}>
              {v.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

const Pagination = ({ total }: { total: number }) => {
  const { page, onPageChange } = useDataTable();

  const handlePreviousPage = useCallback(() => {
    if (page === 1) return;

    onPageChange(page - 1);
  }, [page, onPageChange]);

  const handleNextPage = useCallback(() => {
    onPageChange(page + 1);
  }, [page, onPageChange]);

  return (
    <div className="flex items-center space-x-2">
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        Página {page} de {total}
      </div>
      <div className="flex items-center space-x-2">
        <Button
          variant="outline"
          className="hidden h-7 w-7 p-0 disabled:bg-slate-100 lg:flex"
          onClick={() => onPageChange(1)}
          disabled={page === 1}
        >
          <FontAwesomeIcon size="sm" icon={faChevronsLeft} />
        </Button>
        <Button
          variant="outline"
          className="h-7 w-7 p-0 disabled:bg-slate-100"
          onClick={handlePreviousPage}
          disabled={page === 1}
        >
          <FontAwesomeIcon size="sm" icon={faChevronLeft} />
        </Button>
        <Button
          variant="outline"
          className="h-7 w-7 p-0 disabled:bg-slate-100"
          onClick={handleNextPage}
          disabled={page === total}
        >
          <FontAwesomeIcon size="sm" icon={faChevronRight} />
        </Button>
        <Button
          variant="outline"
          className="hidden h-7 w-7 p-0 disabled:bg-slate-100 lg:flex "
          onClick={() => onPageChange(total)}
          disabled={page === total}
        >
          <FontAwesomeIcon size="sm" icon={faChevronsRight} />
        </Button>
      </div>
    </div>
  );
};

export { Root, useDataTable, PerPageSelect, Pagination };
