import { UserNav } from "@/features/user/components/user-nav";
import { PropsWithChildren } from "react";

type Props = {
  title: string;
} & PropsWithChildren;

export const ContentLayout = ({ title, children }: Props) => {
  return (
    <div className="mt-6">
      <div className="flex items-center justify-between space-y-2">
        <h2 className="text-2xl text-blue-main font-semibold tracking-tight">
          {title}
        </h2>
        <UserNav />
      </div>
      <div className="mt-3 py-5 rounded-sm">{children}</div>
    </div>
  );
};
