import { useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faHashtag,
  faInputText,
  faPenToSquare,
  faTrashXmark,
} from "@fortawesome/sharp-solid-svg-icons";

import { LoadPrintersList } from "@/@core/services/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { DataTableOrder } from "@/components/data-table";
import { PrintersForm, PrintersFormRef } from "../components/form";
import {
  PrintersDelete,
  PrintersDeleteRef,
} from "../components/printers-delete";
import { printerType } from "../utils/printers-type-options";

export const useColumns = () => {
  const printersDeleteRef = useRef<PrintersDeleteRef>(null);
  const printersFormRef = useRef<PrintersFormRef>(null);

  const columns: ColumnDef<LoadPrintersList.Model>[] = [
    {
      accessorKey: "name",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faInputText} />}
          accessor="name"
          title="Nome"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
    },
    {
      accessorKey: "code",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faHashtag} />}
          accessor="code"
          title="Código"
        />
      ),
      cell: ({ row }) => <>{row.getValue("code")}</>,
    },
    {
      accessorKey: "type",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faHashtag} />}
          accessor="code"
          title="Tipo de impressora"
        />
      ),
      cell: ({ row }) => {
        const type =
          printerType[row.getValue("type") as keyof typeof printerType];
        return <>{type}</>;
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <FontAwesomeIcon icon={faEllipsis} size="lg" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    printersDeleteRef.current?.open(row.original.id)
                  }
                >
                  <FontAwesomeIcon icon={faTrashXmark} size="1x" />{" "}
                  <span className="ms-2">Remover impressora</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() => printersFormRef.current?.open(row.original)}
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />{" "}
                  <span className="ms-2">Editar impressora</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <PrintersForm ref={printersFormRef} type="update" />
            <PrintersDelete ref={printersDeleteRef} />
          </>
        );
      },
    },
  ];

  return { columns };
};
