import * as z from "zod";

import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldInput } from "@/components/form";
import { useAuthentication } from "@/features/auth";
import { Spinner } from "@/components/ui/spinner";
import { CredentialsInvalid } from "@/@core/services/errors";
import { useEffect } from "react";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "O email precisa ser informado" })
    .email({ message: "O email precisa ser válido" }),
  password: z.string().min(1, { message: "A senha precisa ser informada" }),
});

type LoginSchema = z.infer<typeof schema>;
type Props = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginSchema>({
    resolver: zodResolver(schema),
  });

  const auth = useAuthentication();

  const onSubmit: SubmitHandler<LoginSchema> = (data) => auth.mutate(data);

  useEffect(() => {
    if (auth.isSuccess) {
      onSuccess();
    }
  }, [auth.isSuccess, onSuccess]);

  const crendentialsIsInvalid = auth.error instanceof CredentialsInvalid;

  return (
    <div className="grid gap-6">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FieldInput
            label="Email"
            error={errors.email}
            placeholder="Digite seu email"
            type="email"
            {...register("email")}
          />

          <FieldInput
            label="Senha"
            error={errors.password}
            placeholder="Digite sua senha"
            type="password"
            autoComplete="current-password"
            {...register("password")}
          />

          {crendentialsIsInvalid && (
            <small className="text-danger text-center">
              Credenciais inválidas
            </small>
          )}

          <Button type="submit" disabled={auth.isPending}>
            Entrar {auth.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </form>
    </div>
  );
};
