import * as React from "react";
import { format, formatISO } from "date-fns";
import { DateRange } from "react-day-picker";

import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/cn";
import { ptBR } from "date-fns/locale";
import { Input } from "../ui/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/sharp-solid-svg-icons"

export type PopoverDateTimeRangeRef = {
  clear: () => void;
};

type TimeFrom = {
  from: string;
  to: string;
};

type Props = {
  placeholder: string;
  searchedValue?: TimeFrom;
  onSearch: (timeFrom: TimeFrom | undefined) => void;
  onClear: () => void;
} & React.HTMLAttributes<HTMLDivElement>;

export const PopoverDateTimeRange = React.forwardRef<
  PopoverDateTimeRangeRef,
  Props
>(({ className, placeholder, searchedValue, onClear, onSearch }, ref) => {
  const [date, setDate] = React.useState<DateRange | undefined>(() => {
    if (!searchedValue) return undefined;

    return {
      from: searchedValue.from ? new Date(searchedValue.from) : undefined,
      to: searchedValue.to ? new Date(searchedValue.to) : undefined,
    };
  });

  const [time, setTime] = React.useState<TimeFrom>(() => {
    if (!searchedValue) return { from: "00:00", to: "00:00" };

    return {
      from: searchedValue.from
        ? format(new Date(searchedValue.from), "HH:mm")
        : "00:00",
      to: searchedValue.to
        ? format(new Date(searchedValue.to), "HH:mm")
        : "00:00",
    };
  });

  const popoverTriggerRef = React.useRef<any>(null);

  const handleTimeChange = (value: string, type: "from" | "to") => {
    setTime((prevState) => ({ ...prevState, [type]: value }));

    if (!date?.from && !date?.to) return;

    const [hours, minutes] = value.split(":").map((str) => parseInt(str, 10));

    setDate((prevState) => {
      if (!prevState) return prevState;

      if (type === "from") {
        return {
          ...prevState,
          from: new Date(
            date.from!.getFullYear(),
            date.from!.getMonth(),
            date.from!.getDate(),
            hours,
            minutes
          ),
        };
      }

      if (type === "to" && date.to) {
        return {
          ...prevState,
          to: new Date(
            date.to.getFullYear(),
            date.to.getMonth(),
            date.to.getDate(),
            hours,
            minutes
          ),
        };
      }
    });
  };

  const handleDateChange = (date: DateRange | undefined) => {
    if (!date) return;

    if (date.from && !date.to) {
      const [hours, minutes] = time.from
        .split(":")
        .map((str) => parseInt(str, 10));

      setDate((prevState) => ({
        ...prevState,
        from: new Date(
          date.from!.getFullYear(),
          date.from!.getMonth(),
          date.from!.getDate(),
          hours,
          minutes
        ),
      }));

      return;
    }

    const [fromHours, fromMinutes] = time.from
      .split(":")
      .map((str) => parseInt(str, 10));

    const [toHours, toMinutes] = time.to
      .split(":")
      .map((str) => parseInt(str, 10));

    setDate(() => ({
      to: new Date(
        date.to!.getFullYear(),
        date.to!.getMonth(),
        date.to!.getDate(),
        toHours,
        toMinutes
      ),
      from: new Date(
        date.from!.getFullYear(),
        date.from!.getMonth(),
        date.from!.getDate(),
        fromHours,
        fromMinutes
      ),
    }));
  };

  const handleClear = () => {
    setDate(undefined);
    onSearch(undefined);
    onClear();
  };

  React.useImperativeHandle(ref, () => ({
    clear: () => {
      setDate(undefined);
      setTime({ from: "00:00", to: "00:00" });
    },
  }));

  React.useEffect(() => {
    onSearch({
      from: date?.from ? formatISO(date.from) : "",
      to: date?.to ? formatISO(date.to) : "",
    });
  }, [date]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild ref={popoverTriggerRef}>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-auto justify-start text-left text-dark font-medium",
              !date && "text-dark"
            )}
          >
            <FontAwesomeIcon icon={faFilter} className="mr-1.5"/>
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "dd/MM/yyyy HH:mm", { locale: ptBR })} -{" "}
                  {format(date.to, "dd/MM/yyyy HH:mm", { locale: ptBR })}
                </>
              ) : (
                format(date.from, "dd/MM/yyyy HH:mm", { locale: ptBR })
              )
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={handleDateChange}
            className="pb-0"
            numberOfMonths={2}
          />

          <div className="flex items-center space-x-3 px-3 py-3">
            <Input
              type="time"
              className="min-w-[140px]"
              value={time.from}
              onChange={(e) => handleTimeChange(e.target.value, "from")}
            />
            <span
              className={`text-muted-foreground ${
                date?.to ? "visible" : "invisible"
              }`}
            >
              -
            </span>
            <Input
              className={`min-w-[140px] ${date?.to ? "visible" : "invisible"}`}
              type="time"
              value={time.to}
              onChange={(e) => handleTimeChange(e.target.value, "to")}
            />
          </div>

          {date && (
            <Button
              variant="ghost"
              className="w-full mx-0 my-1 ps-0 h-7"
              onClick={handleClear}
            >
              Limpar filtro
            </Button>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
});
