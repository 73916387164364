import * as I from "lucide-react";

import { Product } from "@/@core/services/types/products";
import { Spinner } from "@/components/ui/spinner";
import { Authorization, ROLES } from "@/lib/authorization";

import { ProductDownload } from "./product-download";
import { OrderProduct } from "../../order-product";

type Props = {
  products: Product[];
  isLoading: boolean;
};

export const Products = ({ products, isLoading }: Props) => {
  if (isLoading) {
    return (
      <div className="mt-10 flex items-center justify-center">
        <Spinner className="mx-auto h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4 pe-5">
      {products.map((product) => (
        <div className="space-y-2.5" key={`product-details-${product.id}`}>
          <OrderProduct product={product}>
            <div className="ml-auto font-medium flex items-center space-x-3">
              <span>
                {product.core_total}
                <span className="text-md">un</span>
              </span>
              <Authorization
                allowedRoles={[ROLES.Administrator, ROLES.CoverPrinter]}
              >
                <ProductDownload
                  tooltipName="Download da Capa"
                  icon={
                    <I.FileDown size={18} className="me-0.5 cursor-pointer" />
                  }
                  url={decodeURIComponent(product.cover_url)}
                  outputName={product.name}
                />
              </Authorization>

              <Authorization
                allowedRoles={[ROLES.Administrator, ROLES.BookPrinter]}
              >
                <ProductDownload
                  tooltipName="Download do Miolo"
                  icon={
                    <I.FolderDown
                      size={18}
                      className="me-0.5 cursor-pointer mt-[4px]"
                    />
                  }
                  url={decodeURIComponent(product.core_url)}
                  outputName={product.name}
                />
              </Authorization>
            </div>
          </OrderProduct>
        </div>
      ))}
    </div>
  );
};
