import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import { useDisclosure } from "@/hooks";

import * as A from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useOrderReprintStore } from "@/features/order/stores";
import { useReprintOrder } from "@/features/order/api";
import { ReprintOrder } from "@/@core/services/types";
import { Spinner } from "@/components/ui/spinner";

export interface ConfirmReprintRef {
  open: () => void;
}

type Props = {
  orderId: number;
  onSuccess: () => void;
};

export const ConfirmReprint = forwardRef<ConfirmReprintRef, Props>(
  ({ orderId, onSuccess }, ref) => {
    const { products } = useOrderReprintStore();
    const { close, isOpen, open } = useDisclosure();

    const { mutate, isPending, isSuccess, isError } = useReprintOrder();

    const handleConfirm = useCallback(() => {
      const data: ReprintOrder.Params["products"] = products.map((product) => ({
        id: product.productId,
        core_total: product.coreQuantity ?? 0,
        cover_total: product.coverQuantity ?? 0,
      }));

      mutate({
        products: data,
        orderId,
      });
    }, [mutate, orderId, products]);

    useImperativeHandle(ref, () => ({ open }), [open]);

    useEffect(() => {
      if (isSuccess) {
        onSuccess();
        close();
      }
    }, [close, isSuccess]);

    useEffect(() => {
      if (isError) {
        onSuccess();
        close();
      }
    }, [close, isError]);

    return (
      <A.AlertDialog open={isOpen}>
        <A.AlertDialogContent>
          <A.AlertDialogHeader>
            <A.AlertDialogTitle>Refazer Itens do pedido</A.AlertDialogTitle>
            <A.AlertDialogDescription className="text-md text-gray-700">
              Tem certeza que deseja realizar essa ação?
            </A.AlertDialogDescription>
          </A.AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button variant={"ghost"} onClick={close}>
              Cancelar
            </Button>
            <Button onClick={handleConfirm} disabled={isPending}>
              Confirmar {isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </A.AlertDialogContent>
      </A.AlertDialog>
    );
  }
);
