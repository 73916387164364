import { useDataTable } from "@/components/data-table";
import { PublishersHeader } from "./publishers-header";
import { DataTable } from "./table/data-table";
import { PublisherFilterSchema } from "../types";
import { useLoadPublishersList } from "../api";
import { useEffect, useMemo } from "react";

export const PublishersList = () => {
  const { page, perPage, order, filters } =
    useDataTable<PublisherFilterSchema>();

  const publishersList = useLoadPublishersList({
    params: {
      page,
      per_page: perPage,
      sort_direction: order?.direction,
      sort_by: order?.field,
      name: filters?.name ? filters.name : undefined,
      code: filters?.code ? filters.code : undefined,
    },
  });

  const data = useMemo(() => {
    return publishersList.data ? publishersList.data.data : [];
  }, [publishersList.data]);

  useEffect(() => {
    publishersList.refetch();
  }, [page, perPage, order, filters]);

  const isLoading = publishersList.isPending || publishersList.isFetching;

  return (
    <div className="space-y-3">
      <PublishersHeader />
      <DataTable
        isLoading={isLoading}
        publishers={data}
        meta={publishersList.data?.meta}
      />
    </div>
  );
};
