export const printerTypeOptions = [
  {
    label: "Todos",
    value: "all",
  },
  {
    label: "Impressora de Capa",
    value: "cover_printer",
  },
  {
    label: "Impressora de Miolo",
    value: "core_printer",
  },
];

export const printerType = {
  cover_printer: "Impressora de Capa",
  core_printer: "Impressora de Miolo",
};
