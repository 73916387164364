import * as z from "zod";

export const settingsSchema = z.object({
  switchRequestTimeout: z.coerce
    .number()
    .min(0, {
      message:
        "O tempo limite de resposta do switch deve ser maior ou igual a 0",
    })
    .max(999, {
      message:
        "O tempo limite de resposta deve ser menor ou igual a 999 minutos",
    }),
})

export type SettingsSchema = z.infer<typeof settingsSchema>;
