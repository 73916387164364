import { LoadSettings } from "@/@core/services/types";
import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteLoadSettings implements LoadSettings {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadSettings.Model>
  ) {}

  async load(): Promise<LoadSettings.Model> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
