import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { useDataTable } from "@/components/data-table";
import { PopoverFilter } from "@/components/data-table/popover-filter";
import { Button } from "@/components/ui/button";
import { PublisherFilterSchema } from "../../types";

export const DataTableFilter = () => {
  const { filterForm, onSubmit, filters } =
    useDataTable<PublisherFilterSchema>();

  const handleFilter = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  const handleClearFilter = (key: string) => {
    onSubmit({
      code: key === "code" ? "" : filters.code,
      name: key === "name" ? "" : filters.name,
    });
  };

  function clearFilters() {
    filterForm?.setValue("code", "");
    filterForm?.setValue("name", "");
  }

  useEffect(() => {
    if (!filterForm) return;

    onSubmit({
      name: filters.name ?? "",
      code: filters.code ?? "",
    });
  }, []);

  if (!filterForm) return null;

  return (
    <div className="w-4/5">
      <form
        className="flex space-x-2 items-end"
        onSubmit={filterForm.handleSubmit(handleFilter)}
      >
        <PopoverFilter
          title="Nome"
          placeholder="Pesquisar nome"
          onClear={() => handleClearFilter("name")}
          searchedValue={filters.name}
          onSearch={(name) => filterForm.setValue("name", name)}
        />

        <PopoverFilter
          title="Código"
          onClear={() => handleClearFilter("code")}
          searchedValue={filters.code}
          placeholder="Pesquisar código"
          onSearch={(code) => filterForm.setValue("code", code)}
        />

        {filters.name || filters.code ? (
          <Button
            type="submit"
            variant="ghost"
            className="text-[12px] h-8"
            onClick={() => clearFilters()}
          >
            Limpar filtros <FontAwesomeIcon icon={faXmark} className="ml-2" size="lg"/>
          </Button>
        ) : null}
      </form>
    </div>
  );
};
