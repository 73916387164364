import React from "react";
import { Label } from "../ui/label";
import { FieldError } from "react-hook-form";

export type Props = {
  label?: string;
  error?: FieldError | undefined;
} & React.PropsWithChildren;

export const FieldWrapper = ({ children, label, error }: Props) => {
  if (!label) return <>{children}</>;

  return (
    <div className="flex flex-col gap gap-y-1">
      <Label className="text-dark text-md">{label}</Label>
      {children}
      {error && (
        <span className="text-danger text-md px-1 py-0.5">{error.message}</span>
      )}
    </div>
  );
};
