import * as z from "zod";

import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldInput } from "@/components/form";
import { useResetPassword } from "@/features/auth";
import { Spinner } from "@/components/ui/spinner";
import { useEffect } from "react";

const schema = z
  .object({
    password: z
      .string()
      .min(8, { message: "A senha precisa ter no mínimo 8 caracteres" }),
    passwordConfirm: z.string(),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "As senhas precisam ser iguais",
    path: ["passwordConfirm"],
  });

type ResetPasswordFormSchema = z.infer<typeof schema>;

const getParams = () => {
  const params = new URLSearchParams(window.location.search);

  return {
    email: params.get("email"),
    token: params.get("token"),
  };
};

const message = (isSuccess: boolean, isError: boolean) => {
  if (isSuccess) {
    return {
      message: "Senha redefinida com sucesso!",
      color: "bg-success",
    };
  }

  if (isError) {
    return {
      message: "Redefinição de senha inválida",
      color: "bg-danger",
    };
  }

  return {
    message: null,
    color: null,
  };
};

export const ResetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordFormSchema>({
    resolver: zodResolver(schema),
  });
  const { email, token } = getParams();
  const resetPassword = useResetPassword();

  const onSubmit: SubmitHandler<ResetPasswordFormSchema> = (data) => {
    if (!email || !token) return;

    resetPassword.mutate({
      email,
      token,
      password: data.password,
      passwordConfirmation: data.passwordConfirm,
    });
  };

  useEffect(() => {
    if (resetPassword.isSuccess) {
      reset();
    }
  }, [reset, resetPassword.isSuccess]);

  const feedback = message(resetPassword.isSuccess, resetPassword.isError);

  return (
    <div className="grid gap-6">
      {feedback.message && (
        <p className={`text-base p-2 rounded-sm text-white ${feedback.color}`}>
          {feedback.message}
        </p>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FieldInput
            label="Nova senha"
            error={errors.password}
            placeholder="********"
            type="password"
            {...register("password")}
          />

          <FieldInput
            label="Confirmar senha"
            error={errors.passwordConfirm}
            placeholder="********"
            type="password"
            {...register("passwordConfirm")}
          />

          <Button
            type="submit"
            disabled={resetPassword.isPending || !email || !token}
          >
            Redefinir {resetPassword.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </form>
    </div>
  );
};
