/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { useDataTable } from "@/components/data-table";
import { PopoverFilter } from "@/components/data-table/popover-filter";
import { Button } from "@/components/ui/button";
import { PrinterFilterSchema } from "../../types";
import { FieldSelect } from "@/components/form";
import { printerTypeOptions } from "../../utils/printers-type-options";

export const DataTableFilter = () => {
  const { filterForm, onSubmit, filters } = useDataTable<PrinterFilterSchema>();

  const handleFilter = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  const handleClearFilter = (key: string) => {
    onSubmit({
      code: key === "code" ? "" : filters.code,
      name: key === "name" ? "" : filters.name,
      type: key === "type" ? "" : filters.type,
    });
  };

  function clearFilters() {
    filterForm?.setValue("code", "");
    filterForm?.setValue("name", "");
    filterForm?.setValue("type", "");
  }

  useEffect(() => {
    if (!filterForm) return;

    const type = filterForm.watch("type")
    const listAll = typeof type === "undefined" || type === "all" || type === "undefined"

    onSubmit({
      name: filters.name ?? "",
      code: filters.code ?? "",
      type:listAll ? "" : filterForm.watch("type"),
    });
  }, [filterForm?.watch("type")]);

  if (!filterForm) return null;

  return (
    <div className="w-4/5">
      <form
        className="flex space-x-2 items-end"
        onSubmit={filterForm.handleSubmit(handleFilter)}
      >
        <PopoverFilter
          title="Nome"
          placeholder="Pesquisar nome"
          onClear={() => handleClearFilter("name")}
          searchedValue={filters.name}
          onSearch={(name) => filterForm.setValue("name", name)}
        />

        <PopoverFilter
          title="Código"
          onClear={() => handleClearFilter("code")}
          searchedValue={filters.code}
          placeholder="Pesquisar código"
          onSearch={(code) => filterForm.setValue("code", code)}
        />

        <FieldSelect
          className="w-[200px] h-8"
          iconIsVisible
          name="type"
          registration={filterForm.register("type")}
          control={filterForm.control}
          placeholder="Tipo de impressora"
          options={printerTypeOptions}
        />

        {filters.name || filters.code || filters.type ? (
          <Button
            type="submit"
            variant="ghost"
            className="text-[12px] h-8"
            onClick={() => clearFilters()}
          >
            Limpar filtros <FontAwesomeIcon icon={faXmark} className="ml-2" size="lg"/>
          </Button>
        ) : null}
      </form>
    </div>
  );
};
