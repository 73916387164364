import { DataTableFilter } from "./table/data-table-filter";

export const SwitchRequestsHeader = () => {
  return (
    <div className="space-y-4 w-full mb-4">
      <div className="flex justify-between">
        <DataTableFilter />
      </div>
    </div>
  );
};
