import { NotFoundError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { ResendSwitchRequest } from "../types";

export class RemoteResendSwitchRequest implements ResendSwitchRequest {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async resend(): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
    });

    switch (response.statusCode) {
      case HttpStatusCode.noContent:
        return response.body!;
      case HttpStatusCode.notFound:
        throw new NotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}
