import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadPublishersList } from "@/@core/services/factories/usecases";
import { LoadPublishersList } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

const loadAll = (params: LoadPublishersList.Params) =>
  makeRemoteLoadPublishersList().loadAll(params);

type QueryFnType = typeof loadAll;

type UseLoadPublishersOptions = {
  params: LoadPublishersList.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadPublishersList = ({
  params,
  config,
}: UseLoadPublishersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["publishers"],
    queryFn: () => loadAll(params),
    ...config,
  });
};
