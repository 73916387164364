export const userTypeOptions = [
  {
    label: "Todos",
    value: "all",
  },
  {
    label: "Administrador",
    value: "Administrator",
  },
  {
    label: "Impressor de capas",
    value: "CoverPrinter",
  },
  {
    label: "Impressor de miolo",
    value: "BookPrinter",
  },
  {
    label: "Acabamento",
    value: "Finisher",
  },
  {
    label: "Expedição",
    value: "Expeditor",
  },
];

export const userType = {
  Administrator: "Administrador",
  CoverPrinter: "Impressor de capas",
  BookPrinter: "Impressor de miolo",
  Finisher: "Acabamento",
  Expeditor: "Expedição",
};
