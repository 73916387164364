import { ChevronUp } from "lucide-react";
import { useDataTable } from ".";
import { useCallback } from "react";

type Props = {
  title: string;
  accessor: string;
  icon?: any;
  hasSort?: boolean;
};

export const DataTableOrder = ({
  accessor,
  title,
  icon,
  hasSort = true,
}: Props) => {
  const { order, onSort } = useDataTable();

  const handleSort = useCallback(
    (field: string, direction: "asc" | "desc") => {
      if (!hasSort) return;

      onSort(field, direction);
    },
    [onSort, hasSort]
  );

  return (
    <div
      className="flex items-center cursor-pointer"
      onClick={() =>
        handleSort(accessor, order?.direction === "asc" ? "desc" : "asc")
      }
    >
      {icon ? <div className="me-1.5 mt-0.5">{icon}</div> : null}
      <span className="uppercase text-md mt-[3px] me-1">{title}</span>
      {hasSort ? (
        <>
          {order?.direction === "asc" && order?.field === accessor ? (
            <ChevronUp
              className={`${
                order?.field === accessor ? "opacity-100" : "opacity-20"
              }`}
              size={14}
            />
          ) : (
            <ChevronUp
              className={`${
                order?.field === accessor ? "opacity-100" : "opacity-20"
              } transform rotate-180`}
              size={14}
            />
          )}
        </>
      ) : null}
    </div>
  );
};
