import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { useDataTable } from "@/components/data-table";
import { PopoverFilter } from "@/components/data-table/popover-filter";
import { Button } from "@/components/ui/button";
import { SwitchRequestFilterSchema } from "@/features/switch-request/types";

export const DataTableFilter = () => {
  const { filterForm, onSubmit, filters } =
    useDataTable<SwitchRequestFilterSchema>();

  const handleFilter = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  const handleClearFilter = (key: string) => {
    onSubmit({
      shipment: key === "shipment" ? "" : filters.shipment,
    });
  };

  function clearFilters() {
    filterForm?.setValue("shipment", "");
  }

  useEffect(() => {
    if (!filterForm) return;

    onSubmit({
      shipment: filters.shipment ?? "",
    });
  }, []);

  if (!filterForm) return null;

  return (
    <div className="w-4/5">
      <form
        className="flex space-x-2 items-end"
        onSubmit={filterForm.handleSubmit(handleFilter)}
      >
        <PopoverFilter
          title="Nº Lote"
          placeholder="Pesquisar nº lote"
          onClear={() => handleClearFilter("shipment")}
          searchedValue={filters.shipment ?? ""}
          onSearch={(shipment) => filterForm?.setValue("shipment", shipment)}
        />

        {filters.shipment ? (
          <Button
            type="submit"
            variant="ghost"
            className="text-[12px] h-8"
            onClick={() => clearFilters()}
          >
            Limpar filtro <FontAwesomeIcon icon={faXmark} className="ml-2" size="lg"/>
          </Button>
        ) : null}
      </form>
    </div>
  );
};
