import { useRef } from "react";

import { Button } from "@/components/ui/button";
import { UserFilter } from "./user-filter";

import { UserForm, UserFormRef } from "./user-form";
import { Authorization, ROLES } from "@/lib/authorization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/sharp-solid-svg-icons";

export const UserHeader = () => {
  const userFormRef = useRef<UserFormRef>(null);

  return (
    <div className="space-y-4 w-full mb-4">
      <div className="flex justify-between">
        <UserFilter />
        <div className="flex">
          <Authorization allowedRoles={[ROLES.Administrator]}>
            <Button
              className="flex items-center h-8"
              onClick={() => userFormRef.current?.open()}
            >
              <FontAwesomeIcon icon={faPlus} className="me-1.5" /> Adicionar
              usuário
            </Button>
          </Authorization>
        </div>
      </div>

      <UserForm ref={userFormRef} />
    </div>
  );
};
