import { useForm } from "react-hook-form";
import { Root } from "@/components/data-table";
import { ContentLayout, ForbbidenFallback } from "@/components/layout";
import { Authorization, ROLES } from "@/lib/authorization";
import { SwitchRequestsList } from "../components/switch-requests-list";

export const SwitchRequests = () => {
  const form = useForm();

  return (
    <ContentLayout title="Lista de Requisições">
      <Authorization
        allowedRoles={[ROLES.Administrator]}
        forbbidenFallback={<ForbbidenFallback />}
      >
        <Root filterForm={form}>
          <SwitchRequestsList />
        </Root>
      </Authorization>
    </ContentLayout>
  );
};
