import { ForgotPasswordForm } from "../components/forgot-password-form";
import { Layout } from "../components/layout";

export const ForgotPassword = () => {
  return (
    <Layout>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Esqueceu sua senha?
        </h1>
        <p className="text-base text-muted-foreground max-w-xs">
          Digite seu email e enviaremos instruções para redefinir sua senha
        </p>
      </div>
      <ForgotPasswordForm />

      <a className="text-base text-center" href="/login">
        Voltar para o login
      </a>
    </Layout>
  );
};
