import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { useDisclosure } from "@/hooks";
import { forwardRef, useCallback, useImperativeHandle, useState } from "react";
import { useDeleteUser } from "../api/delete-user";
import { Spinner } from "@/components/ui/spinner";
import { Button } from "@/components/ui/button";

export type DeleteConfirmRef = {
  open: (userId: number) => void;
};

export const DeleteConfirm = forwardRef<DeleteConfirmRef, unknown>((_, ref) => {
  const { open, close, isOpen } = useDisclosure();
  const [userIdSelected, setUserIdSelected] = useState<number | null>(null);

  const deleteUser = useDeleteUser();

  const handleOpen = (userId: number) => {
    open();
    setUserIdSelected(userId);
  };

  const handleConfirm = useCallback(async () => {
    if (userIdSelected) {
      await deleteUser.mutateAsync(userIdSelected);
    }
  }, [deleteUser, userIdSelected]);

  useImperativeHandle(ref, () => ({
    open: (userId) => handleOpen(userId),
  }));

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Deletar usuário</AlertDialogTitle>
          <AlertDialogDescription className="text-md text-gray-700">
            Tem certeza que deseja realizar essa ação?
          </AlertDialogDescription>
        </AlertDialogHeader>

        <div className="flex justify-end items-center space-x-4">
          <Button variant={"ghost"} onClick={close}>
            Cancelar
          </Button>
          <Button onClick={handleConfirm} disabled={deleteUser.isPending}>
            Confirmar {deleteUser.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
});
