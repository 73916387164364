import { LoadPrintersToPrint } from "@/@core/services/types";
import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteLoadPrintersToPrint implements LoadPrintersToPrint {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadPrintersToPrint.Response>
  ) {}

  async load(
    params: LoadPrintersToPrint.Params
  ): Promise<LoadPrintersToPrint.Response> {
    const response = await this.httpClient.request({
      url: this.url,
      params,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
