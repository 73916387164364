import { makeRemoteUpdateSettings } from "@/@core/services/factories/usecases";
import { UpdateSettings } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const update = (params: UpdateSettings.Params) =>
  makeRemoteUpdateSettings().update(params);

export const useUpdateSettings = () => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: () => {
      toast({
        title: "Configurações atualizada!",
        description: "Configurações atualizada com sucesso.",
      });

      queryClient.refetchQueries({
        queryKey: ["settings"],
      });
    },
    mutationFn: update,
  });
};
