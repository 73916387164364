import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { useDisclosure } from "@/hooks";

import * as D from "@/components/ui/dialog";

import { useOrderDownloadAll } from "@/features/order/api/order-download-all";
import { DownloadFileSizeLimitError } from "@/@core/services/errors";
import { useToast } from "@/components/ui/use-toast";

export interface DownloadAllFilesRef {
  open: () => void;
}

type Props = {
  orderId: number;
  shipment: string;
};

export const DownloadAllFiles = forwardRef<DownloadAllFilesRef, Props>(
  (props, ref) => {
    const { toast } = useToast();
    const { isOpen, open, close } = useDisclosure();
    const downloadAll = useOrderDownloadAll();

    const handleConfirm = useCallback(() => {
      downloadAll.mutate({ ...props });
    }, [props.orderId, downloadAll]);

    useEffect(() => {
      if (downloadAll.isSuccess) {
        close();
      }
    }, [downloadAll.isSuccess]);

    useEffect(() => {
      if (downloadAll.error instanceof DownloadFileSizeLimitError) {
        toast({
          title: "Erro ao baixar arquivos",
          description: "O tamanho total dos arquivos excede o limite de 1GB",
          variant: "destructive",
        });
      }
    }, [downloadAll.error]);

    useEffect(() => {
      if (downloadAll.isError) {
        close();
      }
    }, [downloadAll.isError]);

    useImperativeHandle(ref, () => ({ open }), [open]);

    return (
      <>
        <D.Dialog open={isOpen}>
          <D.DialogContent onClose={close}>
            <D.DialogHeader>
              <D.DialogTitle>Baixar todos os arquivos</D.DialogTitle>
            </D.DialogHeader>

            <p className="text-md mb-5">
              Deseja fazer o download de todos os arquivos?
            </p>

            <div className="flex justify-end items-center space-x-4">
              <Button variant={"ghost"} onClick={close}>
                Cancelar
              </Button>
              <Button disabled={downloadAll.isPending} onClick={handleConfirm}>
                Confirmar{" "}
                {downloadAll.isPending && <Spinner className="ml-2" />}
              </Button>
            </div>
          </D.DialogContent>
        </D.Dialog>
      </>
    );
  }
);
