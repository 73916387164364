import { Product } from "@/@core/services/types/products";

export const calculateTotalPages = (products: Product[]): number => {
  return products.reduce(
    (acc, product) => acc + product.pages * product.core_total,
    0
  );
};

export const calculateTotalPagesCoreReprint = (products: Product[]): number => {
  return products.reduce(
    (acc, product) => acc + product.pages * product.core_total,
    0
  );
};

export const calculateTotalPagesCoverReprint = (
  products: Product[]
): number => {
  return products.reduce((acc, product) => acc + product.cover_total, 0);
};

export const calculateTotalQuantity = (products: Product[]): number => {
  return products.reduce((acc, product) => acc + product.core_total, 0);
};

export const calculateTotalQuantityCoreReprint = (
  products: Product[]
): number => {
  return products.reduce((acc, product) => acc + product.core_total, 0);
};

export const calculateTotalQuantityCoverReprint = (
  products: Product[]
): number => {
  return products.reduce((acc, product) => acc + product.cover_total, 0);
};
