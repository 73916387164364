import * as Sentry from "@sentry/react"
import { AppProvider } from "./providers/app";
import { AppRoutes } from "./routes";

Sentry.init({
  dsn: "https://937c73750df063bd4594d7f4758a51c2@o4506678540632064.ingest.sentry.io/4506678546268160",
  integrations: [
    new Sentry.BrowserTracing(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export default function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}
