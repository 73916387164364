import logo from "@/assets/imgs/logo-print-park.svg";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="container relative h-screen flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white dark:border-r lg:flex">
        <div className="absolute inset-0 bg-blue-dark" />
        <div className="relative z-10">
          <img src={logo} className="h-12 me-auto" alt="logo empresa" />
        </div>
        <div className="relative z-20 mt-auto">
          <p className="text-lg">
            Empresa com quase duas décadas de sólido crescimento e cada vez mais
            se especializando na impressão, manuseio e envio de livros e
            apostilas, sempre tratando e trazendo maior agilidade e
            assertividade para o sucesso da operação.
          </p>
        </div>
      </div>

      <div className="mx-auto flex w-full flex-col justify-center space-y-6 max-w-[350px]">
        {children}
      </div>
    </div>
  );
};
