import { useEffect, useMemo } from "react";
import { DataTableLoading } from "@/components/data-table";
import { useLoadUserList } from "../api";
import { useDataTable } from "@/components/data-table/data-table-root";
import { UserFilterSchema } from "../types";
import { UserHeader } from "./user-header";
import { DataTable } from "./table";

export const UsersList = () => {
  const { page, perPage, order, filters } = useDataTable<UserFilterSchema>();
  const usersList = useLoadUserList({
    params: {
      page,
      per_page: perPage,
      sort_direction: order?.direction,
      sort_by: order?.field,
      name: filters?.name ? filters.name : undefined,
      email: filters?.email ? filters.email : undefined,
      type: filters?.type && filters?.type !== "undefined" ? filters.type : undefined,
    },
  });

  const data = useMemo(() => {
    return usersList.data ? usersList.data.data : [];
  }, [usersList.data]);

  useEffect(() => {
    usersList.refetch();
  }, [page, perPage, order, filters]);

  const isLoading = usersList.isPending || usersList.isFetching;

  return (
    <div className="space-y-3">
      <UserHeader />
      {isLoading ? (
        <DataTableLoading />
      ) : (
        <DataTable meta={usersList.data?.meta} users={data} />
      )}
    </div>
  );
};
