import { forwardRef, useImperativeHandle } from "react";

import { useDisclosure } from "@/hooks";

import * as A from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

export type HasNotSameStatusRef = {
  open: () => void;
};

export const HasNotSameStatus = forwardRef<HasNotSameStatusRef, unknown>(
  (_, ref) => {
    const { close, isOpen, open } = useDisclosure();

    useImperativeHandle(ref, () => ({ open }), [open]);

    return (
      <A.AlertDialog open={isOpen}>
        <A.AlertDialogContent>
          <A.AlertDialogHeader>
            <A.AlertDialogTitle>Ação não permitida!</A.AlertDialogTitle>
            <A.AlertDialogDescription className="text-md text-gray-700">
              Não é possível realizar uma ação em pedidos que têm status
              diferente.
            </A.AlertDialogDescription>
          </A.AlertDialogHeader>

          <div className="flex justify-end items-center space-x-4">
            <Button onClick={close}>Fechar</Button>
          </div>
        </A.AlertDialogContent>
      </A.AlertDialog>
    );
  }
);
