import { useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";
import * as F from "@fortawesome/sharp-solid-svg-icons";

import { LoadUserList } from "@/@core/services/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";

import { DeleteConfirm, DeleteConfirmRef } from "../components/delete-confirm";
import { DataTableOrder } from "@/components/data-table";
import { userType } from "@/utils/user-type-options";
import { useUserStore } from "@/stores/user";
import { UserForm, UserFormRef } from "../components/user-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const useColumns = () => {
  const { user } = useUserStore();
  const deleteConfirmRef = useRef<DeleteConfirmRef>(null);
  const userFormRef = useRef<UserFormRef>(null);

  const columns: ColumnDef<LoadUserList.Model>[] = [
    {
      accessorKey: "name",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={F.faInputText} />}
          accessor="name"
          title="Nome"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
    },
    {
      accessorKey: "email",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={F.faEnvelope} />}
          accessor="email"
          title="Email"
        />
      ),
      cell: ({ row }) => <>{row.getValue("email")}</>,
    },
    {
      accessorKey: "type",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={F.faUserCog} />}
          accessor="type"
          title="Tipo do usuario"
        />
      ),
      cell: ({ row }) => (
        <>{userType[row.getValue("type") as keyof typeof userType]}</>
      ),
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <FontAwesomeIcon icon={F.faEllipsis} size="lg" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {user?.id !== row.original.id ? (
                  <DropdownMenuItem
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                      deleteConfirmRef.current?.open(row.original.id)
                    }
                  >
                    <FontAwesomeIcon icon={F.faTrashXmark} size="1x" />{" "}
                    <span className="ms-2">Remover usuário</span>
                  </DropdownMenuItem>
                ) : null}
                <DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() => userFormRef.current?.open(row.original)}
                >
                  <FontAwesomeIcon icon={F.faPenToSquare} size="1x" />{" "}
                  <span className="ms-2">Editar usuário</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <UserForm ref={userFormRef} type="update" />
            <DeleteConfirm ref={deleteConfirmRef} />
          </>
        );
      },
    },
  ];

  return { columns };
};
