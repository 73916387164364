import { useCallback } from "react";

import { useToast } from "@/components/ui/use-toast";
// import { useDataTable } from "@/components/data-table";

// import { queryClient } from "@/lib/react-query";
// import { useSocket } from "@/lib/socket";

// import { LoadOrdersList } from "@/@core/services/types";

// import { OrdersFilterSchema } from "../types";
// import { OrdersSelectedContext } from "../contexts";

// type Payload = LoadOrdersList.Model;

export const useOrderCreatedSocket = () => {
  // const { rows } = useContext(OrdersSelectedContext);
  const { toast } = useToast();
  // const { filters, page, perPage } = useDataTable();

  const renderNewOrderToast = useCallback(
    (isReprint: boolean) => {
      if (isReprint) {
        toast({
          title: "Novo pedido de refaz recebido!",
          description:
            "Você tem um novo pedido do tipo refaz para ser atendido.",
        });
      } else {
        toast({
          title: "Novo pedido recebido!",
          description: "Você tem um novo pedido para ser atendido.",
        });
      }
    },
    [toast]
  );

  // const updateOrdersList = useCallback(
  //   (payload: Payload) => {
  //     const filtersTyped = filters as OrdersFilterSchema;

  //     const previousData = queryClient.getQueryData<LoadOrdersList.Response>([
  //       "orders",
  //     ]);

  //     const previousList = previousData?.data || [];

  //     const hasAlreadAddedOnList = previousList.some(
  //       (order) => order.id === payload.id
  //     );

  //     if (hasAlreadAddedOnList) {
  //       return;
  //     }

  //     if (filtersTyped.orderStatus) {
  //       const currentFilters = filtersTyped.orderStatus.split(",");

  //       if (currentFilters.includes(payload.status[0])) {
  //         const removedItem = previousList.splice(-1);
  //         const filteredList = previousList.filter(
  //           (list) => list.id !== removedItem[0].id
  //         );

  //         queryClient.setQueryData(["orders"], {
  //           ...previousData,
  //           data: [payload, ...filteredList],
  //         });
  //       }

  //       return;
  //     }

  //     if (previousList.length < perPage) {
  //       queryClient.setQueryData(["orders"], {
  //         ...previousData,
  //         data: [payload, ...previousList],
  //       });

  //       return;
  //     }

  //     const removedItem = previousList.splice(-1);
  //     const filteredList = previousList.filter(
  //       (list) => list.id !== removedItem[0].id
  //     );

  //     queryClient.setQueryData(["orders"], {
  //       ...previousData,
  //       data: [payload, ...filteredList],
  //     });
  //   },
  //   [filters, renderNewOrderToast, perPage]
  // );

  return { renderNewOrderToast }
};
