import { OrderHistory, OrderStatus } from "@/@core/services/types";
import { TimelineData } from "@/components/timeline";
import { useCallback } from "react";

const getTitleFromChangeStatusAction = (status: OrderStatus) => {
  switch (status) {
    case "waiting_print":
      return `Status alterado para "Espera impressão"`;
    case "core_printing":
      return `Status alterado para "Imprimindo miolo"`;
    case "cover_printing":
      return `Status alterado para "Imprimindo capa"`;
    case "finishing":
      return `Status alterado para "Acabamento"`;
    case "shipping":
      return `Status alterado para "Expedição"`;
    case "canceled":
      return `Status alterado para "Cancelado"`;
    case "finished":
      return `Status alterado para "Finalizado"`;
    default:
      return "";
  }
};

export const useOrderHistory = () => {
  const build = useCallback((item: OrderHistory) => {
    console.log(item);
    if (item.status === "received") {
      return [
        {
          startDate: item.created_at,
          title: "Pedido recebido",
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.action === "change_status") {
      return [
        {
          startDate: item.created_at,
          title: getTitleFromChangeStatusAction(item.status),
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "waiting_print") {
      return [
        {
          startDate: item.created_at,
          title: `Status alterado para "Espera impressão"`,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (
      item.action === "start_print_cover" &&
      item.status === "cover_print_requested"
    ) {
      return [
        {
          title: `${item.user.name} solicitou impressão da capa`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
        {
          title: `Status alterado para "Impressão de capa solicitada"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "cover_printing") {
      return [
        {
          title: `Status alterado para "Imprimindo capa"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (
      item.action === "end_print_cover" &&
      item.status === "cover_print_finished"
    ) {
      return [
        {
          title: `${item.user.name} finalizou a impressão da capa`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
        {
          title: `Status alterado para "Impressão de capa finalizada"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (
      item.action === "start_print_core" &&
      item.status === "core_print_requested"
    ) {
      return [
        {
          title: `${item.user.name} solicitou impressão do miolo`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
        {
          title: `Status alterado para "Impressão de miolo solicitada"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "core_printing") {
      return [
        {
          title: `Status alterado para "Imprimindo miolo"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (
      item.action === "end_print_core" &&
      item.status === "core_print_finished"
    ) {
      return [
        {
          title: `${item.user.name} finalizou a impressão de miolo`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
        {
          title: `Status alterado para "Impressão de miolo finalizada"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "finishing" && item.action === "start_finishing") {
      return [
        {
          title: `Status alterado para "Acabamento"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "finishing" && item.action === "end_finishing") {
      return [
        {
          title: `${item.user.name} finalizou o acabamento`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "failed") {
      return [
        {
          title: "Pedido falhou",
          startDate: item.created_at,
          type: "error" as TimelineData["type"],
          description: item.message,
        },
      ];
    }

    if (item.status === "shipping" && item.action === "start_dispatch") {
      return [
        {
          title: `Status alterado para "Expedição"`,
          startDate: item.created_at,
          type: "status" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "shipping" && item.action === "end_dispatch") {
      return [
        {
          title: `${item.user.name} finalizou a expedição`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
      ];
    }

    if (item.action === "cancel") {
      return [
        {
          title: `${item.user.name} cancelou o pedido`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
      ];
    }

    if (item.status === "finished" && item.action === "finish") {
      return [
        {
          title: `${item.user.name} finalizou o pedido`,
          startDate: item.created_at,
          type: "action" as TimelineData["type"],
        },
      ];
    }

    return [];
  }, []);

  return { build };
};
