import { useCallback } from "react";
import { useUserStore } from "@/stores";
import { useOrderReprintStore } from "../stores";
import { LoadOrder } from "@/@core/services/types";

export const useReprintAll = () => {
  const { addProduct, clearProducts, setAllProductsSelected } =
    useOrderReprintStore();
  const { user } = useUserStore();

  const handleReprintAll = useCallback(
    (isChecked: boolean, orderLoaded: LoadOrder.Model | undefined) => {
      if (!orderLoaded || !user) return;

      if (isChecked) {
        setAllProductsSelected(true);

        orderLoaded.products.map((product) => {
          if (user.type === "Administrator" || user.type === "BookPrinter") {
            addProduct({
              productId: product.id,
              quantity: 1,
              type: "core",
            });
          }

          if (user.type === "CoverPrinter" || user.type === "Administrator") {
            addProduct({
              productId: product.id,
              quantity: 1,
              type: "cover",
            });
          }
        });
      } else {
        setAllProductsSelected(false);
        clearProducts();
      }
    },
    [user, addProduct, clearProducts, setAllProductsSelected]
  );

  return { handleReprintAll };
};
