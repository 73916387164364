import { Controller, FieldError, UseFormRegisterReturn } from "react-hook-form";

import { FieldWrapper } from ".";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { SelectProps } from "@radix-ui/react-select";

type Options = {
  label: string;
  value: string;
};

type Props = {
  label?: string;
  error?: FieldError | undefined;
  placeholder?: string;
  options: Options[];
  control: any;
  className?: string;
  classNameContent?: string;
  iconIsVisible?: boolean;
  registration: Partial<UseFormRegisterReturn>;
} & SelectProps;

export const FieldSelect = ({
  label,
  error,
  control,
  options,
  className,
  iconIsVisible = false,
  classNameContent = "",
  placeholder = "Selecione uma opção",
  ...props
}: Props) => {
  return (
    <FieldWrapper error={error} label={label}>
      <Controller
        control={control}
        name={props.name!}
        render={({ field }) => (
          <Select {...props} onValueChange={field.onChange} value={field.value}>
            <SelectTrigger
              className={`${className} text-md`}
              iconIsVisible={iconIsVisible}
            >
              <SelectValue
                className="font-semibold"
                placeholder={placeholder}
              />
            </SelectTrigger>
            <SelectContent className={classNameContent}>
              <SelectGroup>
                {options.map((option: Options) => (
                  <SelectItem
                    className="text-md"
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
      />
    </FieldWrapper>
  );
};
