import { useContext } from "react";
import { OrdersSelectedContext } from "@/features/order/contexts";
import { CoverFinishContent } from "./cover-finish-content";

import { PrintersList } from "../printers-list";
import { CannotSelectedPrintingDifferent } from "../cannot-select-printing-different";

type Props = {
  onClose: () => void;
};

export const CoverPrinter = (props: Props) => {
  const { rows } = useContext(OrdersSelectedContext);

  const canStartPrinting = rows.every(
    (row) => !row.status.includes("cover_printing")
  );

  console.log(canStartPrinting);

  if (canStartPrinting) {
    return <PrintersList type="cover" {...props} />;
  }

  const canFinishPrinting = rows.every((row) =>
    row.status.includes("cover_printing")
  );

  if (canFinishPrinting) {
    return <CoverFinishContent onClose={props.onClose} />;
  }

  return <CannotSelectedPrintingDifferent onClose={props.onClose} />;
};
