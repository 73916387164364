import { makeRemoteLoadMe } from "@/@core/services/factories/usecases";
import { User } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { useQuery } from "@tanstack/react-query";

const me = (): Promise<User> => makeRemoteLoadMe().me();

type QueryFnType = typeof me;

type useLoadUserListOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useLoadUser = ({ config }: useLoadUserListOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["me"],
    queryFn: () => me(),
    refetchOnWindowFocus: true,
    refetchInterval: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnReconnect: true,
    ...config,
  });
};
