import { OrderStatus } from "@/@core/services/types";
import { Badge } from "@/components/ui/badge";
import { OrderVariantStatus } from "../../types";
import { statusType } from "../../utils/filter-options";

type Props = {
  status: OrderStatus;
  variant: OrderVariantStatus
}

export const OrderStatusBadgeDefault = (props: Props) => {
  return (
    <Badge
      variant={props.variant}
      className="capitalize text-[11px] font-semibold w-fit"
    >
      {statusType[props.status]}
    </Badge>
  )
}
