import { FieldInput } from "@/components/form";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

import { zodResolver } from "@hookform/resolvers/zod";

import { useLoadSettings, useUpdateSettings } from "../api";
import { SettingsSchema, settingsSchema } from "../types";

export const SettingsForm = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SettingsSchema>({
    resolver: zodResolver(settingsSchema),
  });

  const settings = useLoadSettings();
  const updateSettings = useUpdateSettings();

  const onSubmit = (data: SettingsSchema) => {
    if (data.switchRequestTimeout === 0) {
      setValue("switchRequestTimeout", 0);
    }

    updateSettings.mutate({
      switch_request_timeout: data.switchRequestTimeout * 60,
    });
  };

  useEffect(() => {
    if (settings.isSuccess) {
      const minutes = settings.data.switch_request_timeout_seconds / 60;

      setValue("switchRequestTimeout", minutes);
    }
  }, [settings.isSuccess]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="h-[calc(100vh-120px)] flex justify-between flex-col"
    >
      {settings.isPending && !settings.data && (
        <div className="flex items-center justify-center my-auto">
          <Spinner size="md-lg" />
        </div>
      )}

      {settings.data && !settings.isPending && (
        <>
          <FieldInput
            label="Tempo limite de resposta do switch (em minutos)"
            type="number"
            className="max-w-xs"
            error={errors.switchRequestTimeout}
            placeholder="Digite o tempo limite de resposta do switch"
            {...register("switchRequestTimeout")}
          />

          <Button
            className="ml-auto w-[180px]"
            type="submit"
            disabled={updateSettings.isPending}
          >
            Salvar{" "}
            {updateSettings.isPending && <Spinner size="sm" className="ml-2" />}
          </Button>
        </>
      )}
    </form>
  );
};
