import { useEffect, useMemo } from "react";

import { useDataTable } from "@/components/data-table";
import { SwitchRequestFilterSchema } from "../types";
import { useLoadSwitchRequestsList } from "../api/load-switch-requests-list";
import { DataTable } from "./table/data-table";
import { SwitchRequestsHeader } from "./switch-requests-header";

export const SwitchRequestsList = () => {
  const { page, perPage, order, filters } =
    useDataTable<SwitchRequestFilterSchema>();

  const switchRequest = useLoadSwitchRequestsList({
    params: {
      page,
      per_page: perPage,
      sort_direction: order?.direction,
      sort_by: order?.field,
      shipment: filters?.shipment ? filters.shipment : undefined,
    },
  });

  const data = useMemo(() => {
    return switchRequest.data ? switchRequest.data.data : [];
  }, [switchRequest.data]);

  useEffect(() => {
    switchRequest.refetch();
  }, [page, perPage, order, filters]);

  const isLoading = switchRequest.isPending || switchRequest.isFetching;

  return (
    <div className="space-y-3">
      <SwitchRequestsHeader />
      <DataTable
        isLoading={isLoading}
        switchRequests={data}
        meta={switchRequest.data?.meta}
      />
    </div>
  );
};
