import { Input, InputProps } from "../ui/input";
import { FieldWrapper } from ".";
import { FieldError } from "react-hook-form";
import { forwardRef } from "react";

type Props = {
  label?: string;
  error?: FieldError | undefined;
} & InputProps;

export const FieldInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, ...props }, ref) => {
    return (
      <FieldWrapper error={error} label={label}>
        <Input {...props} ref={ref} />
      </FieldWrapper>
    );
  },
);
