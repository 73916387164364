import { formatInTimeZone } from "date-fns-tz";

export const formatDate = (date: string): string => {
  const formatDate = new Date(date);
  return formatInTimeZone(formatDate, "America/Sao_Paulo", "dd/MM/yyyy HH:mm");
};

export const formatDateToISO = (date: string | undefined): string => {
  if (!date) return "";
  const formatDate = new Date(date);
  return formatInTimeZone(formatDate, "America/Sao_Paulo", "yyyy-MM-dd HH:mm");
};
