import { useCallback, useContext, useEffect, useState } from "react";

import { BookPrinter } from "../book";
import { CoverPrinter } from "../cover";
import { RenderStatusTypes } from "@/features/order/hooks/use-order-action";
import {
  OrdersActionContext,
  OrdersSelectedContext,
} from "@/features/order/contexts";
import { PrinterWithoutActionAvaiable } from "./printer-without-action-avaiable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/sharp-solid-svg-icons";

type Props = {
  onClose: () => void;
  status: RenderStatusTypes;
};

type PrinterStepType = "book" | "cover" | "none" | "printer-action-unavaiable";

export const BookCoverPrinterAdmin = (props: Props) => {
  const { onChangeCurrentAction } = useContext(OrdersActionContext);
  const { rows } = useContext(OrdersSelectedContext);

  const [typeVisible, setTypeVisible] = useState<PrinterStepType>("none");

  useEffect(() => {
    onChangeCurrentAction("printer_admin");

    return () => {
      onChangeCurrentAction("none");
    };
  }, []);

  const checkOrdersHasActionAvaiable = useCallback(
    (type: "book" | "cover") => {
      if (type === "book") {
        const hasStatusUnavaiable = rows.some(
          (row) =>
            row.status.includes("core_print_finished") ||
            row.status.includes("core_print_requested")
        );

        if (hasStatusUnavaiable) {
          setTypeVisible("printer-action-unavaiable");
          return;
        }

        setTypeVisible("book");
      }

      if (type === "cover") {
        const hasStatusUnavaiable = rows.some(
          (row) =>
            row.status.includes("cover_print_finished") ||
            row.status.includes("cover_print_requested")
        );

        if (hasStatusUnavaiable) {
          setTypeVisible("printer-action-unavaiable");
          return;
        }

        setTypeVisible("cover");
      }
    },
    [rows]
  );

  const handleTypeVisible = useCallback(
    (type: "book" | "cover") => {
      if (type === "book") {
        checkOrdersHasActionAvaiable("book");
      }

      if (type === "cover") {
        checkOrdersHasActionAvaiable("cover");
      }
    },
    [checkOrdersHasActionAvaiable]
  );

  return (
    <>
      {typeVisible === "none" && (
        <div className="flex flex-col gap-y-2">
          <div
            className="flex justify-between cursor-pointer hover:bg-zinc-100 p-1.5 rounded-md"
            onClick={() => handleTypeVisible("cover")}
          >
            <small>Impressor de Capa</small>
            <FontAwesomeIcon icon={faChevronRight} size="sm" className="mt-0.5" />
          </div>

          <div
            className="flex justify-between cursor-pointer hover:bg-zinc-100 p-1.5 rounded-md"
            onClick={() => handleTypeVisible("book")}
          >
            <small>Impressor de Miolo</small>
            <FontAwesomeIcon icon={faChevronRight} size="sm" className="mt-0.5" />
          </div>
        </div>
      )}

      {typeVisible === "book" && (
        <BookPrinter {...props} onClose={() => setTypeVisible("none")} />
      )}

      {typeVisible === "cover" && (
        <CoverPrinter {...props} onClose={() => setTypeVisible("none")} />
      )}

      {typeVisible === "printer-action-unavaiable" && (
        <PrinterWithoutActionAvaiable onClose={props.onClose} />
      )}
    </>
  );
};
