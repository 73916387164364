import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";
import { useDisclosure } from "@/hooks";

import * as D from "@/components/ui/dialog";

import { useOrderTakeAction } from "@/features/order/api";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";

export interface CancelOrderRef {
  open: () => void;
}

type Props = {
  orderId: number;
};

export const CancelOrder = forwardRef<CancelOrderRef, Props>((props, ref) => {
  const { toast } = useToast();
  const { isOpen, open, close } = useDisclosure();
  const takeAction = useOrderTakeAction();

  const handleConfirm = useCallback(() => {
    takeAction.mutate({
      orders: [
        {
          action: "cancel",
          id: props.orderId,
        },
      ],
    });
  }, [props.orderId, takeAction]);

  useEffect(() => {
    if (takeAction.isSuccess) {
      toast({
        title: "Pedido cancelado!",
        description: "O pedido foi cancelado com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["order"],
      });

      close();
    }
  }, [takeAction.isSuccess]);

  useEffect(() => {
    if (takeAction.isError) {
      close();
    }
  }, [takeAction.isError]);

  useImperativeHandle(ref, () => ({ open }), [open]);

  return (
    <>
      <D.Dialog open={isOpen}>
        <D.DialogContent onClose={close}>
          <D.DialogHeader>
            <D.DialogTitle>Cancelar pedido</D.DialogTitle>
          </D.DialogHeader>

          <p className="text-md mb-5">
            Deseja cancelar o pedido? Essa ação não poderá ser desfeita.
          </p>

          <div className="flex justify-end items-center space-x-4">
            <Button variant={"ghost"} onClick={close}>
              Fechar
            </Button>
            <Button disabled={takeAction.isPending} onClick={handleConfirm}>
              Confirmar {takeAction.isPending && <Spinner className="ml-2" />}
            </Button>
          </div>
        </D.DialogContent>
      </D.Dialog>
    </>
  );
});
