import { makeRemoteUpdatePrinter } from "@/@core/services/factories/usecases";
import { UpdatePrinter } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const update = (params: UpdatePrinter.Params) =>
  makeRemoteUpdatePrinter(params.id).update(params);

export const useUpdatePrinter = () => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: () => {
      toast({
        title: "Impressora atualizada!",
        description: "Impressora atualizada com sucesso.",
      });

      queryClient.refetchQueries({
        queryKey: ["printers"],
      });
    },
    mutationFn: update,
  });
};
