import { useEffect } from "react";
import Echo from "laravel-echo";
import Pusher from "pusher-js";

declare global {
  interface Window {
    pusher: Pusher;
    Echo: Echo;
  }
}

//@ts-ignore
window.pusher = Pusher;

export function createSocketConnection() {
  if (!window.Echo) {
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: import.meta.env.VITE_PUSHER_KEY,
      cluster: import.meta.env.VITE_PUSHER_CLUSTER,
      forceTLS: true,
      encrypted: true,
      disableStats: true,
    });
  }
}

function listen(
  callBack: (payload: any) => void,
  channel: string,
  event: string
) {
  window.Echo.channel(channel).listen(event, (payload: any) => {
    callBack(payload);
  });

  return function cleanUp() {
    window.Echo.leaveChannel(channel);
  };
}

type Options = {
  type: "order-status-updated" | "orders";
  callBack: (payload: any) => void;
};

export const useSocket = ({ type, callBack }: Options) => {
  useEffect(() => {
    createSocketConnection();

    switch (type) {
      case "order-status-updated": {
        return listen(
          callBack,
          "order-status-updated",
          ".order-status-updated"
        );
      }
      case "orders": {
        return listen(callBack, "orders", ".order-created");
      }
    }
  }, [callBack, type]);
};
