import { makeRemoteReprintOrder } from "@/@core/services/factories/usecases";
import { ReprintOrder } from "@/@core/services/types";
import { useMutation } from "@tanstack/react-query";

const reprint = (params: ReprintOrder.Params) =>
  makeRemoteReprintOrder(params.orderId).reprint(params);

export const useReprintOrder = () => {
  return useMutation({
    mutationFn: reprint,
  });
};
