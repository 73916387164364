import React, { useEffect } from "react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useDownloadFile } from "@/hooks/use-download-file";
import { Spinner } from "@/components/ui/spinner";
import { useToast } from "@/components/ui/use-toast";

type Props = {
  icon: React.ReactNode;
  url: string;
  outputName: string;
  tooltipName: string;
};

export const ProductDownload = ({
  icon,
  url,
  outputName,
  tooltipName,
}: Props) => {
  const { toast } = useToast();

  const download = useDownloadFile({
    outputName,
    url,
  });

  useEffect(() => {
    if (download.isError) {
      toast({
        title: "Error ao baixar o arquivo!",
        description: "Não foi possivel fazer o download do arquivo.",
        variant: "destructive",
        duration: 3000,
      });
    }
  }, [download.isError]);

  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger>
          <div onClick={() => download.mutate()}>
            {download.isPending ? (
              <Spinner size="sm" className="mx-0.5" />
            ) : (
              <>{icon}</>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent side="left" sideOffset={12} className="font-medium">
          {tooltipName}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
