import { useRef } from "react";

import * as D from "@/components/ui/dialog";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { Checkbox } from "@/components/ui/checkbox";

import { ConfirmReprint, ConfirmReprintRef } from "./confirm-reprint";
import { Products } from "./products";
import { Footer } from "./footer";
import { useOrderReprintStore } from "../../../stores";
import { useLoadOrder } from "../../../api";
import { useReprintAll } from "../../../hooks/use-reprint-all";

type Props = {
  onClose: () => void;
};

export const OrderReprint = ({ onClose }: Props) => {
  const { order, products, clearProducts, setAllProductsSelected } =
    useOrderReprintStore();
  const { handleReprintAll } = useReprintAll();

  const confirmReprintRef = useRef<ConfirmReprintRef>(null);
  const markAllRef = useRef<HTMLButtonElement>(null);

  const { data: orderLoaded, isFetching } = useLoadOrder({
    //@ts-ignore
    orderId: order?.id,
    config: {
      enabled: !!order,
      staleTime: 0,
    },
  });

  if (!order) {
    return null;
  }

  const hasProducts = !!products.length;

  return (
    <>
      <D.DialogHeader className="pe-6">
        <D.DialogTitle className="flex justify-between">
          <span>Refazer Itens do Pedido - {order.id}</span>
          <div className="flex items-center">
            <label
              className="text-[12px] font-medium me-2 pb-1 pt-1.5"
              htmlFor="mark-all"
            >
              Marcar todos
            </label>
            <Checkbox
              ref={markAllRef}
              id="mark-all"
              onCheckedChange={(value) =>
                handleReprintAll(!!value, orderLoaded)
              }
            />
          </div>
        </D.DialogTitle>
      </D.DialogHeader>

      <ScrollArea>
        <Products
          isLoading={isFetching}
          products={orderLoaded ? orderLoaded.products : []}
        />
        <ScrollBar />
      </ScrollArea>

      {hasProducts && (
        <Footer
          isLoading={false}
          onReprint={() => confirmReprintRef.current?.open()}
          quantity={products.length}
        />
      )}

      <ConfirmReprint
        onSuccess={() => {
          clearProducts();
          setAllProductsSelected(false);

          if (markAllRef.current?.ariaChecked === "true") {
            markAllRef.current?.click();
          }

          onClose();
        }}
        ref={confirmReprintRef}
        orderId={order.id}
      />
    </>
  );
};
