import React, { PropsWithChildren } from "react";
import { formatDate } from "@/utils/format-date";
import { cva } from "class-variance-authority";
import { cn } from "@/lib/cn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faRobot } from "@fortawesome/sharp-solid-svg-icons";

export type TimelineData = {
  title: string;
  startDate: string;
  type: "action" | "status" | "error";
  description?: string | null;
};

type Props = {
  timeline: TimelineData[][];
} & PropsWithChildren;

const timelineVariants = cva(
  "absolute bg-gray-200 rounded-full mt-[2.5px] -start-[12px] border border-white flex items-center justify-center",
  {
    variants: {
      variant: {
        action: "w-[24px] h-[24px] mt-[2.5px] -start-[13px]",
        error: "w-[24px] h-[24px] mt-[2.5px] bg-red-500",
        status: "w-[24px] h-[24px] mt-[2.5px] -start-[13px]",
      },
    },
  }
);

export const TimeLine = ({ timeline, children }: Props) => {
  return (
    <ol className="relative border-s border-gray-200 dark:border-gray-700">
      {timeline.map((historyItem, idx) => (
        <React.Fragment key={idx}>
          {historyItem.map((item) => (
            <li className="mb-8 ms-4" key={item.type}>
              <div className={cn(timelineVariants({ variant: item.type }))}>
                <FontAwesomeIcon
                  size="2xs"
                  color={item.type === "error" ? "white" : "auto"}
                  icon={item.type === "status" ? faUser : faRobot}
                />
              </div>
              <time className="mb-2 text-sm font-normal text-gray-400">
                {formatDate(item?.startDate)}
              </time>

              <h4 className="text-md mb-1 font-medium text-dark">
                {item?.title}
              </h4>

              <small className="text-sm">{item.description}</small>
              {children}
            </li>
          ))}
        </React.Fragment>
      ))}
    </ol>
  );
};
