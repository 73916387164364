import { Layout } from "../components/layout";
import { ResetPasswordForm } from "../components/reset-password-form";

export const ResetPassword = () => {
  return (
    <Layout>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">
          Redefinir senha
        </h1>
        <p className="text-base text-muted-foreground">
          A nova senha precisa ser diferente das senhas anteriores
        </p>
      </div>
      <ResetPasswordForm />

      <a className="text-base text-center" href="/login">
        Voltar para o login
      </a>
    </Layout>
  );
};
