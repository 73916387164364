import { TimeLine } from "@/components/timeline";
import { useMemo } from "react";
import { Order, OrderHistory } from "@/@core/services/types";
import { useOrderHistory } from "../../../hooks/use-order-history";

type Props = {
  history: OrderHistory[];
  order: Order;
};

export const History = (props: Props) => {
  const { build } = useOrderHistory();

  const historyData = useMemo(() => {
    return props.history.map((item: OrderHistory) => build(item));
  }, [build, props.history]);

  return (
    <div className="ps-1">
      <TimeLine timeline={historyData} />
    </div>
  );
};
