import { makeRemoteUpdatePublisher } from "@/@core/services/factories/usecases";
import { UpdatePublisher } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const update = (params: UpdatePublisher.Params) =>
  makeRemoteUpdatePublisher(params.id).update(params);

export const useUpdatePublisher = () => {
  const { toast } = useToast();

  return useMutation({
    onSuccess: () => {
      toast({
        title: "Editora atualizada!",
        description: "Editora atualizada com sucesso.",
      });

      queryClient.refetchQueries({
        queryKey: ["publishers"],
      });
    },
    mutationFn: update,
  });
};
