import { Suspense, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";

import { MainLayout } from "@/components/layout";
import { FallbackSuspense } from "@/components/layout";
import { Toaster } from "@/components/ui/toaster";
import { ErrorBoundaryFallback } from "@/features/misc";
import { useAuth } from "@/hooks";
import { AuthProvider } from "@/providers/auth";

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <MainLayout>
        <Suspense fallback={<FallbackSuspense />}>
          <Toaster />
          <AuthProvider>
            <Outlet />
          </AuthProvider>
        </Suspense>
      </MainLayout>
    </ErrorBoundary>
  );
};
