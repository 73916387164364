import { Button } from "@/components/ui/button";

export const ErrorBoundaryFallback = () => {
  const handleBack = () => {
    window.location.href = window.location.origin;
  }

  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <div className="">
        <h2 className="font-semibold text-[40px]">Ops, ocorreu um erro</h2>
        <p className="text-muted-foreground mb-5">
          Aconteceu um erro inesperado enquanto você estava usando a aplicação!
        </p>
        <Button onClick={handleBack}>Voltar para o inicio</Button>
      </div>
    </div>
  );
};
