import { FieldInput, FieldWrapper } from "@/components/form";
import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { UserSchema } from ".";
import { userTypeOptions } from "@/utils/user-type-options";
import { Button } from "@/components/ui/button";
import { useCreateUser } from "../../api";
import { useEffect } from "react";
import { BadRequestError } from "@/@core/services/errors";
import { UserType } from "@/@core/services/types";
import { Spinner } from "@/components/ui/spinner";
import { faAngleDown } from "@fortawesome/sharp-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  errors: FieldErrors<UserSchema>;
  register: UseFormRegister<UserSchema>;
  control: Control<UserSchema>;
  options: typeof userTypeOptions;
  onClear: () => void;
  onClose: () => void;
  setError: UseFormSetError<UserSchema>;
  onSubmit: UseFormHandleSubmit<UserSchema>;
};

export const CreateForm = ({
  errors,
  register,
  control,
  options,
  setError,
  onClear,
  onClose,
  onSubmit,
}: Props) => {
  const createUser = useCreateUser();

  const handleSubmit: SubmitHandler<UserSchema> = (data) => {
    createUser.mutate({
      email: data.email,
      name: data.name,
      type: data.type as UserType,
    });
  };

  useEffect(() => {
    if (createUser.error instanceof BadRequestError) {
      setError("email", {
        message: "Esse email já está em uso",
      });
    }
  }, [createUser.error, setError]);

  useEffect(() => {
    if (createUser.isSuccess) {
      onClear();
      onClose();
    }
  }, [onClose, createUser.isSuccess]);

  return (
    <form onSubmit={onSubmit(handleSubmit)} autoComplete="off">
      <div className="grid gap-4 mt-5">
        <FieldInput
          label="Nome"
          error={errors.name}
          placeholder="Digite o nome do usuário"
          {...register("name")}
        />

        <FieldInput
          label="Email"
          error={errors.email}
          placeholder="Digite seu email"
          autoComplete="off"
          {...register("email")}
        />

        <FieldWrapper error={errors.type} label="Tipo do usuário">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <div className="relative">
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className="opacity-50 absolute z-10 right-[12px] bottom-[10px] pointer-events-none"
                  size="sm"
                />
                <select
                  {...field}
                  defaultValue=""
                  className="flex h-9 w-full items-center relative justify-between rounded-md border border-input bg-background px-3 py-2 text-md ring-offset-background placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                >
                  <option value="" disabled>
                    Selecione o tipo do usuário
                  </option>
                  {options.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className="text-md"
                    >
                      <span className="ps-6">{option.label}</span>
                    </option>
                  ))}
                </select>
              </div>
            )}
          />
        </FieldWrapper>
      </div>

      <div className="flex justify-end gap-x-3 mt-4">
        <Button type="button" variant={"secondary"} onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={createUser.isPending}>
          Salvar {createUser.isPending ? <Spinner className="ml-2" /> : null}
        </Button>
      </div>
    </form>
  );
};
