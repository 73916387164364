import { useDataTable } from "@/components/data-table";
import { PrintersHeader } from "./printers-header";
import { DataTable } from "./table/data-table";
import { PrinterFilterSchema } from "../types";
import { useLoadPrintersList } from "../api";
import { useEffect, useMemo } from "react";

export const PrintersList = () => {
  const { page, perPage, order, filters } = useDataTable<PrinterFilterSchema>();

  const printerList = useLoadPrintersList({
    params: {
      page,
      per_page: perPage,
      sort_direction: order?.direction,
      sort_by: order?.field,
      name: filters?.name ? filters.name : undefined,
      code: filters?.code ? filters.code : undefined,
      type: filters?.type && filters.type !== "undefined" ? filters.type : undefined,
    },
  });

  const data = useMemo(() => {
    return printerList.data ? printerList.data.data : [];
  }, [printerList.data]);

  useEffect(() => {
    printerList.refetch();
  }, [page, perPage, order, filters]);

  const isLoading = printerList.isPending || printerList.isFetching;

  return (
    <div className="space-y-3">
      <PrintersHeader />
      <DataTable
        isLoading={isLoading}
        printers={data}
        meta={printerList.data?.meta}
      />
    </div>
  );
};
