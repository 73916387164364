import { useQuery } from "@tanstack/react-query";

import { makeRemoteLoadPrintersList } from "@/@core/services/factories/usecases";
import { LoadPrintersList } from "@/@core/services/types";
import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";

const loadAll = (params: LoadPrintersList.Params) =>
  makeRemoteLoadPrintersList().loadAll(params);

type QueryFnType = typeof loadAll;

type UseLoadPrintersOptions = {
  params: LoadPrintersList.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadPrintersList = ({
  params,
  config,
}: UseLoadPrintersOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["printers"],
    queryFn: () => loadAll(params),
    ...config,
  });
};
