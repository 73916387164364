import { useCallback, useContext, useEffect, useRef, useState } from "react";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { DataTableLoading, DataTablePagination } from "@/components/data-table";
import { LoadOrdersList } from "@/@core/services/types";
import { useUserStore } from "@/stores";

import { useColumns } from "../../hooks/use-columns";
import { DataTableHeader } from "./data-table-header";
import { OrdersActionContext, OrdersSelectedContext } from "../../contexts";
import { useOrderDetailsStore } from "../../stores";
import { OrderDetails, OrderDetailsRef } from "../order-details";
import { useOrderStatus } from "../../hooks/use-order-status";
import { OrdersFilterSchema } from "../../types";

type Props = {
  isLoading: boolean;
  meta: LoadOrdersList.Response["meta"] | undefined;
  data: LoadOrdersList.Model[];
  page: number;
  filters: OrdersFilterSchema;
};

const getHeaderSize = (width: number, index: number) => {
  if (index === 0) return "auto";

  return `${width}px`;
};

const getColorByOrderType = (type: LoadOrdersList.Model["type"]) => {
  switch (type) {
    case "priority":
      return "bg-yellow-100 hover:bg-yellow-200 data-[state=selected]:bg-yellow-200";
    case "reprint":
      return "bg-green-100 hover:bg-green-200 data-[state=selected]:bg-green-200";
    default:
      return undefined;
  }
}

export const DataTable = ({ data, meta, isLoading, page, filters }: Props) => {
  const { columns } = useColumns();
  const { onChangeRows, rows } = useContext(OrdersSelectedContext);
  const { closeActionModal } = useContext(OrdersActionContext);
  const { user } = useUserStore();
  const { setOrder } = useOrderDetailsStore();
  const { allowedStatusForUser } = useOrderStatus();

  const orderDetailsRef = useRef<OrderDetailsRef>(null);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: (row) => {
      if (!user) return false

      if (user.type === "Administrator") return true

      return row.original.status.some((status) =>
        allowedStatusForUser()?.includes(status)
      );
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    columnResizeMode: "onChange",
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    manualPagination: true,
  });

  const handleOrderDetails = useCallback(
    (
      order: LoadOrdersList.Model,
      event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
    ) => {
      const target = event.target as HTMLElement;
      if (target.id === "shipping-beep-link") return;

      table.resetRowSelection();
      setOrder(order);
      orderDetailsRef.current?.open();
    },
    [setOrder]
  );

  useEffect(() => {
    if (closeActionModal) {
      table.resetRowSelection();
    }
  }, [closeActionModal]);

  useEffect(() => {
    if (!table) return;

    table.resetRowSelection();
  }, [table, page, filters]);

  useEffect(() => {
    if (!user) return;

    const rows = table.getSelectedRowModel().flatRows;
    onChangeRows(rows.map((item) => item.original));
  }, [table, user, onChangeRows, rowSelection]);

  useEffect(() => {
    console.log("data", rows);
  }, [rows])

  return (
    <>
      <DataTableHeader hasOrders={!!data.length} />

      {isLoading ? (
        <DataTableLoading />
      ) : (
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <TableHead
                    key={header.id}
                    style={{
                      minWidth: getHeaderSize(header.column.getSize(), index),
                    }}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={getColorByOrderType(row.original.type)}
                  data-state={
                    row.getIsSelected() &&
                    row.original.status.some((status) =>
                      allowedStatusForUser()?.includes(status)
                    ) &&
                    "selected"
                  }
                >
                  {row.getVisibleCells().map((cell, idx) => (
                    <TableCell
                      key={cell.id}
                      className="cursor-pointer"
                      onClick={(event) =>
                        idx > 0
                          ? handleOrderDetails(cell.row.original, event)
                          : null
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  Nenhum dado foi encontrado
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}

      <DataTablePagination totalPages={meta ? meta.last_page : 0} />

      <OrderDetails ref={orderDetailsRef} />
    </>
  );
};
