import * as z from "zod";

import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FieldInput } from "@/components/form";
import { useForgotPassword } from "@/features/auth";
import { Spinner } from "@/components/ui/spinner";

const schema = z.object({
  email: z
    .string()
    .min(1, { message: "O email precisa ser informado" })
    .email({ message: "O email precisa ser válido" }),
});

type ForgotPasswordSchema = z.infer<typeof schema>;

const message = (isSuccess: boolean, isError: boolean) => {
  if (isSuccess) {
    return {
      message: "Email enviado com successo!",
      color: "bg-success",
    };
  }

  if (isError) {
    return {
      message: "Ocorreu um erro ao enviar o email, tente novamente mais tarde.",
      color: "bg-danger",
    };
  }

  return {
    message: null,
    color: null,
  };
};

export const ForgotPasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordSchema>({
    resolver: zodResolver(schema),
  });

  const forgotPassword = useForgotPassword();

  const onSubmit: SubmitHandler<ForgotPasswordSchema> = (data) =>
    forgotPassword.mutate(data);

  const feedback = message(forgotPassword.isSuccess, forgotPassword.isError);

  return (
    <div className="grid gap-6">
      {feedback.message && (
        <p className={`text-base p-2 rounded-sm text-white ${feedback.color}`}>
          {feedback.message}
        </p>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid gap-4">
          <FieldInput
            label="Email"
            error={errors.email}
            placeholder="Digite seu email"
            type="email"
            {...register("email")}
          />

          <Button type="submit" disabled={forgotPassword.isPending}>
            Enviar {forgotPassword.isPending && <Spinner className="ml-2" />}
          </Button>
        </div>
      </form>
    </div>
  );
};
