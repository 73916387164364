import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { GenerateCsvOrders } from "../types";

export class RemoteGenerateCsvOrders implements GenerateCsvOrders {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async generate(params: GenerateCsvOrders.Params): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      params,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
