import { DeleteUser } from "@/@core/services/types";
import {
  UnexpectedError,
  CredentialsInvalid,
  UnauthorizedError,
} from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteDeleteUser implements DeleteUser {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>,
  ) {}

  async delete(): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.unauthorized:
        throw new CredentialsInvalid();
      case HttpStatusCode.forbidden:
        throw new UnauthorizedError();
      default:
        throw new UnexpectedError();
    }
  }
}
