import { useMutation } from "@tanstack/react-query";
import { makeRemoteDownloadFile } from "@/@core/services/factories/usecases";

const download = (url: string, outputName: string) =>
  makeRemoteDownloadFile(url).download(outputName);

type UseDownloadFileOptions = {
  url: string;
  outputName: string;
};

export const useDownloadFile = ({
  url,
  outputName,
}: UseDownloadFileOptions) => {
  return useMutation({
    mutationFn: () => download(url, outputName),
  });
};
