import { useRef, useState } from "react";

import * as D from "@/components/ui/dialog";
import * as T from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";

import { Authorization, ROLES } from "@/lib/authorization";
import { useLoadOrder } from "../../../api";

import { General } from "./general";
import { Products } from "./products";
import { DownloadAllFiles, DownloadAllFilesRef } from "./download-all-files";
import { CancelOrder, CancelOrderRef } from "./cancel-order";

const tabs = [
  {
    id: "general",
    label: "Visão Geral",
  },
  {
    id: "order-items",
    label: "Itens do Pedido",
  },
];

type Props = {
  orderId: number;
  openReprint: () => void;
};

export const OrderTabs = ({ orderId, openReprint }: Props) => {
  const [activeTab, setActiveTab] = useState("");
  const cancelOrderRef = useRef<CancelOrderRef>(null);
  const downloadAllFilesRef = useRef<DownloadAllFilesRef>(null);

  const { data: orderLoaded, isFetching } = useLoadOrder({
    orderId: orderId,
    config: {
      initialData: undefined,
      staleTime: 0,
    },
  });

  return (
    <>
      <D.DialogHeader>
        <D.DialogTitle className="flex items-center justify-between">
          <div className="space-x-4">
            <span>Detalhes do pedido - {orderLoaded?.shipment}</span>
            <Authorization
              allowedRoles={[
                ROLES.Administrator,
                ROLES.Expeditor,
                ROLES.Finisher,
              ]}
            >
              {!orderLoaded?.is_reprint && (
                <Button className="h-6 text-sm" onClick={openReprint}>
                  Refazer
                </Button>
              )}
            </Authorization>
            {activeTab === "order-items" && (
              <Authorization
                allowedRoles={[
                  ROLES.Administrator,
                  ROLES.BookPrinter,
                  ROLES.CoverPrinter,
                ]}
              >
                {orderLoaded && (
                  <Button
                    className="h-6 text-sm"
                    onClick={downloadAllFilesRef.current?.open}
                  >
                    Baixar todos arquivos
                  </Button>
                )}
              </Authorization>
            )}
          </div>

          <Authorization allowedRoles={[ROLES.Administrator]}>
            {!orderLoaded?.status.includes("canceled") && (
              <Button
                className="h-6 text-sm me-8"
                variant="destructive"
                onClick={() => cancelOrderRef.current?.open()}
              >
                Cancelar Pedido
              </Button>
            )}
          </Authorization>
        </D.DialogTitle>
      </D.DialogHeader>

      <T.Tabs defaultValue="general">
        <T.TabsList className="w-full">
          {tabs.map((tab) => (
            <T.TabsTrigger
              value={tab.id}
              key={tab.id}
              className="w-full"
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </T.TabsTrigger>
          ))}
        </T.TabsList>
        <ScrollArea className="overflow-visible 2xl:h-[525px] md:h-[335px] pt-4">
          <T.TabsContent value="general">
            <General order={orderLoaded} isLoading={isFetching} />
          </T.TabsContent>
          <T.TabsContent value="order-items">
            <Products
              products={orderLoaded ? orderLoaded.products : []}
              isLoading={isFetching}
            />
          </T.TabsContent>
          <ScrollBar />
        </ScrollArea>
      </T.Tabs>

      <CancelOrder orderId={orderId} ref={cancelOrderRef} />
      {orderLoaded && (
        <DownloadAllFiles
          orderId={orderId}
          shipment={orderLoaded.shipment}
          ref={downloadAllFilesRef}
        />
      )}
    </>
  );
};
