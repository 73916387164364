import { useForm } from "react-hook-form";
import { Root } from "@/components/data-table";
import { ContentLayout, ForbbidenFallback } from "@/components/layout";
import { Authorization, ROLES } from "@/lib/authorization";
import { PublishersList } from "../components/publishers-list";

export const Publishers = () => {
  const form = useForm();

  return (
    <ContentLayout title="Lista de editoras">
      <Authorization
        allowedRoles={[ROLES.Administrator]}
        forbbidenFallback={<ForbbidenFallback />}
      >
        <Root filterForm={form}>
          <PublishersList />
        </Root>
      </Authorization>
    </ContentLayout>
  );
};
