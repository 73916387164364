import { OrderAction } from "@/@core/services/types";
import { PropsWithChildren, createContext, useCallback, useState } from "react";

type OrdersActionType = {
  currentAction: OrderAction | "none" | "printer_admin";
  onChangeCurrentAction: (
    action: OrderAction | "none" | "printer_admin"
  ) => void;
  closeActionModal: boolean;
  onCloseActionModal: (value: boolean) => void;
};

export const OrdersActionContext = createContext({} as OrdersActionType);

export const OrdersActionProvider = ({ children }: PropsWithChildren) => {
  const [currentAction, setCurrentAction] =
    useState<OrdersActionType["currentAction"]>("none");

  const [closeActionModal, setCloseActionModal] = useState(false);

  const onChangeCurrentAction = useCallback(
    (action: OrdersActionType["currentAction"]) => setCurrentAction(action),
    []
  );

  const onCloseActionModal = useCallback(
    (value: boolean) => setCloseActionModal(value),
    []
  );

  return (
    <OrdersActionContext.Provider
      value={{
        currentAction,
        closeActionModal,
        onChangeCurrentAction,
        onCloseActionModal,
      }}
    >
      {children}
    </OrdersActionContext.Provider>
  );
};
