import { useEffect, useMemo, useState } from "react";

import { useDataTable } from "@/components/data-table";
import { DataTable } from "./table/data-table";
import { OrdersFilterSchema } from "../types";
import { useLoadOrdersList } from "../api";
import { convertStringToArray } from "../utils/filter-converter";
import { useOrderStatusSocket } from "../hooks/use-order-status-socket";
import { useOrderCreatedSocket } from "../hooks/use-order-created-socket";
import { useSocket } from "@/lib/socket";

export const OrdersList = () => {
  useOrderStatusSocket();
  const { renderNewOrderToast } = useOrderCreatedSocket();
  const { page, perPage, order, filters } = useDataTable<OrdersFilterSchema>();

  const [showLoading, setShowLoading] = useState(false);

  const ordersList = useLoadOrdersList({
    params: {
      page,
      per_page: perPage,
      sort_direction: order?.direction,
      sort_by: order?.field,
      from: filters.dateFrom ? filters.dateFrom : undefined,
      to: filters.dateTo ? filters.dateTo : undefined,
      shipment: filters?.shipment ? filters.shipment : undefined,
      status: filters?.orderStatus
        ? convertStringToArray(filters.orderStatus)
        : undefined,
      type: filters?.orderType
        ? convertStringToArray(filters.orderType)
        : undefined,
    },
  });

  useEffect(() => {
    setShowLoading(true)
    ordersList.refetch().then(() => setShowLoading(false));
  }, [page, perPage, order, filters]);

  const data = useMemo(() => {
    return ordersList.data ? ordersList.data.data : [];
  }, [ordersList.data]);

  useSocket({
    type: "orders",
    callBack: (payload) => {
      renderNewOrderToast(payload.is_reprint);
      ordersList.refetch();
    },
  });

  const isLoading = ordersList.isPending || showLoading

  return (
    <div className="space-y-3">
      <DataTable
        page={page}
        data={data}
        filters={filters}
        meta={ordersList.data?.meta}
        isLoading={isLoading}
      />
    </div>
  );
};
