import { create } from "zustand";
import { LoadOrdersList } from "@/@core/services/types";

export type ReprintType = "core" | "cover";

type ProductsSelected = {
  productId: number;
  coreQuantity?: number | null;
  coverQuantity?: number | null;
};

export type ReprintSelectionDTO = {
  productId: number;
  type: ReprintType;
  quantity: number | null;
};

export type ReprintQuantityDTO = {
  productId: number;
  type: ReprintType;
  quantity: number;
};

type Values = {
  order: LoadOrdersList.Model | null;
  products: ProductsSelected[];
  allProductsSelected: boolean;
  setAllProductsSelected: (value: boolean) => void;
  setOrder: (order: LoadOrdersList.Model) => void;
  removeOrder: () => void;
  addProduct: (product: ReprintSelectionDTO) => void;
  clearProducts: () => void;
  removeProduct: (productId: number) => void;
  onChangeProductQuantity: (product: ReprintQuantityDTO) => void;
};

export const useOrderReprintStore = create<Values>()((set, values) => ({
  order: null,
  products: [],
  allProductsSelected: false,
  setAllProductsSelected: (value) => set({ allProductsSelected: value }),
  setOrder: (order) => set({ order }),
  removeOrder: () => set({ order: null }),
  removeProduct: (productId) => {
    const products = values().products;

    const updatedList = products.filter((item) => item.productId !== productId);

    set({ products: updatedList });
  },
  addProduct: (product) => {
    const products = values().products;

    const productExists = products.find(
      (item) => item.productId === product.productId
    );

    if (productExists) {
      const updatedList = products.map((item) => {
        if (item.productId === product.productId) {
          return {
            ...item,
            [product.type === "core" ? "coreQuantity" : "coverQuantity"]:
              product.quantity,
          };
        }

        return item;
      });

      set({ products: updatedList });
      return;
    }

    set({
      products: [
        ...products,
        {
          productId: product.productId,
          coreQuantity: product.type === "core" ? product.quantity : null,
          coverQuantity: product.type === "cover" ? product.quantity : null,
        },
      ],
    });
  },
  onChangeProductQuantity: (product) => {
    const products = values().products;

    const updatedList = products.map((item) => {
      if (item.productId === product.productId) {
        return {
          ...item,
          [product.type === "cover" ? "coverQuantity" : "coreQuantity"]:
            product.quantity,
        };
      }

      return item;
    });

    set({ products: updatedList });
  },
  clearProducts: () => set({ products: [], allProductsSelected: false }),
}));
