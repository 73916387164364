import { Product } from "@/@core/services/types/products";
import { Spinner } from "@/components/ui/spinner";
import { ProductItem } from "./product-item";

type Props = {
  products: Product[];
  isLoading: boolean;
};

export const Products = ({ products, isLoading }: Props) => {
  if (isLoading) {
    return (
      <div className="mt-10 flex items-center justify-center">
        <Spinner className="mx-auto h-6 w-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-4 pe-5">
      {products.map((product, index) => (
        <div className="space-y-2.5" key={index}>
          <ProductItem product={product} />
        </div>
      ))}
    </div>
  );
};
