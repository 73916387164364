import { ForbbidenError, UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { LoadOrder } from "../types";

export class RemoteLoadOrder implements LoadOrder {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadOrder.Response>
  ) {}

  async load(): Promise<LoadOrder.Model> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!.data;
      case HttpStatusCode.forbidden:
        throw new ForbbidenError();
      default:
        throw new UnexpectedError();
    }
  }
}
