import { ForgotPassword } from "@/@core/services/types";
import { UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";

export class RemoteForgotPassword implements ForgotPassword {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>,
  ) {}

  async send(params: ForgotPassword.Params): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.noContent:
        return response.body!;
      default:
        throw new UnexpectedError();
    }
  }
}
