import { useQuery } from "@tanstack/react-query";

import { ExtractFnReturnType, QueryConfig } from "@/lib/react-query";
import { makeRemoteLoadSwitchRequestsList } from "@/@core/services/factories/usecases";
import { LoadSwitchRequestsList } from "@/@core/services/types/switch-request";

const loadAll = (params: LoadSwitchRequestsList.Params) =>
  makeRemoteLoadSwitchRequestsList().loadAll(params);

type QueryFnType = typeof loadAll;

type UseLoadSwitchRequestsOptions = {
  params: LoadSwitchRequestsList.Params;
  config?: QueryConfig<QueryFnType>;
};

export const useLoadSwitchRequestsList = ({
  params,
  config,
}: UseLoadSwitchRequestsOptions) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    queryKey: ["switch-requests"],
    queryFn: () => loadAll(params),
    ...config,
  });
};
