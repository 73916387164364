import React, { useCallback } from "react";
import { useUserStore } from "@/stores/user";

export enum ROLES {
  Administrator = "Administrator",
  CoverPrinter = "CoverPrinter",
  BookPrinter = "BookPrinter",
  Finisher = "Finisher",
  Expeditor = "Expeditor",
}

type RolesType = keyof typeof ROLES;

export const useAuthorization = () => {
  const { user } = useUserStore();

  const checkAccess = useCallback(
    ({ allowedRoles }: { allowedRoles: RolesType[] }) => {
      if (allowedRoles && allowedRoles.length > 0) {
        return allowedRoles.includes(user?.type as keyof typeof ROLES);
      }

      return true;
    },
    [user],
  );

  return { checkAccess, user };
};

type AuthorizationProps = {
  children: React.ReactNode;
  allowedRoles: RolesType[];
  forbbidenFallback?: React.ReactNode;
};

export const Authorization = ({
  allowedRoles,
  children,
  forbbidenFallback,
}: AuthorizationProps) => {
  const { checkAccess } = useAuthorization();
  const canAccess = checkAccess({ allowedRoles });

  return <>{canAccess ? children : forbbidenFallback}</>;
};
