import {
  UnexpectedError,
  CredentialsInvalid,
  UnauthorizedError,
  NotFoundError,
  UnprocessableContentError,
  BadRequestError,
} from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { UpdatePrinter } from "../types";

export class RemoteUpdatePrinter implements UpdatePrinter {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async update(params: Omit<UpdatePrinter.Params, "id">): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "put",
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body!;
      case HttpStatusCode.unprocessableContent:
        //@ts-ignore
        throw new UnprocessableContentError(response.body!.errors);
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFoundError();
      case HttpStatusCode.unauthorized:
        throw new CredentialsInvalid();
      case HttpStatusCode.forbidden:
        throw new UnauthorizedError();
      default:
        throw new UnexpectedError();
    }
  }
}
