import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import { useDisclosure } from "@/hooks";

import * as D from "@/components/ui/dialog";
import {
  RenderStatusTypes,
  useOrderAction,
} from "../../hooks/use-order-action";
import { OrdersActionContext } from "../../contexts";

export type OrderActionRef = {
  open: (status: RenderStatusTypes) => void;
};

const titleByAction = {
  end_dispatch: "Finalizar expedição",
  end_finishing: "Finalizar acabamento",
  end_print_cover: "Finalizar impressão da capa",
  end_print_core: "Finalizar impressão do miolo",
  start_print_core: "Imprimir miolo",
  start_print_cover: "Imprimir capa",
  printer_admin: "Imprimir pedido",
  none: "Imprimir pedido",
} as Record<string, string>;

export const OrderAction = forwardRef<OrderActionRef, unknown>((_, ref) => {
  const { currentAction, closeActionModal, onCloseActionModal } =
    useContext(OrdersActionContext);
  const { isOpen, open, close } = useDisclosure();
  const { renderActionBasedOnStatus } = useOrderAction();

  const [status, setStatus] = useState<RenderStatusTypes | null>(null);

  useEffect(() => {
    if (closeActionModal) {
      onCloseActionModal(false);
      close();
    }
  }, [closeActionModal]);

  useImperativeHandle(
    ref,
    () => ({
      open: (status: RenderStatusTypes) => {
        setStatus(status);
        open();
      },
    }),
    [open]
  );

  if (!status || !isOpen) return null;

  return (
    <D.Dialog open={isOpen}>
      <D.DialogContent onClose={close}>
        <D.DialogHeader>
          <D.DialogTitle>{titleByAction[currentAction]}</D.DialogTitle>
        </D.DialogHeader>

        {renderActionBasedOnStatus({
          status,
          onClose: close,
        })}
      </D.DialogContent>
    </D.Dialog>
  );
});
