import { FieldInput } from "@/components/form";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetError,
} from "react-hook-form";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { UnprocessableContentError } from "@/@core/services/errors";
import { PublisherSchema } from ".";
import { useCreatePublisher } from "../../api";
import { Spinner } from "@/components/ui/spinner";

type Props = {
  errors: FieldErrors<PublisherSchema>;
  register: UseFormRegister<PublisherSchema>;
  onClose: () => void;
  setError: UseFormSetError<PublisherSchema>;
  onSubmit: UseFormHandleSubmit<PublisherSchema>;
};

export const CreateForm = ({
  errors,
  register,
  setError,
  onClose,
  onSubmit,
}: Props) => {
  const createPublisher = useCreatePublisher();

  const handleSubmit: SubmitHandler<PublisherSchema> = (data) => {
    createPublisher.mutate({
      name: data.name,
      code: data.code,
      webhook_url: data.url || null,
    });
  };

  useEffect(() => {
    if (createPublisher.error instanceof UnprocessableContentError) {
      Object.keys(createPublisher.error.errors).map((key) => {
        switch (key) {
          case "code":
            setError("code", {
              message: "Esse código já está em uso",
            });
        }
      });
    }
  }, [createPublisher.error, setError]);

  useEffect(() => {
    if (createPublisher.isSuccess) {
      onClose();
    }
  }, [onClose, createPublisher.isSuccess]);

  return (
    <form onSubmit={onSubmit(handleSubmit)}>
      <div className="grid gap-4 mt-5">
        <FieldInput
          label="Nome"
          error={errors.name}
          placeholder="Digite o nome da editora"
          {...register("name")}
        />

        <FieldInput
          label="Código"
          error={errors.code}
          placeholder="Digite o código da editora"
          {...register("code")}
        />

        <FieldInput
          label="Webhook de alteração de status"
          error={errors.url}
          placeholder="Digite a url do webhook"
          {...register("url")}
        />
      </div>

      <div className="flex justify-end gap-x-3 mt-4">
        <Button type="button" variant={"secondary"} onClick={onClose}>
          Cancelar
        </Button>
        <Button type="submit" disabled={createPublisher.isPending}>
          Salvar
          {createPublisher.isPending ? <Spinner className="ml-2" /> : null}
        </Button>
      </div>
    </form>
  );
};
