import { Spinner } from "../ui/spinner";
import logo from "@/assets/imgs/logo-print-park.svg";

export const FallbackSuspense = () => {
  return (
    <div className="flex h-screen w-full items-center space-y-7 justify-center flex-col">
      <img src={logo} alt="logo" className="w-full h-12" />
      <Spinner size="lg" />
    </div>
  );
};
