import { makeCreateUser } from "@/@core/services/factories/usecases";
import { CreateUser } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useMutation } from "@tanstack/react-query";

const create = (params: Omit<CreateUser.Params, "id">) =>
  makeCreateUser().save(params);

export const useCreateUser = () => {
  const { toast } = useToast();
  return useMutation({
    onSuccess: () => {
      toast({
        title: "Usuário criado!",
        description: "Usuário criado com sucesso.",
      });

      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
    mutationFn: create,
  });
};
