import { useCallback, useRef, useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Search } from "lucide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/sharp-solid-svg-icons";
import { Badge } from "../ui/badge";
import { Separator } from "../ui/separator";

type Props = {
  title: string;
  placeholder: string;
  searchedValue: string;
  onSearch: (target: string) => void;
  onClear: () => void;
};

export const PopoverFilter = ({
  title,
  onSearch,
  searchedValue,
  placeholder,
  onClear,
}: Props) => {
  const [value, setValue] = useState("");
  const popoverTriggerRef = useRef<any>(null);

  const handleSubmit = useCallback(() => {
    onSearch(value);
    popoverTriggerRef.current?.click();
  }, [onSearch, value]);

  const handleClear = () => {
    setValue("");
    onSearch("");
    onClear();
    popoverTriggerRef.current?.click();
  };

  return (
    <Popover>
      <PopoverTrigger asChild ref={popoverTriggerRef}>
        <Button variant="outline" className="h-8 border">
          <FontAwesomeIcon icon={faFilter} className="mr-1.5" />
          {title}

          {searchedValue && (
            <>
              <Separator className="mx-2 h-5" orientation="vertical" />

              <Badge
                variant="secondary"
                className="rounded-sm px-1 font-normal"
              >
                {searchedValue}
              </Badge>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <form onSubmit={handleSubmit} className="flex items-center ps-2">
          <Search className="h-4 w-4 text-gray-400" />
          <Input
            className="w-full border-0"
            placeholder={placeholder}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </form>

        {searchedValue && (
          <Button
            variant="ghost"
            className="w-full mx-0 ps-0 h-7"
            onClick={handleClear}
          >
            Limpar filtro
          </Button>
        )}
      </PopoverContent>
    </Popover>
  );
};
