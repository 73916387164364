import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useDisclosure } from "@/hooks";
import { Printer } from "@/@core/services/types";
import { CreateForm } from "./create";
import { UpdateForm } from "./update";
import { printerTypeOptions } from "../../utils/printers-type-options";

const schema = z.object({
  name: z.string().min(1, { message: "Nome é obrigatório" }),
  code: z.string().min(1, { message: "Código é obrigatório" }),
  type: z.string().min(1, { message: "Tipo é obrigatório" }),
});

export type PrinterSchema = z.infer<typeof schema>;

type Props = {
  type?: "update" | "create";
};

export type PrintersFormRef = {
  open: (printer?: Printer) => void;
};

export const PrintersForm = forwardRef<PrintersFormRef, Props>(
  ({ type = "create" }, ref) => {
    const { open, close, isOpen } = useDisclosure();

    const [printerToUpdate, setPrinterToUpdate] = useState(
      null as Printer | null
    );

    const {
      register,
      handleSubmit,
      setValue,
      control,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm<PrinterSchema>({
      resolver: zodResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      open: (publisher?: Printer) => {
        open();

        if (publisher) {
          setPrinterToUpdate(publisher);
        }
      },
    }));

    useEffect(() => {
      if (!isOpen) {
        clearErrors();
      }
    }, [isOpen, clearErrors]);

    const selectOptions = printerTypeOptions.filter(
      (opt) => opt.value !== "all"
    );

    return (
      <Sheet open={isOpen}>
        <SheetContent onClose={close}>
          <SheetHeader>
            <SheetTitle>
              {type === "create" ? "Adicionar impressora" : "Editar impressora"}
            </SheetTitle>
          </SheetHeader>
          {type === "create" && !printerToUpdate ? (
            <CreateForm
              errors={errors}
              register={register}
              control={control}
              onClose={close}
              setError={setError}
              options={selectOptions}
              onSubmit={handleSubmit}
            />
          ) : (
            <UpdateForm
              errors={errors}
              register={register}
              onClose={close}
              control={control}
              setError={setError}
              setValue={setValue}
              options={selectOptions}
              onSubmit={handleSubmit}
              printer={printerToUpdate!}
            />
          )}
        </SheetContent>
      </Sheet>
    );
  }
);
