import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { queryClient } from "@/lib/react-query";
import { FallbackSuspense } from "@/components/layout/fallback-suspense";

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <React.Suspense fallback={<FallbackSuspense />}>
      <QueryClientProvider client={queryClient}>
        <Router>{children}</Router>
      </QueryClientProvider>
    </React.Suspense>
  );
};
