import { forwardRef, useImperativeHandle, useState } from "react";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { userTypeOptions } from "@/utils/user-type-options";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useDisclosure } from "@/hooks";
import { User } from "@/@core/services/types";
import { CreateForm } from "./create-form";
import { UpdateForm } from "./update-form";

const schema = z.object({
  name: z.string().min(1, { message: "Nome é obrigatório" }),
  email: z
    .string()
    .min(1, { message: "Email é obrigatório" })
    .email({ message: "Email inválido" }),
  type: z
    .string({ required_error: "Tipo do usuário é obrigatório" })
    .min(1, { message: "Tipo do usuário é obrigatório" }),
});

export type UserSchema = z.infer<typeof schema>;

type Props = {
  type?: "update" | "create";
};

export type UserFormRef = {
  open: (user?: User) => void;
};

export const UserForm = forwardRef<UserFormRef, Props>(
  ({ type = "create" }, ref) => {
    const { open, close, isOpen } = useDisclosure();

    const [userToUpdate, setUserToUpdate] = useState(null as User | null);

    const {
      control,
      register,
      handleSubmit,
      setValue,
      reset,
      setError,
      formState: { errors },
    } = useForm<UserSchema>({
      resolver: zodResolver(schema),
    });

    useImperativeHandle(ref, () => ({
      open: (user?: User) => {
        open();

        if (user) {
          setUserToUpdate(user);
        }
      },
    }));

    const selectOptions = userTypeOptions.filter((opt) => opt.value !== "all");

    return (
      <Sheet open={isOpen}>
        <SheetContent onClose={close}>
          <SheetHeader>
            <SheetTitle>
              {type === "create" ? "Adicionar usuário" : "Editar usuário"}
            </SheetTitle>
          </SheetHeader>
          {type === "create" && !userToUpdate ? (
            <CreateForm
              control={control}
              errors={errors}
              onClear={() =>
                reset({
                  name: "",
                  email: "",
                  type: "",
                })
              }
              register={register}
              options={selectOptions}
              onClose={close}
              setError={setError}
              onSubmit={handleSubmit}
            />
          ) : (
            <UpdateForm
              control={control}
              errors={errors}
              register={register}
              options={selectOptions}
              onClose={close}
              setError={setError}
              setValue={setValue}
              onSubmit={handleSubmit}
              user={userToUpdate!}
            />
          )}
        </SheetContent>
      </Sheet>
    );
  }
);
