/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { useDataTable } from "@/components/data-table";
import { PopoverFilter } from "@/components/data-table/popover-filter";
import {
  PopoverMultiFilter,
  PopoverMultiFilterRef,
} from "@/components/data-table/popover-muti-filter";
import { Button } from "@/components/ui/button";

import {
  orderStatusOptions,
  orderTypeOptions,
} from "../../utils/filter-options";
import { OrdersFilterSchema } from "../../types";
import {
  PopoverDateTimeRange,
  PopoverDateTimeRangeRef,
} from "@/components/data-table/popover-date-range";
import { formatDateToISO } from "@/utils/format-date";

export function DataTableFilter() {
  const { filterForm, onSubmit, filters } = useDataTable<OrdersFilterSchema>();
  const statusFilterRef = useRef<PopoverMultiFilterRef>(null);
  const typeFilterRef = useRef<PopoverMultiFilterRef>(null);
  const dateTimeFilterRef = useRef<PopoverDateTimeRangeRef>(null);

  const handleFilter = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  const handleClearFilter = (key: string) => {
    filterForm?.setValue(key as keyof OrdersFilterSchema, "");
  };

  function clearFilters() {
    if (!filterForm) return;

    filterForm.setValue("dateFrom", "");
    filterForm.setValue("dateTo", "");
    filterForm.setValue("shipment", "");
    filterForm.setValue("orderStatus", "");
    filterForm.setValue("orderType", "");

    statusFilterRef.current?.clear();
    typeFilterRef.current?.clear();
    dateTimeFilterRef.current?.clear();
  }

  useEffect(() => {
    if (!filterForm) return;

    onSubmit({
      dateFrom: filterForm.getValues("dateFrom"),
      dateTo: filterForm.getValues("dateTo"),
      shipment: filterForm.getValues("shipment") ?? "",
      orderStatus: filterForm.getValues("orderStatus") ?? "",
      orderType: filterForm.getValues("orderType") ?? "",
    });
  }, [
    filterForm,
    filterForm?.watch("orderStatus"),
    filterForm?.watch("orderType"),
    filterForm?.watch("dateFrom"),
    filterForm?.watch("dateTo"),
    filterForm?.watch("shipment"),
  ]);

  const showClearButton =
    filters.dateFrom ||
    filters.dateTo ||
    filters.shipment ||
    filters.orderStatus ||
    filters.orderType;

  return (
    <div className="w-full">
      <form
        className="flex space-x-2 items-end"
        onSubmit={filterForm?.handleSubmit(handleFilter)}
      >
        <PopoverDateTimeRange
          ref={dateTimeFilterRef}
          placeholder="Data/Hora de Abertura"
          onClear={() => {
            handleClearFilter("dateFrom");
            handleClearFilter("dateTo");
          }}
          searchedValue={{
            from: filters.dateFrom,
            to: filters.dateTo,
          }}
          onSearch={(timeFrom) => {
            filterForm?.setValue("dateFrom", formatDateToISO(timeFrom?.from));
            filterForm?.setValue("dateTo", formatDateToISO(timeFrom?.to));
          }}
        />

        <PopoverFilter
          title="Remessa"
          placeholder="Pesquisar remessa"
          onClear={() => handleClearFilter("shipment")}
          searchedValue={filters.shipment ?? ""}
          onSearch={(shipment) => filterForm?.setValue("shipment", shipment)}
        />

        <PopoverMultiFilter
          ref={typeFilterRef}
          title="Tipo"
          options={orderTypeOptions}
          onClear={() => handleClearFilter("orderType")}
          searchedValues={filters.orderType ?? ""}
          onSearch={(types) => filterForm?.setValue("orderType", types)}
        />

        <PopoverMultiFilter
          ref={statusFilterRef}
          className="w-[16.25rem]"
          title="Status"
          options={orderStatusOptions}
          searchedValues={filters.orderStatus ?? ""}
          onClear={() => handleClearFilter("orderStatus")}
          onSearch={(status) => filterForm?.setValue("orderStatus", status)}
        />

        {showClearButton && (
          <Button
            type="submit"
            variant="ghost"
            className="text-[12px] h-8 px-2.5"
            onClick={() => clearFilters()}
          >
            Limpar filtros <FontAwesomeIcon icon={faXmark} className="ml-2" size="lg"/>
          </Button>
        )}
      </form>
    </div>
  );
}
