import { useRef } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faHashtag,
  faInputText,
  faLink,
  faPenToSquare,
  faTrashXmark,
} from "@fortawesome/sharp-solid-svg-icons";

import { LoadPublishersList } from "@/@core/services/types";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { DataTableOrder } from "@/components/data-table";
import { PublishersForm, PublishersFormRef } from "../components/form";
import {
  PublishersDelete,
  PublishersDeleteRef,
} from "../components/publishers-delete";

export const useColumns = () => {
  const publishersDeleteRef = useRef<PublishersDeleteRef>(null);
  const publishersFormRef = useRef<PublishersFormRef>(null);

  const columns: ColumnDef<LoadPublishersList.Model>[] = [
    {
      accessorKey: "name",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faInputText} />}
          accessor="name"
          title="Nome"
        />
      ),
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("name")}</div>
      ),
    },
    {
      accessorKey: "code",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faHashtag} />}
          accessor="code"
          title="Código"
        />
      ),
      cell: ({ row }) => <>{row.getValue("code")}</>,
    },
    {
      accessorKey: "webhook_url",
      header: () => (
        <DataTableOrder
          icon={<FontAwesomeIcon icon={faLink} />}
          accessor="webhook_url"
          title="Webhook de alteração de status"
        />
      ),
      cell: ({ row }) => <>{row.getValue("webhook_url") ?? "-"}</>,
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <FontAwesomeIcon icon={faEllipsis} size="lg" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() =>
                    publishersDeleteRef.current?.open(row.original.id)
                  }
                >
                  <FontAwesomeIcon icon={faTrashXmark} size="1x" />{" "}
                  <span className="ms-2">Remover editora</span>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="flex items-center cursor-pointer"
                  onClick={() => publishersFormRef.current?.open(row.original)}
                >
                  <FontAwesomeIcon icon={faPenToSquare} size="1x" />{" "}
                  <span className="ms-2">Editar editora</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            <PublishersForm ref={publishersFormRef} type="update" />
            <PublishersDelete ref={publishersDeleteRef} />
          </>
        );
      },
    },
  ];

  return { columns };
};
