import { AxiosResponse } from "axios";
import { HttpClient } from "@/@core/infra/protocols/http";
import { UnexpectedError } from "../errors";

export class RemoteDownloadFile {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<AxiosResponse>
  ) {}

  async download(outputName: string): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
      responseType: "blob",
    });

    const blob = response.body as any;

    try {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");

      link.href = blobUrl;
      link.download = outputName;

      document.body.appendChild(link);

      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );

      document.body.removeChild(link);
    } catch (error) {
      throw new UnexpectedError();
    }
  }
}
