import { UnexpectedError } from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { OrderTakeAction } from "../types";

export class RemoteOrderTakeAction implements OrderTakeAction {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async takeAction(params: OrderTakeAction.Params): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.noContent:
        return response.body!;
      default:
        throw new UnexpectedError();
    }
  }
}
