import { useCallback, useEffect, useState } from "react";

import { Product } from "@/@core/services/types/products";
import { OrderProduct } from "../../order-product";
import { ProductOption } from "./product-option";
import { useOrderReprintStore } from "../../../stores";
import { Separator } from "@/components/ui/separator";
import { useUserStore } from "@/stores";

type Props = {
  product: Product;
};

export const ProductItem = ({ product }: Props) => {
  const { user } = useUserStore();

  const {
    allProductsSelected,
    addProduct,
    removeProduct,
    onChangeProductQuantity,
    products,
  } = useOrderReprintStore();

  const [checks, setCheckes] = useState({
    cover: false,
    core: false,
  });

  const [counts, setCounts] = useState({
    cover: 1,
    core: 1,
  });

  const handleAdd = useCallback(
    (type: "core" | "cover", isChecked: boolean) => {
      let quantityBasedType = null;

      if (type === "core") {
        quantityBasedType = isChecked ? counts.core : null;
      }

      if (type === "cover") {
        quantityBasedType = isChecked ? counts.cover : null;
      }

      addProduct({
        productId: product.id,
        quantity: quantityBasedType,
        type: type,
      });
    },
    [addProduct, product.id, counts.core, counts.cover]
  );

  useEffect(() => {
    if (!checks.core && !checks.cover) {
      removeProduct(product.id);
    }
  }, [checks.core, checks.cover, product.id, removeProduct]);

  useEffect(() => {
    if (!user) return;

    const coverCheck =
      (user.type === "CoverPrinter" || user.type === "Administrator") &&
      allProductsSelected;

    const coreCheck =
      (user.type === "BookPrinter" || user.type === "Administrator") &&
      allProductsSelected;

    setCheckes({ cover: coverCheck, core: coreCheck });

    if (allProductsSelected) {
      addProduct({
        productId: product.id,
        quantity: coverCheck ? counts.cover : null,
        type: "cover",
      });

      addProduct({
        productId: product.id,
        quantity: coreCheck ? counts.core : null,
        type: "core",
      });
    }
  }, [allProductsSelected, user]);

  useEffect(() => {
    if (!products.length) {
      setCounts({ cover: 1, core: 1 });
      setCheckes({ cover: false, core: false });
    }
  }, [products]);

  return (
    <OrderProduct product={product}>
      <div className="ml-auto font-medium flex items-center space-x-3">
        <ProductOption
          onCheck={(isChecked) => {
            setCheckes({ ...checks, cover: !checks.cover });
            handleAdd("cover", isChecked);
          }}
          max={product.cover_total}
          onCount={(value) => {
            setCounts((prevState) => ({ ...prevState, cover: value }));

            onChangeProductQuantity({
              productId: product.id,
              quantity: value,
              type: "cover",
            });
          }}
          count={counts.cover}
          checked={checks.cover}
          productId={product.id}
          type="cover"
        />

        {user && user.type ? (
          <Separator orientation="vertical" className="h-5 w-0.5" />
        ) : null}

        <ProductOption
          onCheck={(isChecked) => {
            setCheckes({ ...checks, core: !checks.core });
            handleAdd("core", isChecked);
          }}
          onCount={(value) => {
            setCounts((prevState) => ({ ...prevState, core: value }));

            onChangeProductQuantity({
              productId: product.id,
              quantity: value,
              type: "core",
            });
          }}
          count={counts.core}
          max={product.core_total}
          checked={checks.core}
          productId={product.id}
          type="core"
        />
      </div>
    </OrderProduct>
  );
};
