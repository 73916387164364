import { User } from "@/@core/services/types";
import { storageKeys } from "@/config/storage-keys";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type UserType = {
  user: User | null;
  setUser: (user: User) => void;
  removeUser: () => void;
};

export const useUserStore = create<UserType>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => set({ user }),
      removeUser: () => set({ user: null }),
    }),
    {
      name: storageKeys.user,
    },
  ),
);
