import {
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useRef,
} from "react";
import * as z from "zod";
import { useForm, SubmitHandler } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import * as D from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { FieldSelect } from "@/components/form";

import { useDisclosure } from "@/hooks";
import { useUserStore } from "@/stores";

import { orderStatusOptions } from "@/features/order/utils/filter-options";
import { OrdersSelectedContext } from "@/features/order/contexts";
import { OrderStatus } from "@/@core/services/types";
import {
  ConfirmChangeStatusHard,
  ConfirmChangeStatusHardRef,
} from "./confirm-change-status-hard";

export interface ChangeOrderStatusRef {
  open: () => void;
}

const schema = z.object({
  status: z.string({
    required_error: "Status é obrigatório",
  }),
});

type Schema = z.infer<typeof schema>;

export const ChangeOrderStatus = forwardRef<ChangeOrderStatusRef, unknown>(
  (_, ref) => {
    const { user } = useUserStore();
    const { rows } = useContext(OrdersSelectedContext);
    const confirmChangeStatusRef = useRef<ConfirmChangeStatusHardRef>(null);
    const { close, isOpen, open } = useDisclosure();

    const {
      control,
      handleSubmit,
      reset,
      register,
      formState: { errors },
    } = useForm<Schema>({
      resolver: zodResolver(schema),
    });

    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    const onSubmit: SubmitHandler<Schema> = useCallback(
      (data) => {
        if (confirmChangeStatusRef.current) {
          confirmChangeStatusRef.current.open({
            ids: rows.map((row) => row.id),
            status: data.status as OrderStatus,
          });

          close();
        }
      },
      [rows, close]
    );

    function handleClose() {
      reset();
      close();
    }

    if (!user) return null;

    const options = orderStatusOptions.filter(
      (status) => status.value !== "failed"
    );

    return (
      <>
        <D.Dialog open={isOpen}>
          <D.DialogContent onClose={handleClose}>
            <D.DialogHeader>
              <D.DialogTitle>Alterar status do pedido</D.DialogTitle>
            </D.DialogHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldSelect
                control={control}
                name="status"
                label="Status"
                error={errors.status}
                options={options}
                registration={register("status")}
                placeholder="Selecione o status"
              />

              <div className="flex justify-end items-center space-x-4 mt-5">
                <Button type="button" variant={"ghost"} onClick={handleClose}>
                  Cancelar
                </Button>
                <Button type="submit">Confirmar</Button>
              </div>
            </form>
          </D.DialogContent>
        </D.Dialog>

        <ConfirmChangeStatusHard
          onClose={() => open()}
          onSuccess={() => reset()}
          ref={confirmChangeStatusRef}
        />
      </>
    );
  }
);
