import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faXmark } from "@fortawesome/sharp-solid-svg-icons";

import { useDataTable } from "@/components/data-table";
import { FieldSelect } from "@/components/form";
import { UserFilterSchema } from "../types";
import { userTypeOptions } from "@/utils/user-type-options";
import { PopoverFilter } from "@/components/data-table/popover-filter";
import { Button } from "@/components/ui/button";


export const UserFilter = () => {
  const { filterForm, onSubmit, filters } = useDataTable<UserFilterSchema>();

  const handleFilter = (data: any) => {
    if (data) {
      onSubmit(data);
    }
  };

  const handleClearFilter = (key: string) => {
    onSubmit({
      email: key === "email" ? "" : filters.email,
      name: key === "name" ? "" : filters.name,
      type: key === "type" ? "" : filters.type,
    });
  };

  function clearFilters() {
    filterForm?.setValue("email", "");
    filterForm?.setValue("name", "");
    filterForm?.setValue("type", "");
  }

  useEffect(() => {
    if (!filterForm) return;

    const type = filterForm.watch("type");

    const listAll = typeof type === "undefined" || type === "all" || type === "undefined"

    onSubmit({
      email: filters.email ?? "",
      name: filters.name ?? "",
      type: listAll ? "" : filterForm.watch("type"),
    });
  }, [filterForm?.watch("type")]);

  if (!filterForm) return null;

  return (
    <div className="w-4/5">
      <form
        className="flex space-x-2 items-end"
        onSubmit={filterForm.handleSubmit(handleFilter)}
      >
        <PopoverFilter
          title="Nome"
          placeholder="Pesquisar nome"
          onClear={() => handleClearFilter("name")}
          searchedValue={filters.name}
          onSearch={(name) => filterForm.setValue("name", name)}
        />

        <PopoverFilter
          title="Email"
          onClear={() => handleClearFilter("email")}
          searchedValue={filters.email}
          placeholder="Pesquisar email"
          onSearch={(email) => filterForm.setValue("email", email)}
        />

        <FieldSelect
          className="w-[195px] h-8"
          iconIsVisible
          name="type"
          registration={filterForm.register("type")}
          control={filterForm.control}
          placeholder="Tipo de usuário"
          options={userTypeOptions}
        />

        {filters.name || filters.email || filters.type ? (
          <Button
            type="submit"
            variant="ghost"
            className="text-[12px] h-8"
            onClick={() => clearFilters()}
          >
            Limpar filtros <FontAwesomeIcon icon={faXmark} className="ml-2" size="lg"/>
          </Button>
        ) : null}
      </form>
    </div>
  );
};
