/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import * as I from "lucide-react";
import classnames from "classnames";

import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";

type Props = {
  type: string;
  productId: number;
  checked: boolean;
  count: number;
  max: number;
  onCheck: (value: boolean) => void;
  onCount: (value: number) => void;
};

export const ProductOption = ({
  type,
  productId,
  checked,
  count,
  max,
  onCheck,
  onCount,
}: Props) => {
  const title = type === "cover" ? "Capa" : "Miolo";
  const id = `product-${productId}-${type}`;

  const handleIncrement = useCallback(() => {
    onCount(count + 1);
  }, [count]);

  const handleDecrement = useCallback(() => {
    onCount(count - 1);
  }, [count]);

  const handleChangeInput = (value: string) => {
    const replacedValue = value.replace(/[^0-9]/g, "");
    onCount(Number(replacedValue));
  };

  const limitReached = max === count;

  return (
    <div className="flex items-center space-x-4">
      <div
        className="flex items-center space-x-1 cursor-pointer"
        onClick={() => onCheck(!checked)}
      >
        <Checkbox id={id} checked={checked} />
        <label className="pt-0.5 text-sm">{title}</label>
      </div>
      {checked ? (
        <div className="flex items-center mt-0.5 space-x-1.5">
          <I.MinusCircle
            size={15}
            onClick={() => {
              if (count === 1) {
                return;
              }

              handleDecrement();
            }}
            className={classnames("cursor-pointer", {
              "opacity-25": count === 1,
            })}
          />
          <Input
            className="w-11 h-7 flex items-center justify-center text-center px-0 pt-2.5"
            value={count}
            onBlur={(e) => {
              if (Number(e.target.value) === 0) {
                onCount(1);
              }

              if (Number(e.target.value) > max) {
                onCount(max);
              }
            }}
            onChange={(e) => handleChangeInput(e.target.value)}
          />
          <I.PlusCircle
            size={15}
            className={classnames("cursor-pointer", {
              "opacity-25": limitReached,
            })}
            onClick={() => {
              if (limitReached) {
                return;
              }

              handleIncrement();
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
