import {
  DownloadFileSizeLimitError,
  NotFoundError,
  UnexpectedError,
} from "@/@core/services/errors";
import { HttpClient, HttpStatusCode } from "@/@core/infra/protocols/http";
import { OrderDownloadAll } from "../types";

export class RemoteOrderDownloadAll implements OrderDownloadAll {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<void>
  ) {}

  async downloadAll(): Promise<void> {
    const response = await this.httpClient.request({
      url: this.url,
      method: "get",
      responseType: "blob",
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        console.log(response.body)
        return response.body!;
      case HttpStatusCode.contentTooLarge:
        throw new DownloadFileSizeLimitError();
      case HttpStatusCode.notFound:
        throw new NotFoundError();
      default:
        throw new UnexpectedError();
    }
  }
}
