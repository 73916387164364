import { makeUpdateUser } from "@/@core/services/factories/usecases";
import { UpdateUser } from "@/@core/services/types";
import { useToast } from "@/components/ui/use-toast";
import { queryClient } from "@/lib/react-query";
import { useUserStore } from "@/stores/user";
import { useMutation } from "@tanstack/react-query";

const update = (params: UpdateUser.Params) =>
  makeUpdateUser(params.id).update({
    email: params.email,
    name: params.name,
    type: params.type,
  });

export const useUpdateUser = () => {
  const { toast } = useToast();
  const { user, setUser } = useUserStore();

  return useMutation({
    onSuccess: (_, data) => {
      if (user && user.id === data.id) {
        setUser(data);
        toast({
          title: "Perfil atualizado!",
          description: "Seu perfil foi atualizado com sucesso.",
        });
      } else {
        toast({
          title: "Usuário atualizado!",
          description: "Usuário atualizado com sucesso.",
        });
      }

      queryClient.refetchQueries({
        queryKey: ["users"],
      });
    },
    mutationFn: update,
  });
};
