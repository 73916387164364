import { Layout } from "../components/layout";
import { LoginForm } from "../components/login-form";

export const Login = () => {
  return (
    <Layout>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">Bem vindo</h1>
        <p className="text-base text-muted-foreground">
          Faça login em sua conta
        </p>
      </div>

      <LoginForm onSuccess={() => (window.location.href = "/")} />

      <a className="text-base text-center" href="/forgot-password">
        Esqueceu sua senha?
      </a>
    </Layout>
  );
};
