import { PerPageSelect, Pagination } from "./data-table-root";

export function DataTablePagination({
  totalPages = 10,
}: {
  totalPages: number;
}) {
  return (
    <div className="flex items-center justify-between px-2">
      <PerPageSelect />
      <Pagination total={totalPages} />
    </div>
  );
}
